import React from 'react';
import NotFound from '../../components/NotFound';
const NotFoundPage = () => {
    useEffect(() => {
        document.title = `${process.env.APP_NAME} • Niet gevonden`;

      }, []);
    return (
            <NotFound />
)
}

export default NotFoundPage;