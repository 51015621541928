import React, { useState, useRef } from 'react';
import useAuth from "../../hooks/useAuth";

import AlertDanger from '../../components/ui/alerts/AlertDanger';
import AlertSuccess from '../../components/ui/alerts/AlertSuccess';

const UserDetails = () => {
    const { currentUser, updateUser } = useAuth();

    const emailRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const passwordRef = useRef();
    const [firstNameError, setFirstNameError] = useState();
    const [lastNameError, setLastNameError] = useState();
    const [emailError, setEmailError] = useState();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [isFormModified, setIsFormModified] = useState(false);


    const handleInputChange = () => {
        const isModified =
            firstNameRef.current.value !== currentUser.firstName ||
            lastNameRef.current.value !== currentUser.lastName ||
            emailRef.current.value !== currentUser.email;
        setIsFormModified(isModified);
    };

    async function handleSubmit(e) {
        e.preventDefault();
        setEmailError("");
        setFirstNameError("");
        setLastNameError("");
        setError("");
        setSuccess(false);
        setSuccessMessage("");
        let fieldsCorrect = true;
        if ((emailRef.current.value.length < 1)) {
            setEmailError("Users must have an email.");
            fieldsCorrect = false;
        }
        if ((firstNameRef.current.value.length < 1)) {
            setFirstNameError("Users must have a first name.");
            fieldsCorrect = false;
        }
        if ((lastNameRef.current.value.length < 1)) {
            setLastNameError("Users must have a last name.");
            fieldsCorrect = false;
        }
        if (!fieldsCorrect) {
            return;
        }
        try {
            setIsLoading(true);
            await updateUser(firstNameRef.current.value, lastNameRef.current.value, emailRef.current.value);
            setSuccess(true);
            setSuccessMessage("Your details were successfully updated.")
        }
        catch (error) {
            console.error("userdetails throwing", error);
            setError("Update unsuccessful. Please try again.")
        }

        setIsLoading(false);
    }



    return (<div className="bg-white rounded-md p-6 flex flex-col gap-4 w-1/2">
        {success && <AlertSuccess message={successMessage} />}
        {error && <AlertDanger message={error} />}
        <img id="user-photo" src= "/logo-small.png" className="mx-auto w-20 h-20" />
        <form
            className="h-full flex flex-col gap-4 justify-between"
            onSubmit={handleSubmit}
        >
            <div>
                <label className="block text-gray-800 mb-2 font-medium" htmlFor="first-name">
                    Voornaam
                </label>
                <input id="first-name"
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text" defaultValue={currentUser.firstName} ref={firstNameRef} onChange={handleInputChange} />

            </div>
            {firstNameError && <AlertDanger message={firstNameError} />}
            <div>
                <label className="block text-gray-800 mb-2 font-medium" htmlFor="last-name">
                    Achternaam
                </label>
                <input
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="last-name" type="text" defaultValue={currentUser.lastName} ref={lastNameRef} onChange={handleInputChange} />
            </div>
            {lastNameError && <AlertDanger message={lastNameError} />}
            <div className='mb-3'>
                <label className="block text-gray-800 mb-2 font-medium" htmlFor="email">
                    E-mailadres
                </label>
                <input
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email" type="text" defaultValue={currentUser.email} ref={emailRef} onChange={handleInputChange} />
            </div>
            {emailError && <AlertDanger message={emailError} />}
            <button
                className="bg-blue-700 w-full hover:bg-blue-900 disabled:bg-blue-400 transition-colors duration-300 ease-in-out text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                disabled={isLoading || !isFormModified}>
                Update
            </button>
        </form>
    </div>)
}

export default UserDetails;