const getHighlightedText = (text, highlightString) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const highlights = highlightString.split(" ");
    
    console.log("ORIGINAL\n",  text)

    let newText = text;

    highlights.forEach((highlight) => {
        const parts = newText.split(new RegExp(`(${highlight})`, 'gi'));
        newText = `${parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? `<span style="background-color:#5eead4">${part}</span>` : part).join("")}`;
    })
    console.log("NEW\n", newText)
    return newText;
}

export default getHighlightedText;