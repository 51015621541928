import React, { useState, useRef } from 'react';
import useAuth from '../../hooks/useAuth';
import { useNavigate, useLocation, redirect } from 'react-router-dom';
import AlertDanger from '../../components/ui/alerts/AlertDanger';
import ForgotPasswordModal from './ForgotPasswordModal';
import SignUpModal from './SignUpModal';
import AlertSuccess from '../../components/ui/alerts/AlertSuccess';
const is2FA = (process.env.IS_2FA.toLowerCase() === 'true')

const LoginForm = () => {
    const { login } = useAuth();

    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const emailRef = useRef();
    const passwordRef = useRef();
    const verificationCodeRef = useRef();
    const [showVerificationCode, setShowVerificationCode] = useState(false);
    const [signupSuccess, setSignupSuccess] = useState("");
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
    
    const handleShowSignupModal = () => {
        setSignupSuccess(false);
        setShowSignupModal(true);
        setError("")
    }

    const handleShowResetPasswordModal = () => setShowForgotPasswordModal(true);

    async function handleSubmit(e) {
        e.preventDefault();
        if (showSignupModal) {
            return;
        }
        setError("");
        setSignupSuccess("");
        if ((emailRef.current.value.length < 1) || (passwordRef.current.value.length < 1)) {
            setError("Geef je e-mailadres en paswoord in");
            return;
        }
        if ((!verificationCodeRef.current || (verificationCodeRef.current.value === '')) && is2FA ) {
            console.log('verification code not present')
            setShowVerificationCode(true);
        } else {
            try {
                setIsLoading(true);
                console.log("logging in")
                const password = is2FA ? passwordRef.current.value + verificationCodeRef.current.value :  passwordRef.current.value
                await login(emailRef.current.value, password);
                navigate(location.state ? location.state.from.pathname : "/");
            }
            catch (error) {
                console.error("login error", error.response);
                if (error.response && error.response.status === 400) {
                    if (error.response.data.detail === "LOGIN_USER_NOT_VERIFIED") {
                        setError("Je e-mailadres is nog niet geverifieerd. Check je inbox voor de verificatie-mail en volg de instructies alvorens opnieuw in te loggen.");
                    }
                    else {
                        setError("Deze combinatie van e-mailadres en paswoord is ons niet bekend.");
                    }
                } else {
                    setError(`Er ging iets fout. Refresh deze pagina en probeer opnieuw of contacteer ${process.env.ADMIN_EMAIL} als de fout zich blijft voordoen.`);
                }
            }
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="h-full w-full bg-white flex flex-col justify-between leading-normal">
                <div className="h-full lg:p-16 p-10 flex flex-col justify-between">
                    <div className="text-gray-800 text-md text-2xl xl:text-3xl mb-2 font-bold">Log in</div>
                    <div className="flex flex-col gap-4 mb-4">
                        {error && <AlertDanger message={error} />}
                        {signupSuccess && <AlertSuccess message={signupSuccess} />}
                        <form className="h-full flex flex-col justify-between" onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label
                                    className="block text-gray-600 text-sm mb-2"
                                    htmlFor="email"
                                >
                                    E-mailadres
                                </label>
                                <input
                                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="email" type="text" ref={emailRef}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-600 text-sm mb-2" htmlFor="password">
                                    Paswoord
                                </label>
                                <input
                                    className="appearance-none bForder rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="password" type="password" ref={passwordRef}
                                    required
                                />
                            </div>
                            {showVerificationCode &&
                                <div className="mb-4">
                                    <label className="block text-gray-600 text-sm mb-2" htmlFor="password">
                                        Verificatie code
                                    </label>
                                    <input
                                        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="verification-code" type="text" ref={verificationCodeRef}
                                        required
                                    />
                                    <small className='ml-2 text-gray-400'>Voer de code in van de tweefactorapp op uw mobiele apparaat.</small>
                                </div>
                            }
                            <div>
                                <button
                                    className="bg-blue-700 w-full hover:bg-blue-900 transition-colors mt-3 duration-300 ease-in-out text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    type="submit"
                                    disabled={isLoading}>
                                    Inloggen
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="flex items-center justify-between">
                        <a className="inline-block align-baseline text-sm transition-colors duration-300 ease-in-out text-gray-300 hover:text-gray-500 hover:cursor-pointer"
                            onClick={handleShowResetPasswordModal}>
                            Paswoord vergeten?
                        </a>
                        <span className="inline-block align-baseline text-sm text-gray-300">
                            Nog geen account?
                            <a className="cursor-pointer text-sm transition-colors duration-300 ease-in-out text-blue-700 hover:text-blue-900 ml-1"
                                onClick={handleShowSignupModal}>
                                Registreer hier
                            </a>
                        </span>
                    </div>
                </div>
            </div>
            <SignUpModal show={showSignupModal} close={() => setShowSignupModal(false)} setSuccess={setSignupSuccess} />
            <ForgotPasswordModal show={showForgotPasswordModal} close={() => setShowForgotPasswordModal(false)} />
        </>
    )
}

export default LoginForm;