import { useEffect, useState } from "react";
import React from "react";

import SharedTermsWidget from "../../components/sharedTerms/SharedTermsWidget";
import ActiveGroupsWidget from "../../components/activeSources/ActiveSourcesWidget";
import axios from "../../services/axios";

import TopicsChart from "../../components/topics/chart/TopicsChart";
import PolarityChart from "../PolarityChart";
import SummaryMarkdown from "../SummaryMarkdown";
import { AllTopicsSummaryButtons } from "./AllTopicsSummaryButtons";
import Skeleton from "react-loading-skeleton";

const AllTopicsSummary = ({ selectedTimeFrame, setSelectedTimeFrame }) => {
    
    const [chartData, setChartData] = useState();
    const [activeSources, setActiveSources] = useState();
    const [terms, setTerms] = useState([]);
    const [polarities, setPolarities] = useState();
    const [summary, setSummary] = useState();
    const [showPolaritiesWidget, setShowPolaritiesWidget] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            fetchChartData(),
            fetchTermsData(),
            fetchSourcesData(),
            fetchPolaritiesData(),
            fetchSummary()
        ]).finally(() => {
            setLoading(false);
        });
    }, [selectedTimeFrame]);


    const fetchSummary = async () => {
        setSummary();
        try {
            const summaryResponse = await axios.get(`topics-summary/summary?timeframe=${selectedTimeFrame}`);
            setSummary(summaryResponse.data)
        } catch (error) {
            console.error(error);
        }

    }


    const fetchTermsData = async () => {
        setTerms();
        try {
            const termsResponse = await axios.get(
                `topics-summary/terms?timeframe=${selectedTimeFrame}`
            );
            setTerms(termsResponse.data);
        } catch (error) {
            console.error(error)
        }

    }

    const fetchSourcesData = async () => {
        setActiveSources();
        try {
            const activeSourcesResponse = await axios.get(
                `topics-summary/sources?sort=most_active&timeframe=${selectedTimeFrame}`
            );
            setActiveSources(activeSourcesResponse.data);
        } catch (error) {
            console.error(error)

        }
    }

    const fetchPolaritiesData = async () => {
        setPolarities(false)
        try {
            const polaritiesResponse = await axios.get(`topics-summary/distribution?timeframe=${selectedTimeFrame}`)
            setPolarities(polaritiesResponse.data);
        } catch (error) {
            console.error(error)
        }
    }

    // Cache the chart data in session storage, improve perfomance and UX
    const fetchChartData = async () => {
        const cacheKey = `chartData_${selectedTimeFrame}`;
        const cachedData =  sessionStorage.getItem(cacheKey);

        if (cachedData) {
            setChartData(JSON.parse(cachedData));
        } else {
            setChartData(undefined);
            try {
                const chartResponse = await axios.get(
                    `/topics-summary/chart?timeframe=${selectedTimeFrame}`
                );
                setChartData(chartResponse.data);
                sessionStorage.setItem(cacheKey, JSON.stringify(chartResponse.data));
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <div className="pb-6 flex flex-col gap-6 h-3/4 ">
            <div className="grid grid-cols-3 gap-6">
                <div className="p-11 rounded bg-white shadow-sm border
                2xl:col-span-2 col-span-3 flex flex-col gap-4">
                    <div className="flex justify-between">
                        <h1 className="font-bold text-xl text-black">Trends</h1>
                        <select
                            id="topic-trends-time-frame"
                            value={selectedTimeFrame}
                            onChange={(e) => setSelectedTimeFrame(e.target.value)}
                            className="bg-violet-50 border text-black
                            py-1 px-3 text-sm border-gray-300 rounded-lg active:outline-none 
                            block p-2.5 dark:bg-gray-700 dark:border-gray-600 
                            dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 
                            dark:focus:border-blue-500 cursor-pointer"
                        >
                            <option value="month">Maand</option>
                            <option value="week">Week</option>
                        </select>
                    </div>
                    <TopicsChart
                        chartId={`seartchterms-all-topics`}
                        data={chartData}
                        timescale={selectedTimeFrame}
                    />
                </div>

                <div className="col-span-3 2xl:col-span-1 flex flex-col md:flex-row 2xl:flex-col gap-6">
                    {terms && (
                        <SharedTermsWidget
                            sharedTermData={terms}
                            timeFrame={selectedTimeFrame}
                            termsClickable={false}
                            searchTerms={[]}
                        />
                    )}
                    {activeSources && (
                        <ActiveGroupsWidget
                            mostActiveSourcesData={activeSources}
                            timeFrame={selectedTimeFrame}
                        />
                    )}
                </div>
            </div>
            {loading ? 
             <Skeleton height={"1rem"} width={"20rem"} />
             : polarities && 
                <>
                    <AllTopicsSummaryButtons showPolaritiesWidget={showPolaritiesWidget} 
                    setShowPolaritiesWidget={setShowPolaritiesWidget} />
                    {showPolaritiesWidget &&
                        <div className='flex'>
                            {polarities &&
                                <div className="h-[560px] w-full bg-white p-6 rounded ">
                                    <PolarityChart dataset={polarities.data} labels={polarities.labels} />
                                </div>
                            }
                        </div>
                    }
                </>
            }

            <div className="w-3/4 mx-auto">
                {!loading ?
                    <SummaryMarkdown summary={summary} />
                    :   
                    <Skeleton height={"15rem"} width={"70rem"} count={1} />                    
                }
            </div>

        </div>

    );
};

export default AllTopicsSummary;
