export const getIconColor = (platform) => {
    switch (platform) {
        case 'twitter':
            return '#1DA1F2';
        case 'facebook':
            return '#1877F2';
        case 'youtube':
            return '#FF0000';
        case 'reddit':
            return '#FF4500';
        case 'tiktok':
            return '#000000';
        default:
            return '#000000';
    }
}