import React from 'react';
import { useEffect, useState } from 'react';
import { Chart, LineController, BarController, BarElement, CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Tooltip, Legend, plugins } from 'chart.js';
Chart.register(LineController, BarController, BarElement, CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Tooltip, Legend);
import Spinner from '../../ui/Spinner';

import 'chartjs-adapter-moment';

export default function TopicsChart({ chartId, data, timescale }) {
    const [chart, setChart] = useState();
    useEffect(() => {
        if (chart){
            chart.destroy();
        }
        if (data) {

            const canvas = document.getElementById(chartId);

            const ctx = canvas.getContext('2d');

            // Sample data for the chart
            const chartData = {
                labels: data.labels,
                datasets: data.datasets,

            };
            // console.log("data", data)
            const tooltipFormat = timescale === 'month' ? "MMMM" : "DD MMM"
            let delayed;
            const config = {
                type: 'line',
                data: chartData,
                options: {
                    animation: {
                        onComplete: () => {
                            delayed = true;
                        }
                    },
                    responsive: true,
                    tooltips: {
                        mode: 'index'
                    },
                    hover: {
                        mode: 'index',
                        intersect: false
                    },
                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                boxWidth: 20, 
                                padding: 20,  
                            }
                        }
                    },
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            ticks: {
                                precision: 0
                            }
                        },
                        x: {
                            type: "time",
                            time: {
                                tooltipFormat: tooltipFormat,
                                unit: timescale
                            },
                            title: {
                                text: "Date"
                            }
                        }
                    }
                },
                plugins: [
                    // Add a plugin to the chart, increases spacing between labels/legend and chart
                    {
                        beforeInit(chart) {
                            const originalFit = chart.legend.fit;
                            chart.legend.fit = function fit() {
                                originalFit.bind(chart.legend)();
                                this.height += 30;
                            };
                        }
                    }
                ]
            };
            setChart(new Chart(ctx, config));
        }
        return

    }, [data]); // Empty dependency array ensures this runs only on component mount

    if (data) {
        return (
            <div className='flex-1 min-h-[40rem]'>
                <canvas id={chartId} ></canvas>
            </div>
        );
    }
    return (
        <div className='min-h-[664px] flex justify-center items-center'>
            <Spinner />
        </div>
    )
};

