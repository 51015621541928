import React from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import { CollapseNavMenu } from "./ui/CollapseNavMenu";


const NavItem = ({ id, to, title, target }) => {
  return ( 
    <li>
      <NavLink
        id={id}
        target={target || "_self"} 
        className={({ isActive }) => {
          return (
            "flex items-center pl-16 py-2 text-gray-500 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group " +
            (isActive && !id.includes("tab-logout")
              ? "bg-violet-50 border-r-4 border-r-blue-700 font-bold"
              : "")
          );
        }}
        to={to}
        end
      >
        {title}
      </NavLink>
    </li>
  )
}

export const SideNavBar = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  async function handleLogout() {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <aside
      id="navbar"
      className="min-h-screen hidden lg:block lg:w-1/6 bg-white shadow-md"
      aria-label="Sidebar"
    >
      <div className='h-[90vh] sticky top-11 overflow-y-auto'>
        <div className="gap-24 dark:bg-gray-800 flex flex-col ">
          <div className="flex flex-col gap-11">
            <Link to="/">
              <div className="pl-11">
                <img className="h-16" src="/logo-large.png" />
              </div>
            </Link>
            <ul name='nav-items' className="space-y-2 font-medium border-b border-b-violet-50">
              <NavItem id="tab-dashboard" to="/" title="Dashboard" />
              <NavItem id="tab-saved" to="/saved" title="Opgeslagen berichten" />
              
              <CollapseNavMenu name="Onderwerpen" collapsed={true}>
                <NavItem id="tab-topic-list" to="/topics" title="Onderwerpenlijst" />
                <NavItem id="tab-create-topic" to="/topics/new" title="Onderwerp creëren" />
              </CollapseNavMenu>

              <CollapseNavMenu name="Bronnen" collapsed={true}>
                <NavItem id="tab-source-list" to="/sources" title="Bronnenlijst" />
                <NavItem id="tab-add-source" to="/sources/new" title="Bron toevoegen" />
              </CollapseNavMenu>

              <CollapseNavMenu name="Gebruik" collapsed={true}>
                {/* <NavItem id="tab-status" to="/status" title="Status" /> */}
                <NavItem id="tab-manual" to="/manual" title="Handleiding" />
                <NavItem id="tab-api" to={`${process.env.API_URL}/redoc`} title="API" target="_blank" />
              </CollapseNavMenu>
            </ul>
          </div>

          <ul className="space-y-2 font-medium">
            <CollapseNavMenu name={currentUser.firstName} isUserMenu={true} collapsed={true} >
              <NavItem id="tab-profile" to="/profile" title="Profiel" />
              <div onClick={handleLogout}>
                <NavItem id="tab-logout" title="Uitloggen" />
              </div>
            </CollapseNavMenu>
          </ul>
        </div>
      </div>
    </aside>
  );
};

