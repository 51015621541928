import React from 'react';
const InfoAlert = ({ heading, message }) => {
    return (<div
        className="bg-orange-100  border-amber-400 py-4 px-6 flex flex-col gap-6 rounded-md border-l-8">
        {heading && <div className="flex gap-6 text-yellow-700 items-center">
            <i
                className="fa-solid fa-triangle-exclamation text-white bg-amber-400 rounded-md w-6 h-6 p-1 text-center"></i>
            <h1 className="text-2xl font-semibold text-yellow-700">{heading}</h1>
        </div>}
        <p className="text-orange-400 text-sm font-medium">{message}</p>
    </div>)
}

export default InfoAlert;