import React from 'react';
import { useContext, useState, useEffect, createContext } from 'react';
import axios from '../services/axios';



const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);


    const value = {
        currentUser,
        setCurrentUser,
        signup,
        login,
        logout,
        getUser,
        updateUser,
        verifyEmail,
        requestPasswordChange,
        requestEmailVerification,
        resetPassword, 
    }



    function signup(email, password, firstName, lastName) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post("/auth/register",
                    JSON.stringify({
                        "email": email,
                        "password": password,
                        'first_name': firstName,
                        'last_name': lastName
                    }),
                    {
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
                    }
                )

                const secondResponse = await requestEmailVerification(email);

                resolve(response.data);  // Resolve the promise with the response data
            } catch (error) {
                reject(error.response.data.detail);  // Reject the promise with the caught error
            }
        });
    }


    function verifyEmail(token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post("/auth/verify",
                    JSON.stringify({
                        "token": token,
                    }),
                    {
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
                    }
                )
                const data = response.data;
                // console.log("verification response", response)

                resolve(data);  // Resolve the promise with the response data
            } catch (error) {
                reject(error.response.data.detail);  // Reject the promise with the caught error
            }
        });
    }

    function requestEmailVerification(email) {

        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post("/auth/request-verify-token",
                    JSON.stringify({
                        "email": email,
                    }),
                    {
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
                    }
                )
                const data = response.data;

                resolve(data);  // Resolve the promise with the response data
            } catch (error) {
                reject(error.response.data.detail);  // Reject the promise with the caught error
            }
        });
    }


    function login(email, password) {
        // console.log("email", email, "password", password)
        return new Promise(async (resolve, reject) => {
            const body = new URLSearchParams();
            body.append('username', email);
            body.append('password', password);
            try {
                const response = await axios.post('/auth/cookie/jwt/login',
                    body,
                    {
                        withCredentials: true,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    });

                await getUser();

                resolve(response.data);
            } catch (error) {
                console.error("auth context throwing ", error);
                reject(error);  // Reject the promise with the caught error
            }
        });
    }

    function logout() {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post("/auth/cookie/jwt/logout",
                )
                resolve(response);  // Resolve the promise with the response data
            } catch (error) {
                reject(error);  // Reject the promise with the caught error
            }
        })
    }

    function updateUser(firstName, lastName, email, password) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.patch("/users/me",
                    JSON.stringify({
                        'first_name': firstName,
                        'last_name': lastName,
                        'email': email,
                        'password': password
                    }),
                    {
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
                    }
                );

                resolve(response);  // Resolve the promise with the response data
            } catch (error) {

                reject(error);  // Reject the promise with the caught error
            }
        })
    }

    function getUser() {

        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.get(
                    '/users/me');
                const userData = response.data;

                const email = userData.email;
                const firstName = userData.first_name;
                const lastName = userData.last_name;

                setCurrentUser({
                    "email": email,
                    'firstName': firstName,
                    'lastName': lastName
                });
                resolve(response);
            } catch (error) {
                setCurrentUser(null);

                reject(error.response);
            }
        })
    }

    function resetPassword(password, token) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post("/auth/reset-password",
                    JSON.stringify({
                        "password": password,
                        "token": token
                    }),
                    {
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
                    }
                )

                resolve(response);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    

    function requestPasswordChange(email) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post("/auth/forgot-password",
                    JSON.stringify({
                        "email": email
                    }),
                    {
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
                    }
                )

                resolve(response);
            } catch (error) {
                reject(error.response);
            }
        });
    }

    return (
        <AuthContext.Provider value={value} >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;