import React, { useState, useRef, useEffect } from "react";
import { MainLayout } from "../../components/layouts/MainLayout";
import BreadCrumbBar from "../../components/BreadCrumbBar";
import ContentHeader from "../../components/ContentHeader";
import SourcesTable from "../../components/sources/SourcesTable";
import axios from "../../services/axios";
import AlertDanger from "../../components/ui/alerts/AlertDanger";
import { useNavigate } from "react-router";
import AlertSuccess from "../../components/ui/alerts/AlertSuccess";
import Spinner from "../../components/ui/Spinner";
import languages from "../../utils/languages";

const AddTopicPage = () => {
  const topicNameRef = useRef();
  const topicDescriptionRef = useRef();
  const navigate = useNavigate();
  const searchTermInputRef = useRef();
  const [success, setSuccess] = useState(false);
  const formRef = useRef();
  const [searchTermInputValues, setSearchTermInputValues] = useState([]);
  const [linkedSourceIds, setLinkedSourceIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestSearchTermsLoading, setSuggestSearchTermsLoading] =
    useState(false);
  const [searchTermsSuggested, setSearchTermsSuggested] = useState(false);
  const [searchTermsSuggestedError, setSearchTermsSuggestedError] = useState();
  const [sources, setSources] = useState();
  const [titleError, setTitleError] = useState();
  const [searchTermError, setSearchTermError] = useState();
  const [showAddSourcesPanel, setShowAddSourcesPanel] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("nl");

  const removeAllKeywords = (event) => {
    event.preventDefault();
    setSearchTermInputValues([]);
  };

  const handleInputChange = (index, value) => {
    const newValues = [...searchTermInputValues];
    newValues[index] = value;
    setSearchTermInputValues(newValues);
  };

  const handleKeyPress = (event) => {
    const newKeyword = event.target.value;
    if (newKeyword.length > 0 && event.keyCode === 13) {
      event.preventDefault();
      setSearchTermInputValues([...searchTermInputValues, newKeyword]);
      event.target.value = "";
    } else {
      if (event.keyCode === 8 && newKeyword.length < 1) {
        if (searchTermInputValues.length > 0) {
          const keywordsCopy = [...searchTermInputValues];
          keywordsCopy.pop();
          setSearchTermInputValues(keywordsCopy);
        }
      }
    }
  };

  useEffect(async () => {
    document.title = `${process.env.APP_NAME} • Onderwerp toevoegen`;
    await fetchSources();
  }, []);

  const fetchSources = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/sources`);
      setSources(response.data);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setTitleError();
    setSearchTermsSuggestedError();

    const topicDescription = topicDescriptionRef.current.value;

    const topicName = topicNameRef.current.value;
    let hasErrors = false;

    if (!topicName) {
      setTitleError("Je onderwerp moet een naam hebben.");
      hasErrors = true;
    }
    if (searchTermInputValues.length === 0) {
      setSearchTermsSuggestedError(
        "Je onderwerp moet minimaal 1 zoekterm bevatten."
      );
      hasErrors = true;
    }
    if (hasErrors) {
      window.scrollTo({ top: 0 });
      return;
    }

    try {
      setIsLoading(true);
      await axios.post("/topics", {
        title: topicName,
        description: topicDescription,
        search_terms: searchTermInputValues,
        source_uuids: linkedSourceIds,
        language: selectedLanguage
      });
      formRef.current.reset();
      setSearchTermInputValues([]);
      setLinkedSourceIds([]);
      setSuccess(true);
      window.scrollTo({ top: 0 });
      setTimeout(() => {
        navigate("/topics");
      }, 1000);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const suggestSearchTerms = async (event) => {
    event.preventDefault();
    const topicTitle = topicNameRef.current.value;
    const topicDescription = topicDescriptionRef.current.value;
    setSearchTermsSuggestedError("");
    if (topicTitle.length < 1) {
      setSearchTermsSuggestedError(
        "Please provide at least a title for your topic (and optionally a description)."
      );
    } else if (searchTermsSuggested) {
      setSearchTermsSuggestedError(
        "Only 20 new suggested search terms may be requested per new topic."
      );
    } else {
      setIsLoading(true);
      setSuggestSearchTermsLoading(true);
      setSearchTermsSuggested(true);
      const searchParams = new URLSearchParams();
      searchParams.append("title", topicTitle);
      searchParams.append("language", selectedLanguage)
      if (topicDescription) {
        searchParams.append("description", topicDescription);
      }
      try {
        const response = await axios.get("/topics/hints?" + searchParams);
        if (!topicDescriptionRef.current.value) {
          topicDescriptionRef.current.value = response.data.description;
        }
        const searchTermData = response.data.search_terms;
        const newSearchTerms = searchTermData.map(
          (searchTerm) => searchTerm.term
        );
        setSearchTermInputValues((previousValues) => {
          return [...previousValues, ...newSearchTerms];
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        setSuggestSearchTermsLoading(false);
      }
    }
  };

  const resizePillInput = (input) => {
    input.style.width = `${input.scrollWidth}px`;
  };

  useEffect(() => {
    searchTermInputValues.forEach((value, index) => {
      const input = document.getElementById(`keyword-input-${index}`);
      if (input) {
        resizePillInput(input);
      }
    });
  }, [searchTermInputValues]);

  const removeInputField = (index) => {
    const newValues = [...searchTermInputValues];
    newValues.splice(index, 1);
    setSearchTermInputValues(newValues);
  };

  return (
    <>
      <ContentHeader
        heading="Onderwerp op maat creëren"
        subtitle="Een onderwerp op maat moet een titel hebben, een doeltaal en minstens 1 zoekterm. Zoektermen
        voeg je toe door een woord of frase in te typen en op enter te drukken. Je kan ook een beschrijving van je onderwerp toevoegen. 
        Zo'n beschrijving helpt de AI om gescrapetete data toe te wijzen aan je onderwerp. Als je niet zeker weet welke
        zoektermen of beschrijving je moet ingeven, kan je aan de AI vragen om suggesties te doen."

      />

      <form
        className="flex flex-col gap-6 items-start w-full"
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <div className="flex lg:justify-between lg:flex-row lg:gap-0 gap-2 flex-col w-full">
          <div className="flex flex-col gap-2 lg:w-2/5">
            <label
              htmlFor="topic-name"
              className="text-base font-medium text-gray-800"
            >
              Titel
            </label>
            <input
              id="topic-name"
              type="text"
              className="h-14 rounded-lg pl-5 border border-neutral-200 text-gray-500"
              ref={topicNameRef}
            />
            {titleError && (
              <div className="max-w-md">
                <AlertDanger message={titleError} />
              </div>
            )}
            <label htmlFor="language" className="text-base font-medium text-gray-800">Taal</label>
            <select id="language" 
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={selectedLanguage}
            onChange={(event) => setSelectedLanguage(event.target.value)}
            >
              {languages.map((language) => <option value={language.code}>{language.name}</option>)}
            </select>
            <label
              htmlFor="topic-info"
              className="text-base font-medium text-gray-800"
            >
              Beschrijving
            </label>
            <textarea
              id="topic-info"
              className="rounded-lg py-4 pl-5 border border-neutral-200 text-gray-500"
              ref={topicDescriptionRef}
            />
          </div>
          <div className="flex flex-col gap-2 lg:w-1/2">
            <label
              htmlFor="topic-keywords"
              className="text-base font-medium text-gray-800"
            >
              Zoektermen
            </label>

            <div
              id="topic-keywords"
              type="text"
              onClick={(event) => {
                if (document.getElementById("topic-keywords") !== event.target)
                  return;
                searchTermInputRef.current.focus();
              }}
              className="rounded-lg p-5 border border-neutral-200 text-gray-500 bg-white flex flex-wrap"
            >
              {searchTermInputValues.map((value, index) => (
                <span key={"span-" + index} className="pill-input">
                  <input
                    id={`keyword-input-${index}`}
                    type="text"
                    className="topic-keyword-input"
                    size="1"
                    key={"keywordInput-" + index}
                    onInput={(e) => resizePillInput(e.target)}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    value={value}
                  />
                  <i
                    className="delete flex items-center"
                    key={"i-" + index}
                    onClick={() => removeInputField(index)}
                  >
                    &times;
                  </i>
                </span>
              ))}
              <input
                type="text"
                className="bg-transparent focus:outline-none focus:ring-0"
                ref={searchTermInputRef}
                onKeyDown={handleKeyPress}
              />
              <div />
            </div>
            {searchTermsSuggestedError && (
              <div className="w-7/12">
                <AlertDanger message={searchTermsSuggestedError} />
              </div>
            )}

            <div className="flex gap-4">
              <button
                className="btn"
                onClick={suggestSearchTerms}
                disabled={isLoading || suggestSearchTermsLoading}
              >
                {suggestSearchTermsLoading && (
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                )}
                AI-suggesties
              </button>
              <button
                className="btn--warning"
                onClick={removeAllKeywords}
                disabled={isLoading || suggestSearchTermsLoading}
              >
                Verwijder alle zoektermen
              </button>
            </div>
          </div>
        </div>
        <button
          type="button"
          onClick={() => {
            setShowAddSourcesPanel((prev) => !prev)
            if (!showAddSourcesPanel) { setLinkedSourceIds([]) }
          }}
          className="flex items-center pr-6 text-base text-gray-500 hover:text-gray-800 font-semibold transition duration-75 group dark:text-white "
        >
          Wijs bronnen toe aan dit onderwerp (optioneel)
          {showAddSourcesPanel ? <i className='ml-2 fa-solid fa-minus'></i> : <i className='ml-2 fa-solid fa-plus'></i>}
        </button>

        {
          showAddSourcesPanel &&
          <>
            <span className="text-gray-500 ml-6">
              Als je zeker weet dat een bron voornamelijk posts bevat over het onderwerp in kwestie,
              kan je die hier linken.
            </span>
            <div id="source-list-container" className="gap-6 flex flex-col w-full">

              {sources && (
                <SourcesTable
                  selectable={true}
                  tableId={"source-table"}
                  checkedSourceIds={linkedSourceIds}
                  setCheckedSourceIds={setLinkedSourceIds}
                  sources={sources}
                  noneMesssage={"Geen bronnen beschikbaar."}
                />
              )}
            </div>
          </>
        }


        <button className="btn w-32" type="submit" disabled={isLoading}>
          Creëer
        </button>
      </form >
    </>
  );
};

export default AddTopicPage;
