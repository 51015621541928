import React from 'react';
export default function TextCard({ heading, content }) {
    return (<div className='p-6 rounded bg-white'>
        <div className='flex gap-4'>
            <h1 className='secondary-widget-heading'>Twijfel</h1>
        </div>
        <div>
            <p className='text-gray-700'>{content}</p>
        </div>
    </div>)
}