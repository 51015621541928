import React from 'react'
import Select from 'react-select'
import formatPostType from '../utils/formatting/formatPost';
import makeAnimated from 'react-select/animated';

const formatOptionLabel = ({ value, label }) => (
    <div className='flex'>
        <div className="mr-2">
            {formatPostType(value)['icon']}
        </div>
      <div className='text-slate-600'>{label}</div>
    </div>
  );


const PlatformSelect = ({ platforms, selectedPlatforms, setSelectedPlatforms }) => {

    const handleChange = selectedOptions => {
        setSelectedPlatforms(selectedOptions);
    };

    return (
    <Select
        components={makeAnimated()}
        isMulti
        styles={{
            container: (base) => ({
                ...base,
                width: '100%'
            }),
            control: (base) => ({
                ...base,
                width: '100%',
                cursor: "pointer"
            }),
            option: (base) => ({
                ...base,
                backgroundColor: "#FFFAFA",
                cursor: "pointer",
                ":hover": {backgroundColor: "#E0E0E0"}
            }),
            multiValueRemove: (base) => ({
                ...base,
                color: "black",
                transition: "color 0.3s ease, background-color 0.3s ease",
                ":hover": {
                    backgroundColor: "#f26b5e",
                    color: "white",
                }
            }),
            multiValue: (styles) => {
                return {
                    ...styles,
                    backgroundColor: "#FFFAFA",
                    border: "1px solid #E0E0E0",
                    cursor: "default",
                };
            },
          }}
        
        options={platforms}
        onChange={handleChange}
        defaultValue={platforms}
        formatOptionLabel={formatOptionLabel}   
        placeholder={"Selecteer een platform waarvan je berichten wilt bekijken..."}
        value={selectedPlatforms}
    />)
}

export default PlatformSelect;