import React, { useEffect, useState } from 'react';
import SharedTerm from './SharedTerm';

export default function SharedTermsWidget({ sharedTermData, timeFrame, searchTerms, termsClickable, topicId }) {
    
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setIsVisible(true), 50);
        return () => clearTimeout(timer);
    }, [])
    
    return (
        <div className={`p-6 rounded bg-white flex flex-col gap-4 shadow-sm border
        transition-opacity duration-500 ${isVisible ? "opacity-100" : "opacity-0"}`}>
            <div className='flex gap-4 items-center border-b'>
                <i
                    className="fa-regular fa-cube rounded text-2xl text-teal-500 p-4 w-14 h-14"></i>
                <h1 className='text-black text-lg font-semibold'>{`Meest markante termen deze ${timeFrame === "month" ? "maand" : "week"}`}</h1>
            </div>
            {
                !sharedTermData || !sharedTermData.length &&
                <h1 className='text-gray-500  text-sm font-semibold self-center justify-self-center'>
                    Dit onderwerp heeft geen termen
                </h1>
            }

            {
                sharedTermData && sharedTermData.length &&
                <div className='flex gap-4 flex-wrap'>

                    {
                        sharedTermData.map((term, index) => {

                            let isSearchTerm = false;
                            for (let searchTerm of searchTerms) {
                                if (term.term === searchTerm) {
                     
                                    isSearchTerm = true;
                                }
                            }

                            return <SharedTerm key={term.term + "-" + index} isSearchTerm={isSearchTerm} isClickable={termsClickable} term={term.term} topicId={topicId} />
                        }
                        )
                    }


                </div>
            }

        </div>
    )
}