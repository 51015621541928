import React, { useState, useEffect } from 'react'
import useAuth from '../hooks/useAuth';
import { Outlet } from 'react-router-dom';

const PersistLogin  = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { currentUser, getUser } = useAuth();


    useEffect(() => {

        const getUserOnRefresh = async () => {
            try {
                await getUser();
            } catch (error) {
                console.error(error);

            }
            finally {
                setIsLoading(false);
            }
        }

        !currentUser ? getUserOnRefresh() : setIsLoading(false);

    }, [])

    return (
        <>
            {isLoading
                ? <p>Loading ... </p> :
                <Outlet />}
        </>
    )
}
export default PersistLogin;