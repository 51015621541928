import React, { useEffect } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Routes,
} from "react-router-dom";
import HomePage from "./pages/Home/HomePage";
import NotFoundPage from "./pages/NotFound/NotFoundPage";
import "./assets/scss/styles.scss";
import "./assets/fontawesome-pro-6.0.0-beta3-web/css/all.min.css";
import useAuth from "./hooks/useAuth";

import SourcesPage from "./pages/Sources/SourcesPage";
import AddSourcePage from "./pages/AddSource/AddSourcePage";
import TopicsPage from "./pages/Topics/TopicsPage";
// import CreateTopicPage from './pages/CreateTopic/AddTopicPage';
import { MainLayout } from "./components/layouts/MainLayout";
import ProfilePage from "./pages/Profile/ProfilePage";
import TopicDetailPage from "./pages/TopicDetail/TopicDetailPage";
import { LoginLayout } from "./components/layouts/LoginLayout";
import LoginPage from "./pages/Login/LoginPage";
import RequireAuth from "./components/RequireAuth";
import { AuthProvider } from "./contexts/AuthContext";
import PersistLogin from "./components/PersistLogin";
import ResetPasswordPage from "./pages/ResetPassword/ResetPasswordPage";
import VerifyEmailPage from "./pages/VerifyEmail/VerifyEmailPage";
import AddTopicPage from "./pages/AddTopic/AddTopicPage";
import StatusPage from "./pages/Status/StatusPage";
import EditTopicPage from "./pages/EditTopic/EditTopicPage";
import ManualPage from "./pages/Manual/ManualPage";
import DPAPage from "./pages/DPA/DPApage";
import axios from "./services/axios";
import SavedPostsPage from "./pages/SavedPosts/SavedPostsPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route>
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<HomePage />} />
              <Route path="sources" element={<SourcesPage />} />
              <Route path="sources/new" element={<AddSourcePage />} />
              <Route path="topics" element={<TopicsPage />} />
              <Route path="topics/:id" element={<TopicDetailPage />} />
              <Route path="topics/:id/edit" element={<EditTopicPage />} />
              <Route path="topics/new" element={<AddTopicPage />} />
              <Route path="profile" element={<ProfilePage />} />
              <Route path="status" element={<StatusPage />} />
              <Route path="manual" element={<ManualPage />} />

              <Route path="saved" element={<SavedPostsPage />} />
              <Route path="*" element={<NotFoundPage />}></Route>
            </Route>
          </Route>
        </Route>
        <Route element={<LoginLayout />}>
          <Route path="login" element={<LoginPage />}></Route>
          <Route path="reset" element={<ResetPasswordPage />}></Route>
          <Route path="verify" element={<VerifyEmailPage />}></Route>
          <Route path="dpa" element={<DPAPage />} />
          {/* <Route path='*' element={<LoginPage />}></Route> */}
        </Route>
      </Route>
    </>
  )
);

export default function App() {
  function refresh() {
    return new Promise(async (resolve, reject) => {});
  }

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        // User has returned to the tab

        try {
          const response = await axios.post("/auth/cookie/jwt/refresh");
        } catch (error) {
          console.error("APP ERROR", error);
        }
      } else {
        // User has navigated away from the tab
        console.log("User has navigated away from the tab");
        // You may want to clear any sensitive data or perform other actions
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean-up function to remove the event listener
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}
