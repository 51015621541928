import Markdown from 'markdown-to-jsx'
import React from 'react'

export default function SummaryMarkdown({ summary }) {
  return (
        <div className='flex flex-col gap-6 self-center'>
            {summary && summary.samenvatting &&
                <div className='p-6 rounded bg-white flex flex-col border shadow-sm'>
                    <div className='flex gap-4 items-center mb-4'>
                        <i className="fa-light fa-file rounded 
                        bg-teal-50 text-2xl text-teal-500 p-4 w-14 h-14"></i>
                        <h1 className=' text-black text-lg font-semibold'>Samenvatting</h1>
                    </div>
                    <Markdown className='text-gray-500 mb-4 list-disc'>{summary.samenvatting}</Markdown>
                    {summary.concepten &&
                        <div className='flex gap-4 flex-wrap'>

                            {
                                summary.concepten.map((concept, index) => {
                                    return <span 
                                    key={concept + "-" + index} 
                                    className="inline-flex items-center 
                                    rounded-full text-sm bg-gray-50 px-2 py-2 font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                        {concept}
                                    </span>
                                })
                            }
                        </div>
                    }
                </div>
            }
        </div>  
    )
}
