import React, { useState, useRef, useEffect } from "react";
import manual1 from "../../assets/images/manual/Picture 1.png";
import manual2 from "../../assets/images/manual/Picture 2.png";
import manual3 from "../../assets/images/manual/Picture 3.png";
import manual3b from "../../assets/images/manual/Picture 3b.png";
import manual4 from "../../assets/images/manual/Picture 4.png";
import manual5 from "../../assets/images/manual/Picture 5.png";
import manual6 from "../../assets/images/manual/Picture 6.png";
import manual7 from "../../assets/images/manual/Picture 7.png";
import manual8 from "../../assets/images/manual/Picture 8.png";
import manual9 from "../../assets/images/manual/Picture 9.png";
import manual10 from "../../assets/images/manual/Picture 10.png";
import manual11 from "../../assets/images/manual/Picture 11.png";
import manual12 from "../../assets/images/manual/Picture 12.png";

import Vlaanderen from "../../assets/images/Vlaanderen_verbeelding werkt.png";

const AddStatusPage = () => {
  useEffect(() => {
    document.title = `${process.env.APP_NAME} • Handleiding`;
  }, []);

  return (
    <>
      <h1 className="text-gray-900 text-4xl font-semibold">
        Handleiding (versie 2024-05-15)
      </h1>

      <div>
        <span className="text-gray-900 text-1xl">
          [
          <a href="#dashboard" className="underline">
            Dashboard
          </a>
          ]
        </span>
        &nbsp;
        <span className="text-gray-900 text-1xl">
          [
          <a href="#sources" className="underline">
            Bronnen
          </a>
          ]
        </span>
        &nbsp;
        <span className="text-gray-900 text-1xl">
          [
          <a href="#topics" className="underline">
            Onderwerpen
          </a>
          ]
        </span>
        &nbsp;
        <span className="text-gray-900 text-1xl">
          [
          <a href="#opgeslagen" className="underline">
            Opgeslagen berichten
          </a>
          ]
        </span>
        &nbsp;
        <span className="text-gray-900 text-1xl">
          [
          <a href="#status" className="underline">
            Status
          </a>
          ]
        </span>
        &nbsp;
        <span className="text-gray-900 text-1xl">
          [
          <a href="#flowchart" className="underline">
            Data Flow
          </a>
          ]
        </span>
        &nbsp;
        <span className="text-gray-900 text-1xl">
          [
          <a
            href="https://docs.google.com/spreadsheets/d/1xaBAYZ7RxR5pbLUBpdB2LYrpbEt6U6cIPvsUXZiJVCM/edit#gid=0"
            className="underline"
            target="_BLANK"
          >
            Bugs melden
          </a>
          ]
        </span>
      </div>

      <h2 className="text-gray-900 text-3xl underline font-semibold">
        <a name="dashboard">Dashboard</a>
      </h2>

      <span className="text-gray-900">
        Het eerste scherm dat je te zien krijgt nadat je bent ingelogd is het
        Dashboard. Helemaal bovenaan vind je een overzicht van de onderwerpen
        die momenteel trending zijn. Daaronder vind je een timeline van de vijf
        meest actieve onderwerpen waarvan je{" "}
        <a href="#addtopic" className="underline">
          eigenaar
        </a>{" "}
        bent of waarop je{" "}
        <a href="#subscribed" className="underline">
          geabonneerd
        </a>{" "}
        bent. De <b>tijdlijn</b> toont per maand of per week aan wanneer en hoe
        vaak er is gereageerd op een post voor de onderwerpen in kwestie. Naast
        de grafiek zie je ook een infobox <b>Meest markante termen</b> met
        daarin de kernwoorden die uit het oog springen in de top-5 onderwerpen
        uit de grafiek. Een tweede infobox <b>Meest actieve bronnen</b> geeft
        weer uit welke bronnen de discussies over deze onderwerpen komen. Onder
        de grafiek zie je de{" "}
        <b>
          {" "}
          <a href="#feed" className="underline">
            social feed
          </a>
        </b>
        .
      </span>

      <img className="w-1/2 shadow-lg " src={manual1} />

      <span className="text-gray-900"></span>

      <hr></hr>
      <h2 className="text-gray-900 text-3xl underline font-semibold">
        <a name="sources">Bronnen</a>
      </h2>

      <span className="text-gray-900">
        Wanneer je op{" "}
        <a href="/sources" className="underline font-semibold" target="_blank">
          Bronnenlijst
        </a>{" "}
        klikt, krijg je een overzicht van alle bronnen die momenteel in de
        database opgenomen zijn, inclusief bronnen die andere gebruikers hebben
        toegevoegd. Je kan op de titel van de bron klikken om naar de pagina te
        gaan op het Internet.
      </span>

      <img className="w-1/2 shadow-lg" src={manual2} />

      <span className="text-gray-900">
        Je kan ook zelf bronnen toevoegen via{" "}
        <a
          href="/sources/new"
          className="underline font-semibold"
          target="_blank"
        >
          Bron toevoegen
        </a>
        . Je hoeft enkel je bron een titel te geven en de URL van de bron in
        kwestie in te geven. Je kan (publieke) Facebookpagina's en -groepen,
        Twitter profielen, Youtube kanalen, Wordpress sites en TikTok profielen
        toevoegen. Onze scrapers nemen deze bron dan mee vanaf de volgende
        iteratie.
      </span>

      <img className="w-1/3 shadow-lg" src={manual3} />
      <span className="text-gray-900">
        Je kan ook bronnen toevoegen in batch door een Excel-bestand in te
        vullen en te uploaden.
      </span>
      <img className="w-1/3 shadow-lg" src={manual3b} />

      <hr></hr>

      <h2 className="text-gray-900 text-3xl underline font-semibold">
        <a name="topics">Onderwerpen</a>
      </h2>
      <span className="text-gray-900">
        Onderwerpen zijn het belangrijkste concept in het dashboard. Ze
        groeperen de gescrapetete data en vormen de basisunit voor de analyses.
        Op de pagina{" "}
        <a href="/topics" className="underline font-semibold" target="_blank">
          Onderwerpenlijst
        </a>{" "}
        krijg je een overzicht van de onderwerpen, gegroepeerd in drie tabellen:
        (1) de onderwerpen die je zelf hebt aangemaakt, (2) de onderwerpen
        waarop je geabonneerd bent en (3) alle andere onderwerpen. Door op de
        titel van de bron te klikken, ga je naar de{" "}
        <a href="#topicpage" className="underline">
          analysepagina
        </a>{" "}
        van deze bron.
      </span>

      <h6 className="text-gray-900 font-semibold">Eigen Onderwerpen</h6>
      <span className="text-gray-900">
        Dit zijn onderwerpen die je{" "}
        <a href="#addtopic" className="underline">
          zelf hebt aangemaakt
        </a>
        .
      </span>
      <img className="w-1/3 shadow-lg" src={manual4} />

      <h6 className="text-gray-900 font-semibold">
        <a name="subscribed">Geabonneerde Onderwerpen</a>
      </h6>
      <span className="text-gray-900">
        Wanneer je voor het eerst bent ingelogd op het dashboard zal je
        automatisch geabonneerd zijn op de tien meest actieve onderwerpen. Je
        kan je voor deze onderwerpen uitschrijven als ze je niet interesseren.
      </span>
      <img className="w-1/3 shadow-lg" src={manual5} />

      <h6 className="text-gray-900 font-semibold">Andere Onderwerpen</h6>
      <span className="text-gray-900">
        Helemaal onderaan deze pagina vind je het overzicht van alle beschikbare
        onderwerpen in de database waar je je op kan inschrijven. Er zijn twee
        types van onderwerpen: (1) onderwerpen die gebruikers hebben aangemaakt
        en (2) IPTC-onderwerpen. Deze laatste zijn onderwerpen die automatisch
        gegenereerd worden op basis van de{" "}
        <a href="https://iptc.org/standards/media-topics/" target="_BLANK">
          <span class="underline">IPTC-categorieën (mediatopics)</span>
        </a>{" "}
        die onze scrapers detecteren. Contacteer de{" "}
        <a HREF="mailto:guy@textgain.com">administrator</a> als IPTC-onderwerpen
        niet beschikbaar zijn in je omgeving en je deze wil laten activeren.
      </span>
      <img className="w-1/2 shadow-lg" src={manual6} />

      <h2 className="text-gray-900 text-2xl underline font-semibold">
        <a name="addtopic">Onderwerpen creëren</a>
      </h2>
      <span className="text-gray-900">
        Je kan zelf ook een onderwerp op maat aanmaken door naar de pagina{" "}
        <a
          href="/topics/new"
          className="underline font-semibold"
          target="_blank"
        >
          Onderwerp creëren
        </a>{" "}
        te gaan. Hier kan je je nieuwe onderwerp een titel en beschrijving geven
        en zoektermen toevoegen. De tool beperkt zich tot het Nederlands dus je
        kan alleen Nederlandstalige zoektermen meegeven. Je hebt ook de
        mogelijkheid om onze AI automatisch zoektermen en een beschrijving te
        laten generen. Je kan deze nog bewerken naderhand.
      </span>

      <img className="w-1/2 shadow-lg" src={manual7} />

      <span className="text-gray-900">
        Het is niet nodig om bronnen toe te voegen aan je onderwerp. We zoeken
        automatisch in alle bronnen naar de sleutelwoorden van je onderwerp.
        Maar je kan ook specifieke bronnen toevoegen aan je onderwerp, als je
        wil dat alle posts uit deze bronnen aan het onderwerp worden toegewezen.
      </span>
      <img className="w-1/2 shadow-lg" src={manual8} />

      <h2 className="text-gray-900 text-2xl underline font-semibold">
        <a name="topicpage" className="underline">
          Onderwerp: analysepagina
        </a>
      </h2>
      <span className="text-gray-900">
        Dit is de overzichtspagina voor het onderwerp. Via de edit link kan je
        het{" "}
        <a href="#edittopic" className="underline">
          onderwerp aanpassen
        </a>
        . De tijdlijn toont het aantal berichten in de 5 meest actieve bronnen.
        Net zoals op het dashboard vind je ook de infoboxen met de meest
        markante termen en de meest actieve bronnen. De termen zijn klikbaar:
        als de term een goede zoekterm voor het onderwerp in kwestie is, kan je
        deze hier selecteren om ze snel toe te voegen aan het onderwerp.
        <br></br>Onder de grafieken vind je een samenvatting van de discussies
        die over dit onderwerp zijn gevoerd. Je kan de tijdspanne van de analyse
        wijzigen door naast de timeline "week" of "maand" te selecteren. De
        grafieken, tabellen en de samenvatting passen zichzelf dan aan.<br></br>
        Onder de samenvatting vind je de{" "}
        <a href="#feed" className="underline">
          feed
        </a>
        .
      </span>
      <img className="w-1/2 shadow-lg" src={manual9} />

      <h6 className="text-gray-900 font-semibold">
        <a name="feed">De Feed</a>
      </h6>

      <span className="text-gray-900">
        Een analysepagina bevat ook een feed. Hier zie je de berichten voor het
        onderwerp in kwestie (of op de overzichtspagina van al je onderwerpen).
        De feed is standaard chronologisch geordend en toont de meest recente
        berichten bovenaan. Je kan de feed ook sorteren volgens viraliteit of je
        kan hem filteren op social media platform, tijdspanne en trefwoorden.
        <br></br>
        Sommige posts, met name die posts waar een uitgebreide discussie onder
        is losgebarsten, beginnen met een samenvatting. De samenvatting bevat
        ook links naar de comments in kwestie.
        <br></br>
        Onder de samenvatting van de discussie vind je de post zelf
        en een lijst van relevante commentaren. Door op de
        datum van de post of comment te klikken, kan je naar de originele post
        of comment gaan. Opgelet: je kan niet doorklikken naar de precieze
        locatie van een comment op TikTok.
        <br></br>
        <b><small>Noot: het doorlinken naar posts en comments is niet voor alle
        account-types beschikbaar. Contacteer de{" "}
        <a HREF="mailto:guy@textgain.com">administrator</a> als je deze optie wil
        activeren.</small></b>
        <br></br>

        Je kan ook berichten opslaan door op de knop "opslaan" te klikken. Ze
        komen dan terecht in je persoonlijke overzicht op de pagina{" "}
        <a href="#opgeslagen" className="underline">
          Opgeslagen berichten
        </a>
        .{" "}
      </span>
      <img className="w-1/2 shadow-lg" src={manual11} />

      <h2 className="text-gray-900 text-2xl underline font-semibold">
        <a name="edittopic" className="underline">
          Onderwerp aanpassen
        </a>
      </h2>
      <span className="text-gray-900">
        Op deze pagina kan je een bestaand onderwerp aanpassen. Je kan nieuwe
        zoektermen toevoegen en nieuwe bronnen linken. Het is niet toegelaten om
        bestaande bronnen en zoektermen te verwijderen. Dit dashboard is een{" "}
        <em>community effort</em> en we willen vermijden dat gebruikers elkaars
        werk ongedaan maken.
      </span>
      <img className="w-1/2 shadow-lg" src={manual10} />

      <hr></hr>
      <h2 className="text-gray-900 text-3xl underline font-semibold">
        <a name="opgeslagen">Opgeslagen berichten</a>
      </h2>
      <span className="text-gray-900">
        Deze pagina toont het persoonlijke overzicht van berichten die je hebt
        opgeslagen uit de feed. Hier kan je ook aanduiden als je een bericht
        hebt afgehandeld of niet en kan je opzoeken of er gerelateerde
        factchecks bestaan in de BENEDMO factcheck-database.
      </span>
      <img className="w-1/2 shadow-lg" src={manual12} />

      <hr></hr>
      <h2 className="text-gray-900 text-3xl underline font-semibold">
        <a name="status">Status</a>
      </h2>

      <span className="text-gray-900">
        De statuspagina geeft een overzicht van de scrapers die worden gebruikt
        en of ze actief zijn. Scrapers zijn onderhevig aan veranderingen in de
        API's van de bronnen die ze scrapen. Het kan dus zijn dat een scraper
        tijdelijk niet werkt. De changelog-tabel geeft een overzicht van de
        reeds uitgevoerde en geplande werkzaamheden.<br></br>Als je een bug wil
        melden of een idee hebt voor een leuke feature, kan je die{" "}
        <a
          href="https://docs.google.com/spreadsheets/d/1xaBAYZ7RxR5pbLUBpdB2LYrpbEt6U6cIPvsUXZiJVCM/edit#gid=0"
          className="underline"
          target="_BLANK"
        >
          hier aanmelden
        </a>
        .
      </span>

      <hr></hr>
      <h2 className="text-gray-900 text-3xl underline font-semibold">
        <a name="flowchart">Data FlowChart</a>
      </h2>
      <iframe
        width="1000"
        height="600"
        src="https://miro.com/app/live-embed/uXjVNvXB6b8=/?moveToViewport=-1253,-699,2799,1917&embedId=327331728236"
        // frameBorder="0"
        // scrolling="no"
        allow="fullscreen; clipboard-read; clipboard-write"
        allowFullScreen
      ></iframe>

      <h2 className="text-gray-900 text-2xl underline font-semibold">
        <a name="edittopic" className="underline">
          Vlaanderen
        </a>
      </h2>
      <span className="text-gray-900">
        Dit project kwam tot stand met de steun van de Vlaamse overheid.{" "}
      </span>
      <img className="w-1/2" src={Vlaanderen} />

      <hr></hr>
    </>
  );
};

export default AddStatusPage;
