import React, { useState, useRef } from 'react'
import axios from '../../services/axios';
import AlertDanger from '../../components/ui/alerts/AlertDanger';
import AlertSuccess from '../../components/ui/alerts/AlertSuccess';


const PasswordReset = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);
    const currentPasswordRef = useRef();
    const newPasswordRef = useRef();
    const confirmNewPasswordRef = useRef();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError();
        setSuccess(false);

        if (newPasswordRef.current.value.length < 6) {
            setError("Pasword moet minsens 6 tekens bevatten.");
            return
        }
        if (newPasswordRef.current.value !== confirmNewPasswordRef.current.value) {
            setError("Passwords do not match.");
            return
        }

        setIsLoading(true);
        try {
            const response = await axios.patch("/users/me/reset-password", {
                "current_password": currentPasswordRef.current.value,
                "new_password": newPasswordRef.current.value
            })
            setSuccess(true);
            event.target.reset();
        } catch (error) {
            if ((error.response) && (error.response.status === 422)) {
                setError("The current password does not match our records. Please try again.");
            }
            else {
                setError("Something went wrong. Your password could not be updated.")
            }


        } finally {
            setIsLoading(false);
        }

    }




    return (<div className="bg-white rounded-md p-6 flex flex-col gap-4 w-1/2">
        <h1 className="font-semibold text-black w-3/4 text-2xl">Wijzig je paswoord</h1>
        <form className="h-full flex flex-col gap-4 justify-between"
            onSubmit={handleSubmit}
        >
            {
                error &&
                <AlertDanger message={error} />

            }
            {success &&
                <AlertSuccess message={"Your password was successfully updated"} />
            }
            <div>
                <label className="block text-gray-800 mb-2 font-medium" for="current-password">
                    Huidige paswoord
                </label>
                <input id="current-password"
                    ref={currentPasswordRef}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="password" />
            </div>
            <div>
                <label className="block text-gray-800 mb-2 font-medium" for="new-password">
                    Nieuw paswoord
                </label>
                <input
                    ref={newPasswordRef}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="new-password"
                    placeholder="" type="password" />
            </div>
            <div className='mb-3'>
                <label className="block text-gray-800 mb-2 font-medium" for="retype-password">
                    Herhaal je nieuw paswoord
                </label>
                <input
                    ref={confirmNewPasswordRef}
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="retype-password" placeholder="" type="password" />
            </div>
            <button

                className="btn"
                type="submit"
                disabled={isLoading}
            >
                Update
            </button>
        </form>
    </div>)
}

export default PasswordReset;