import React from 'react';
const Modal = ({ show, close, children }) => {

    if (!show) {
        return null
    };


    return (
        <>
            <div onClick={close} id="overlay" className="fixed top-0 left-0 min-w-full min-h-full bg-black bg-opacity-50 z-10 flex items-center justify-center " >

                <div onClick={(event) => { event.stopPropagation() }}  id='signup-modal'  className="flex flex-col p-16 justify-between bg-white rounded-xl xs:w-[85%] sm:w-1/2 xl:w-1/4">
                    {children}
                </div>
            </div>
        </>)
}

export default Modal;