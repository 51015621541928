import React, { useEffect, useState } from "react";
import formatPostType from "../../utils/formatting/formatPost";
import formatDate from "../../utils/formatting/formatDate";
import FeedItemComment from "./FeedItemComment";

import DOMPurify from "dompurify";
import benedmo from '../../assets/benedmo/benedmo.png'
import getHighlightedText from "../../utils/formatting/highlightText";
import FeedItemPolarity from "./FeedItemPolarity";
import { handleSave } from "./utils/handleSave";
import { handleUnsave } from "./utils/handleUnsave";

const FeedItem = ({ data, savedPage, toggleHandled, textToHighlight, setFeedItems, feedItems }) => {
  const [showFullText, setShowFullText] = useState(false);
  const textExceedsCharacterLimit = data.text && (data.text.length > 330);

  const toggleShowFullText = (event) => {
    event.preventDefault();
    setShowFullText((prev) => !prev);
  };

  return (
    <div className="flex xl:flex-row flex-col p-11 mx-1 rounded-md relative" url={data.url}>
      <div className="xl:w-1/6">
        <a
          className="underline w-full text-neutral-500 text-sm"
          href={data.url}
          target="_blank"
        >
          {formatDate(data.datetime)}
        </a>
      </div>
      <div className="flex flex-col xl:w-2/3 gap-2">
        <div className="flex justify-between">
          <a className="pb-1 pl-2 inline-block" href={data.url} target="_blank">
            {data.type && formatPostType(data.type)["icon"]}
            <span className="text-black text-sm ml-2">
              {data.type && formatPostType(data.type)["type"]}
            </span>
          </a>
          <div className='flex gap-4'>
            {savedPage && data.save &&
              <button className={"mr-2 py-1 px-3 rounded-full " + (data.save.handled ? "bg-neutral-100 hover:bg-neutral-200 " : "hover:bg-neutral-100")}
                onClick={() => {
                  toggleHandled(data.save.id, !data.save.handled)
                }}
                disabled={data.isUpdating}
              >
                {data.save.handled ? <span className='text-slate-600 text-sm'><i className="fa-regular fa-square-check mr-2"></i>Afgehandeld</span> : <span className='text-slate-600 text-sm'><i className="fa-regular fa-square mr-2"></i>Onafgehandeld</span>}
              </button>
            }
            <button className={"mr-2 py-1 px-3 rounded-full " + (data.save ? "bg-neutral-100 hover:bg-neutral-200 " : "hover:bg-neutral-100")}
              onClick={() => {
                if (!data.save) {
                  handleSave(data.type, data.uid, setFeedItems);
                } else {
                  handleUnsave(data.save.id, setFeedItems, feedItems);
                }
              }
              }
              disabled={data.isUpdating}
            >
              <span className='text-slate-600 text-sm'>
                <i className="fa-regular hover:fa-solid fa-bookmark mr-2"></i>
                {data.save ? "Opgeslagen" : "Opslaan"}
              </span>
            </button>
            {
              savedPage && data.summary &&
              <a className={"mr-2 py-3 px-3 rounded-full hover:bg-neutral-100 cursor-pointer relative group"}
                target="_blank"
                href={`https://factcheck.benedmo.eu/semantic-search?q=${encodeURIComponent(data.summary.map((summary) => summary.sentence).join(" ").replace(/ *\(#[^)]*\) */g, ""))}&language=nl&language=en`}
              >
                <span className='absolute top-0 left-0 w-32 translate-y-1/2 -translate-x-1/2 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 z-10' >Klik om gerelateerde factcheck-artikelen te bekijken</span>

                <img src={benedmo} className='h-4'></img>

              </a>}
          </div>

        </div>
        {data.summary && (
          <p
            key={Math.random()}
            className="text-black"

          >

            {data.summary_references &&
              data.summary.map((summary, index) => {
                if (index == 0) {
                  var className = "";
                } else {
                  var className = "sumsentence";
                }
                return (
                  <>
                    <span className={className} key={Math.random()}>
                      {summary.sentence + ((Object.keys(data.summary_references).length > 0) 
                      || (index === data.summary.length - 1) ? "" : " ")}
                    </span>
                    {summary.references && 
                        summary.references
                        .filter((reference) => Object.keys(data.summary_references).includes(reference))
                        .map((reference, index) => <>
                          <a key={Math.random()} target="_blank" className="mr-1" href={data.summary_references[reference]['url']}>
                            <sup key={Math.random()} className="font-semibold text-blue-700">{reference}</sup>
                          </a>
                          {(index === summary.references.length - 1) ? null : <sup>|</sup>}
                        </>
                        )
                    }
                  </>
                )
              }
              )
            }
          </p>
        )}
        {data.text &&
          <span
            className="break-words hover:bg-neutral-100 text-neutral-400 rounded-md p-2"
            onClick={toggleShowFullText}
          > <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  showFullText || !textExceedsCharacterLimit
                    ? textToHighlight ? getHighlightedText(data.text, textToHighlight).concat(" ")
                      : data.text.concat(" ")
                    : textToHighlight ? getHighlightedText(data.text, textToHighlight).substring(0, 320).concat("... ")
                      : data.text.substring(0, 320).concat("... "))
              }}
            />
            {textExceedsCharacterLimit && !showFullText && (
              <button
                className="text-cyan-600 px-1 rounded-lg hover:bg-neutral-200"
              >
                lees meer
              </button>
            )}
          </span>
        }
        {data.comments && data.comments.length > 0 && (
          <div className="relative">
            <div
              className="absolute top-0 left-0 bottom-0 w-lg xs:w-xl flex justify-center items-center z-0 group mb-sm"
              aria-hidden="true"
            >
              <div
                data-testid="main-thread-line"
                className="w-[1px] h-full bg-neutral-300"
              ></div>
            </div>
            {data.comments.map((comment, index) => {
              return (
                <FeedItemComment
                  key={`${comment.url}-${index}`}
                  comment={comment}
                  textToHighlight={textToHighlight}
                />
              );
            })}
          </div>
        )}
      </div>
      <div className="xl:w-1/6 xl:ml-6 flex flex-col justify-between gap-4">
        {data.img && (
          <a className="cursor-pointer" href={data.url} target="_blank">
            <img className="object-cover w-full" src={data.img}></img>
          </a>
        )}

      </div>
      {data.polarity && (<div className="absolute bottom-0 right-4 ">
        <FeedItemPolarity
          datasets={data.polarity.data}
          labels={data.polarity.labels} />
      </div>
      )}
    </div>
  );
};

export default FeedItem;
