import React, { useState, useRef, useEffect } from 'react';
import { MainLayout } from '../../components/layouts/MainLayout';
import BreadCrumbBar from '../../components/BreadCrumbBar';
import ContentHeader from '../../components/ContentHeader';
import SourcesTable from '../../components/sources/SourcesTable';
import axios from '../../services/axios';
import AlertDanger from '../../components/ui/alerts/AlertDanger';
import { useNavigate } from 'react-router';
import AlertSuccess from '../../components/ui/alerts/AlertSuccess';
import Spinner from '../../components/ui/Spinner';


const EditTopicPage = () => {

    const navigate = useNavigate();
    const searchTermInputRef = useRef();
    const [success, setSuccess] = useState(false);
    const [topic, setTopic] = useState();
    const formRef = useRef();
    const [searchTermInputValues, setSearchTermInputValues] = useState([]);
    const [linkedSourceIds, setLinkedSourceIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [suggestSearchTermsLoading, setSuggestSearchTermsLoading] = useState(false);
    const [searchTermsSuggested, setSearchTermsSuggested] = useState(false);
    const [searchTermsSuggestedError, setSearchTermsSuggestedError] = useState();
    const [topicSources, setTopicSources] = useState()
    const [allSources, setAllSources] = useState();
    const [searchTermError, setSearchTermError] = useState();
    const [allSourcesLoading, setAllSourcesLoading] = useState(false);
    const [showAddSourcesPanel, setShowAddSourcesPanel] = useState(false);


    const removeAllKeywords = (event) => {
        event.preventDefault();
        setSearchTermInputValues([])
    }

    const handleInputChange = (index, value) => {
        const newValues = [...searchTermInputValues];
        newValues[index] = value;
        setSearchTermInputValues(newValues);
    };

    const handleKeyPress = (event) => {
        const newKeyword = event.target.value;
        if ((newKeyword.length > 0) && (event.keyCode === 13)) {
            event.preventDefault();
            setSearchTermInputValues([...searchTermInputValues, newKeyword]);
            event.target.value = "";
        } else {
            if ((event.keyCode === 8) && (newKeyword.length < 1)) {
                if (searchTermInputValues.length > 0) {
                    const keywordsCopy = [...searchTermInputValues];
                    keywordsCopy.pop();
                    setSearchTermInputValues(keywordsCopy);
                }
            }
        }
    }

    useEffect(async () => {

        await fetchTopicWithSources();
    }, []);

    useEffect(() => {
        if (topic) { document.title = `${process.env.APP_NAME} • Onderwerp "${topic.title }" aanpassen`; }
    }, [topic])

    useEffect(async () => {
        if (topicSources) { await fetchAllSources(); }
    }, [topicSources]);

    useEffect(() => {
        for (let input of document.querySelectorAll(".topic-existing-keyword")) {
            resizePillInput(input);
        }
    }, [document.querySelectorAll(".topic-existing-keyword")]
    )



    const fetchAllSources = async () => {
        setAllSourcesLoading(true);
        const existingSourceUrls = topicSources.map((source) => source.url);
        try {
            const response = await axios.get(`/sources`);
            const unLinkedSources = response.data.filter(source =>
                !existingSourceUrls.includes(source.url)
            )
            setAllSources(unLinkedSources);

        } catch (error) {
            console.error(error)
        }
        finally {
            setAllSourcesLoading(false);
        }
    };




    const fetchTopicWithSources = async () => {
        setIsLoading(true);
        try {

            const topicId = /\/topics\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/
                .exec(window.location.pathname)[1];
            const topicResponse = await axios.get(`/topics/${topicId}`);
            console.log("topic response", topicResponse)
            setTopic(topicResponse.data);
            const sourcesResponse = await axios.get(`/topics/${topicId}/linked-sources`);
            console.log("sources rsponse", sourcesResponse)
            setTopicSources(sourcesResponse.data);
        } catch (error) {
            console.error(error);

        } finally {
            setIsLoading(false);
        }

    }






    const handleSave = async (event) => {
        event.preventDefault();
        try {
            setIsLoading(true);
            await axios.patch(`/topics/${topic.uuid}`,
                {
                    'search_terms': searchTermInputValues,
                    "source_uuids": linkedSourceIds
                }
            );
            formRef.current.reset();
            setSearchTermInputValues([]);
            setLinkedSourceIds([]);
            setSuccess(true);
            window.scrollTo({ top: 0 });
            setTimeout(() => {
                navigate(`/topics/${topic.uuid}`);
            }, 1000);
        } catch (error) {
            console.error(error);

        } finally {
            setIsLoading(false);
        }


    }

    const suggestSearchTerms = async (event) => {
        event.preventDefault();

        setSearchTermsSuggestedError("");
        if (searchTermsSuggested) {
            setSearchTermsSuggestedError("Only 20 new suggested search terms may be requested per new topic.")
        }
        else {
            setSuggestSearchTermsLoading(true);
            setSearchTermsSuggested(true);
            const searchParams = new URLSearchParams();
            searchParams.append("title", topic.title);
            searchParams.append("language", topic.language)
            if (topic.description) {
                searchParams.append("description", topic.description);
            }
            try {
                const response = await axios.get("/topics/hints?" + searchParams);
                const searchTermData = response.data.search_terms;
                const newSearchTerms = searchTermData.map((searchTerm) => searchTerm.term);
                setSearchTermInputValues((previousValues) => {
                    return [...previousValues, ...newSearchTerms];
                });
            } catch (error) {
                console.error(error);

            } finally {

                setSuggestSearchTermsLoading(false);
            }
        }
    };

    const resizePillInput = (input) => {
        input.style.width = `${input.scrollWidth}px`;
    };

    useEffect(() => {
        searchTermInputValues.forEach((value, index) => {
            const input = document.getElementById(`keyword-input-${index}`);
            if (input) {
                resizePillInput(input);
            }
        });
    }, [searchTermInputValues]);

    const removeInputField = (index) => {
        const newValues = [...searchTermInputValues];
        newValues.splice(index, 1);
        setSearchTermInputValues(newValues);
    };


    if (!topic) {
        return null;
    }

    return (
        <>
            {topic &&
                <ContentHeader
                    heading={`Onderwerp "${topic.title}" aanpassen`}
                    subtitle={`Je kan dit onderwerp aanpassen door nieuwe bronnen en zoektermen toe te voegen. 
                    Je kan geen bestaande bronnen of zoektermen verwijderen. Contacteer de admin (${process.env.ADMIN_EMAIL}) 
                    om aanpassingen voor te stellen aan bestaande bronnen en zoektermen.`}
                />
            }

            <form className="flex flex-col gap-6 items-start w-full"
                ref={formRef}
                onSubmit={handleSave}
            >

                <div className="flex flex-col gap-2 w-1/2">
                    <label htmlFor="topic-keywords" className="text-xl font-semibold text-gray-900">Zoektermen</label>

                    <div id="topic-keywords"
                        type="text"
                        onClick={(event) => {
                            if (document.getElementById("topic-keywords") !== event.target) return;
                            searchTermInputRef.current.focus();
                        }}
                        className="rounded-lg p-5 border border-neutral-200 text-gray-500 bg-white flex flex-wrap" >
                        {
                            topic
                            && topic.search_terms &&
                            topic.search_terms.map((value, index) => {
                                return (<span
                                    key={"span-existing" + index}
                                    className="pill-input">
                                    <input
                                        id={`keyword-${index}`}
                                        type="text"
                                        className='topic-existing-keyword bg-transparent'
                                        size="1"
                                        key={"keyword-" + index}
                                        value={value}
                                        disabled
                                    />
                                </span>)
                            })
                        }
                        {
                            searchTermInputValues.map((value, index) => (
                                <span
                                    key={"span-" + index}
                                    className="pill-input">
                                    <input
                                        id={`keyword-input-${index}`}
                                        type="text"
                                        className='topic-keyword-input'
                                        size="1"
                                        key={"keywordInput-" + index}
                                        onInput={(e) => resizePillInput(e.target)}
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                        value={value}
                                    />
                                    <i
                                        className="delete flex items-center"
                                        key={"i-" + index}
                                        onClick={() => removeInputField(index)}
                                    >&times;</i>
                                </span>
                            ))
                        }
                        <input
                            type="text"
                            className='bg-transparent focus:outline-none focus:ring-0'
                            ref={searchTermInputRef}
                            onKeyDown={handleKeyPress}
                        />
                        <div />
                    </div>
                    {
                        searchTermsSuggestedError &&
                        <div className='w-7/12'>
                            <AlertDanger message={searchTermsSuggestedError} />
                        </div>
                    }

                    <div className='flex gap-4'>
                        <button className="btn"
                            onClick={suggestSearchTerms}
                            disabled={isLoading || suggestSearchTermsLoading}
                        >
                            {
                                suggestSearchTermsLoading &&
                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg>
                            }
                            AI-suggesties
                        </button>
                        <button className="btn--warning"
                            onClick={removeAllKeywords}
                            disabled={isLoading || suggestSearchTermsLoading}
                        >
                            Verwijder alle nieuwe zoektermen
                        </button>
                    </div>
                </div>


                <h1 className="text-gray-900 text-xl font-semibold">Gelinkte bronnen</h1>
                <div id="source-list-container" className="gap-6 flex flex-col w-full">

                    {
                        topicSources &&

                        <SourcesTable selectable={false} tableId={"topic-source-table"} checkedSourceIds={linkedSourceIds} setCheckedSourceIds={setLinkedSourceIds} sources={topicSources} />

                    }
                </div>

                <button
                    type="button"
                    onClick={() => {
                        setShowAddSourcesPanel((prev) => !prev)
                        if (!showAddSourcesPanel) { setLinkedSourceIds([]) }
                    }}
                    className="flex items-center pr-6 text-base text-gray-500 hover:text-gray-800 font-semibold transition duration-75 group dark:text-white "
                >
                    Wijs bronnen toe aan dit onderwerp (optioneel)
                    {showAddSourcesPanel ? <i className='ml-2 fa-solid fa-minus'></i> : <i className='ml-2 fa-solid fa-plus'></i>}
                </button>
                {
                    showAddSourcesPanel &&
                    <>
                        <span className="text-gray-500 ml-6">
                            Als je zeker weet dat een bron voornamelijk posts bevat over het onderwerp in kwestie,
                            kan je die hier linken.
                        </span>
                        <div id="source-list-container" className="gap-6 flex flex-col w-full">
                            {
                                allSources && (
                                    <SourcesTable
                                        selectable={true}
                                        tableId={"source-table"}
                                        checkedSourceIds={linkedSourceIds}
                                        setCheckedSourceIds={setLinkedSourceIds}
                                        sources={allSources}
                                        noneMesssage={"Geen bronnen beschikbaar."}
                                    />
                                )
                            }
                        </div>
                    </>
                }
                <button
                    className="btn w-32"
                    type="submit"
                    disabled={isLoading || suggestSearchTermsLoading || (!searchTermInputValues.length && !linkedSourceIds.length)}
                >Update
                </button>
            </form>
        </>
    )
}


export default EditTopicPage;
