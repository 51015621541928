import React from 'react';
import BreadCrumbBar from './BreadCrumbBar';

const ContentHeader = ({ currentPage, heading, subtitle, children }) => {
    return (<div id="content-header" className="flex border-b border-b-neutral-200 pb-4 justify-between">
        <div className="flex flex-col gap-6">
            {currentPage && <BreadCrumbBar currentPage={currentPage} />}
            <h1 className="text-gray-900 text-2xl font-semibold">{heading}</h1>
            {subtitle && <p className="text-gray-500">{subtitle}</p>}
        </div>
        <div className="flex flex-col items-start gap-1">{children}</div>
    </div>)
}

export default ContentHeader;