import React, { useState, useRef } from 'react';
import axios from '../../services/axios';
import AlertDanger from '../../components/ui/alerts/AlertDanger';

const TextInputSourceForm = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const sourceTitle = useRef();
    const url = useRef();
    async function handleSubmit(e) {
        e.preventDefault();
        setError("");
        if (!sourceTitle.current.value || !url.current.value) {
            console.log("fields empty!")
            setError("De bron moet een titel en een geldige URL hebben.")

        } else {
            try {
                const response = await axios.post(`/sources`, {
                    'title': sourceTitle.current.value,
                    "url": url.current.value
                });
                // alert(response.data);
                window.location.replace('/sources');
            } catch (error) {
                console.error(error.response.data)
                if (error.response.data.detail === "DUPLICATE_RESOURCE") {
                    setError("Er bestaat al een bron met deze URL.");
                } else if (error.response.status === 422) {
                    setError("De bron moet een titel en een geldige url hebben.")
                } else {
                    setError("Er is iets fout gegaan. Vernieuw de pagina en probeer het opnieuw.");
                }

            }
            finally {
                setIsLoading(false);
            }
            setIsLoading(false);
        }
    }

    return (


        <form
            className='flex flex-col gap-4'
            onSubmit={handleSubmit}
        >
            {
                error && <AlertDanger message={error} />
            }
            <div>
                <label
                    className="block text-gray-800 text-md mb-2"
                    htmlFor="title"
                >
                    Titel
                </label>
                <input
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="title" type="text" placeholder="
                                    Het Belang van Limburg" ref={sourceTitle} />

            </div>
            <div>
                <label className="block text-gray-800 text-md mb-2"
                    htmlFor="url">
                    URL
                </label>
                <input
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="url" placeholder="
                                    https://www.facebook.com/hetbelangvanlimburg" type="text" ref={url} />

            </div>
            <button
                className="bg-blue-700 w-full hover:bg-blue-900 transition-colors duration-300 ease-in-out text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                disabled={isLoading}>
                Creëer
            </button>

        </form >

    )
}

export default TextInputSourceForm;