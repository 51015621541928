import React from 'react';
const AlertSuccess = ({ heading, message }) => {
    return (<div
        className="bg-green-100  border-emerald-400 py-4 px-6 flex flex-col gap-4 rounded-md border-l-8">
        {heading && <div className="flex gap-6 text-teal-700 items-center">
            <i
                className="fa-solid fa-check text-white bg-emerald-400 rounded-md w-6 h-6 p-1 text-center"></i>
            <h1 className="text-xl font-semibold text-teal-700">{heading}</h1>
        </div>}
        <p className="text-emerald-400 text-sm font-medium">{message}</p>
    </div>)
}

export default AlertSuccess;