import React, { useEffect, useState } from 'react';
import FeedItemsContainer from '../../components/feed/FeedItemsContainer';
import FeedItemSkeleton from '../../components/feed/FeedItemSkeleton';
import axios from '../../services/axios';
import FeedItem from '../../components/feed/FeedItem';
import ContentHeader from '../../components/ContentHeader';
import SaveHandledSelect from '../../components/SaveHandledSelect';


const SavedPostsPage = () => {

    const [filter, setFilter] = useState({
        'label': 'Alles',
        'value': 'all'
    });
    const [savedFeedItems, setSavedFeedItems] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [exportLoading, setExportLoading] = useState(false);
    const handleSave = async (platform, postUid) => {
        setSavedFeedItems(prev => prev.map(item => {
            if ((item.type === platform) && (item.uid === postUid)) {
                return { ...item, isUpdating: true }; // remove save record
            } else {
                // No change
                return item
            }
        }));
        try {
            const response = await axios.post("/saves",
                {
                    "platform": platform,
                    "post_uid": postUid
                },
                {
                    headers: {
                        "Accept": 'application/json',
                        "Content-Type": "application/json"
                    }
                }

            );
            setSavedFeedItems(prev => prev.map(item => {
                if ((item.uid === postUid) && (item.type === platform)) {
                    return { ...item, save: { id: response.data.id, handled: response.data.handled } };
                } else {
                    // No change
                    return item
                }
            }));
        } catch (error) {
            console.error("Error saving post:", error)
        } finally {
            setSavedFeedItems(prev => prev.map(item => {
                if ((item.type === platform) && (item.uid === postUid)) {
                    return { ...item, isUpdating: false }; // remove save record
                } else {
                    // No change
                    return item
                }
            }));
        }
    }

    const handleUnsave = async (saveId) => {
        setSavedFeedItems(prev => prev.map(item => {
            if ((item.save) && (item.save.id === saveId)) {
                return { ...item, isUpdating: true }; // set updating
            } else {
                // No change
                return item
            }
        }));
        const item = savedFeedItems.find((item) => {
            if ((item.save) && (item.save.id === saveId)) {
                return item;
            }
        })
        const uid = item.uid;
        const platform = item.platform;
        try {
            const response = await axios.delete(`/saves/${saveId}`,
                {
                    headers: {
                        "Accept": 'application/json',
                    }
                }
            );
            setSavedFeedItems(prev => prev.map(item => {
                if ((item.save) && (item.save.id === saveId)) {
                    return { ...item, save: null }; // remove save record
                } else {
                    // No change
                    return item
                }
            }));
        } catch (error) {
            console.error("Error unsaving posts:", error)
        } finally {
            setSavedFeedItems(prev => prev.map(item => {
                if ((item.platform === platform) && (item.uid === uid)) {
                    return { ...item, isUpdating: false }; // set not updating
                } else {
                    // No change
                    return item
                }
            }));
        }
    }

    const toggleHandled = async (saveId, newHandledValue) => {
        try {
            const response = await axios.patch(`/saves/${saveId}`,
                { "handled": newHandledValue },
                {
                    headers: {
                        'Accept': "application/json",
                        "Content-Type": "application/json"
                    }
                }
            )
            const updatedSave = response.data;
            setSavedFeedItems(savedFeedItems.map(item => {
                if (item.save.id === updatedSave.id) {
                    item['save'] = { "id": updatedSave.id, 'handled': updatedSave.handled }
                }
                return item;
            }
            ));
        } catch (error) {
            console.error("Error patching save", error)
        }
    }



    const fetchSavedPosts = async () => {
        setIsLoading(true);
        try {
            let url = "/users/me/feed";
            if (filter.value !== 'all') {
                url += `?handled=${filter.value === 'handled'}`;
            }
            const response = await axios.get(url);
            const savedFeedItemsData = response.data;
            setSavedFeedItems(savedFeedItemsData);
        } catch (error) {
            console.error("Error fetching saved posts", error)
        } finally {
            setIsLoading(false);
        }
    }

    const fetchSavesExcel = async () => {
        setExportLoading(true);
        try {
            const url = "/saves/xlsx";
            const response = await axios.get(url, {
                method: 'GET',
                responseType: 'blob',
            });
            const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', `saves.xlsx`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Error fetching excel sheet saves", error)
        } finally {
            setExportLoading(false);
        }
    }

    useEffect(() => {
        document.title = `${process.env.APP_NAME} • Opgeslagen berichten`;
        fetchSavedPosts();
    }, [filter])

    return (
        <>
            <ContentHeader heading={"Opgeslagen berichten"} />
            <div className='xl:w-3/4 justify-self-center self-center'>
                <div className='flex flex-col gap-6'>
                    <div className='flex justify-between'>
                        <SaveHandledSelect selectedFilter={filter} setSelectedFilter={setFilter} />
                        <button
                        disabled={exportLoading}
                            onClick={() => {
                                fetchSavesExcel();
                            }}
                            className="btn h-full cursor-pointer flex gap-4"
                            
                        >
                            <i className="fa-solid fa-download"></i>
                            Exporteren</button>
                    </div>
                    {
                        isLoading &&

                        <FeedItemsContainer >
                            <FeedItemSkeleton />
                            <FeedItemSkeleton />
                            <FeedItemSkeleton />
                        </FeedItemsContainer>

                    }{
                        !isLoading && savedFeedItems && savedFeedItems.length > 0 &&

                        <FeedItemsContainer >
                            {
                                savedFeedItems.map((save) => {
                                    return (
                                        <FeedItem
                                            data={save}
                                            savedPage={true}
                                            handleSave={handleSave}
                                            handleUnsave={handleUnsave}
                                            toggleHandled={toggleHandled}
                                            key={`feeditem-${save.uid}`}
                                        />)
                                })
                            }
                        </FeedItemsContainer>

                    }
                    {
                        !isLoading && savedFeedItems && savedFeedItems.length < 1 &&
                        <h1 className='text-slate-600 text-xl'>Er zijn geen beschikbare opgeslagen berichten.</h1>
                    }
                </div >
            </div>
        </>)
}

export default SavedPostsPage;