const languages = [
    {"code": "ar", "name": "Arabisch"},
    {"code": "bg", "name": "Bulgaars"},
    {"code": "cs", "name": "Tsjechisch"},
    {"code": "da", "name": "Deens"},
    {"code": "de", "name": "Duits"},
    {"code": "el", "name": "Grieks"},
    {"code": "en", "name": "Engels"},
    {"code": "es", "name": "Spaans"},
    {"code": "et", "name": "Estisch"},
    {"code": "fi", "name": "Fins"},
    {"code": "fr", "name": "Frans"},
    {"code": "ga", "name": "Iers"},
    {"code": "hr", "name": "Kroatisch"},
    {"code": "hu", "name": "Hongaars"},
    {"code": "it", "name": "Italiaans"},
    {"code": "lt", "name": "Litouws"},
    {"code": "lv", "name": "Lets"},
    {"code": "mk", "name": "Macedonisch"},
    {"code": "mt", "name": "Maltees"},
    {"code": "nl", "name": "Nederlands"},
    {"code": "pl", "name": "Pools"},
    {"code": "pt", "name": "Portugees"},
    {"code": "ro", "name": "Roemeens"},
    {"code": "ru", "name": "Russisch"},
    {"code": "sk", "name": "Slowaaks"},
    {"code": "sl", "name": "Sloveens"},
    {"code": "sq", "name": "Albanees"},
    {"code": "sv", "name": "Zweeds"},
    {"code": "tr", "name": "Turks"},
    {"code": "uk", "name": "Oekraïens"}
]

export default languages;