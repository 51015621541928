const formatSourceType = (sourceType) => {
   switch (sourceType) {
      case "facebook.page":
         return "Facebook page";
      case "facebook.group":
         return "Facebook group";
      case "youtube.channel":
         return "Youtube channel";
      case "twitter.profile":
         return "Twitter profile";
      case "tiktok.profile":
         return "Tiktok profile";
      case "wordpress.site":
         return "Wordpress Site";
      default:
         return "Source";
   }
}

export default formatSourceType;

