import React, { useEffect, useState } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import axios from '../services/axios';

const RequireAuth = () => {
    const { currentUser, getUser, setCurrentUser } = useAuth();
    const location = useLocation();


    useEffect(() => {
        const logoutOnCookieExpire = async () => {

            try {
                await getUser();

            } catch (error) {
                console.log("cookie expired")
            }
        };

        const refresh = async () => {

            try {
                const response = await axios.post("/auth/cookie/jwt/refresh")


            } catch (error) {
                console.error("APP ERROR", error)
            }
        }
        logoutOnCookieExpire();

        const logoutIntervalId = setInterval(logoutOnCookieExpire, 340000);
        const refreshCookieIntervalId = setInterval(refresh, 3500000)

        // Cleanup function 
        return () => {
            clearInterval(logoutIntervalId);
            clearInterval(refreshCookieIntervalId)
        }
    }, []);




    return (
        // navigate unauthorized users to login page, replace login in navigation history with location they came from
        currentUser ? <Outlet /> : <Navigate
            to='/login'
            state={{ from: location }}
            replace
        />
    );
}

export default RequireAuth;