import React, { useRef, useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import AlertDanger from "../../components/ui/alerts/AlertDanger";

import AlertSuccess from "../../components/ui/alerts/AlertSuccess";
import Modal from "../../components/ui/Modal";

const SignUpModal = ({ show, close, setSuccess }) => {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const { signup } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dpaAccepted, setDpaAccepted] = useState(false); // Track DPA acceptance
  useEffect(() => {
    setErrorMessage("");
  }, [show]);

  if (!show) {
    return null;
  }

  async function handleSubmit(e) {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;
    e.preventDefault();
    setErrorMessage("");
    if (!dpaAccepted) {
      setErrorMessage("Je moet akkoord gaan met de verwerkersovereenkomst.");
      return;
    }

    if (passwordRef.current.value.length < 6) {
      setErrorMessage("Paswoord moet minstens 6 tekens lang zijn.");
      return;
    }
    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      setErrorMessage("Paswoorden verschillen.");
      return;
    }

    try {
      setIsLoading(true);
      console.log("signing up");
      await signup(email, password, firstName, lastName);
      setSuccess(
        `We stuurden een e-mail naar ${email}. Volg de instructies om je account te activeren.`
      );
      close();
    } catch (error) {
      setIsLoading(false);
      if (error === "REGISTER_USER_ALREADY_EXISTS") {
        setErrorMessage(
          `Je kan je niet registreren met dit e-mailadres. Contacteer ${process.env.ADMIN_EMAIL} voor meer informatie.`
        );
        return;
      }
      if (error[0] && error[0].type === "string_pattern_mismatch") {
        setErrorMessage(
          `Je kan je niet registreren met dit e-mailadres. Contacteer ${process.env.ADMIN_EMAIL}  voor meer informatie.`
        );
        return;
      }
      setErrorMessage("Registratie mislukt.");
    }
  }

  return (
    <Modal show={show} close={close}>
      <div className="basis-1/6">
        <div className="text-gray-800 text-3xl font-bold mb-6">Registratie</div>
      </div>
      <div className="basis-5/6 flex flex-col gap-4">
        {errorMessage && <AlertDanger message={errorMessage} />}

        <form
          className="h-full flex flex-col justify-between"
          onSubmit={handleSubmit}
        >
          <div className="mb-4">
            <label
              className="block text-gray-500 text-sm mb-2"
              htmlFor="signup-first-name"
            >
              Voornaam
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="signup-first-name"
              type="text"
              ref={firstNameRef}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-500 text-sm mb-2"
              htmlFor="signup-last-name"
            >
              Achternaam
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="signup-last-name"
              type="text"
              ref={lastNameRef}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-500 text-sm mb-2" htmlFor="email">
              E-mailadres
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="signup-email"
              type="email"
              ref={emailRef}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-500 text-sm mb-2"
              htmlFor="password"
            >
              Paswoord
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="signup-password"
              type="password"
              ref={passwordRef}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-500 text-sm mb-2"
              htmlFor="confirm-password"
            >
              Herhaal paswoord
            </label>
            <input
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="confirm-password"
              type="password"
              ref={confirmPasswordRef}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-500 text-sm mb-2">
              <input
                type="checkbox"
                id="confirm-dpa"
                style={{ marginLeft: "5px" }}
                onChange={(e) => setDpaAccepted(e.target.checked)}
                checked={dpaAccepted}
                required
              />{" "}
              Ik heb de{" "}
              <a href="/dpa" target="_blank" className="underline">
                verwerkersovereenkomst
              </a>{" "}
              gelezen en ga hiermee akkoord.
            </label>
          </div>

          <div>
            <button
              className="bg-blue-700 w-full hover:bg-blue-900 transition-colors duration-300 ease-in-out text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              disabled={isLoading}
              type="submit"
            >
              Maak account aan
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SignUpModal;
