import React, { useState } from 'react';
import axios from '../../services/axios';

const SharedTerm = ({ isSearchTerm, isClickable, term, topicId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [termIsSearchTerm, setIsSearchTerm] = useState(isSearchTerm);



    const handleAddSeachTerm = async () => {
        setIsLoading(true);
        const topicUpdateBody = { 'source_uuids': [], 'search_terms': [term] }

        try {
            const response = await axios.patch(`/topics/${topicId}`, topicUpdateBody);
            setIsSearchTerm(true);
        } catch (error) {
            console.error("Error", error)

        } finally {
            setIsLoading(false);
        }

    };

    return (
        <div className={"relative flex justify-center"+ (!termIsSearchTerm && isClickable ? " group" : "") }>
            <span className='absolute top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 z-10 w-28' >{"Klik om \"" + term + "\" toe te voegen aan de lijst met zoektermen van dit onderwerp"}</span>
            <button
                disabled={isSearchTerm || !isClickable || isLoading}
                onClick={handleAddSeachTerm}
                className={
                    "inline-flex items-center rounded-full text-sm px-2 py-2 font-medium  ring-1 ring-inset ring-gray-500/10 " +
                    (termIsSearchTerm ? "bg-teal-100 text-teal-600 " : "bg-gray-50 text-gray-600 ") +
                    (isClickable ? (termIsSearchTerm ? "cursor-not-allowed" : "cursor-pointer") : "cursor-default")
                }
            >
                {term}
            </button>
        </div>)
}

export default SharedTerm;