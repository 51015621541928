import { useEffect, useState } from "react"
import React from "react"

import SharedTermsWidget from '../../components/sharedTerms/SharedTermsWidget';
import ActiveGroupsWidget from '../../components/activeSources/ActiveSourcesWidget';
import axios from "../../services/axios";
import TextCard from '../../components/ui/TextCard';
import TopicsChart from '../../components/topics/chart/TopicsChart';
import PolarityChart from "../PolarityChart";
import Markdown from "markdown-to-jsx";

const TopicSummary = ({ topic, selectedTimeFrame, setSelectedTimeFrame }) => {

    const [chartDataLoading, setChartDataLoading] = useState(false);
    const [termsLoading, setTermsLoading] = useState(false);
    const [sourcesLoading, setSourcesLoading] = useState(false);
    const [chartData, setChartData] = useState();
    const [activeSources, setActiveSources] = useState();
    const [checkedSourceIds, setCheckedSourceIds] = useState([]);
    const [terms, setTerms] = useState([]);
    const [polarities, setPolarities] = useState([]);
    const [polaritiesLoading, setPolaritiesLoading] = useState(false);
    const [error, setError] = useState();

    const [summary, setSummary] = useState();
    const [summaryLoading, setSummaryLoading] = useState(false);
    const [showPolaritiesWidget, setShowPolaritiesWidget] = useState(false);

    useEffect(() => {
        if (topic) {
            fetchChartData();
            fetchSourcesData();
            fetchTermsData();
            fetchSummary();
            fetchPolaritiesData();
        }
    }, [selectedTimeFrame, topic]);


    const fetchTermsData = async () => {
        setTermsLoading(true);
        setTerms();
        try {
            const termsResponse = await axios.get(`topics/${topic.uuid}/terms?timeframe=${selectedTimeFrame}`);
            setTerms(termsResponse.data);
        } catch (error) {
            console.error(error)

        } finally { setTermsLoading(false) }

    }

    const fetchSourcesData = async () => {
        setSourcesLoading(true);
        setActiveSources();
        try {
            const activeSourcesResponse = await axios.get(`topics/${topic.uuid}/sources?sort=most_active&timeframe=${selectedTimeFrame}`)
            setActiveSources(activeSourcesResponse.data);
        } catch (error) {
            console.error(error)

        } finally {
            setSourcesLoading(false)
        }
    }

    const fetchPolaritiesData = async () => {
        setPolarities(false)
        setPolaritiesLoading(true);
        try {
            const polaritiesResponse = await axios.get(`topics/${topic.uuid}/distribution?timeframe=${selectedTimeFrame}`)
            setPolarities(polaritiesResponse.data);

        } catch (error) {
            console.error(error)

        } finally {
            setPolaritiesLoading(true);
        }
    }

    const fetchChartData = async () => {
        setChartDataLoading(true);
        setError("");
        setChartData();
        try {
            const chartResponse = await axios.get(`/topics/${topic.uuid}/chart?timeframe=${selectedTimeFrame}`);
            setChartData(chartResponse.data);
        } catch (error) {
            console.error(error);
            setError("Could not load topic");
        } finally {
            setChartDataLoading(false);
        }
    };

    const fetchSummary = async () => {
        setSummaryLoading(true);
        setError("");
        setSummary();
        try {
            const summaryResponse = await axios.get(`topics/${topic.uuid}/summary?timeframe=${selectedTimeFrame}`);
            setSummary(summaryResponse.data)

        } catch (error) {
            console.error(error);
            setError("Could not load topic");
        } finally {
            setSummaryLoading(false);
        }

    }

    if (topic) {
        return (
            <div className='flex flex-col gap-8 border-t-neutral-200'>

                <div className="pb-6 flex flex-col gap-6 h-3/4 ">
                    <div className='flex flex-col gap-1'>
                        <h2 className="text-gray-900 pl-11 text-3xl font-semibold" >{topic.title}</h2>
                        <a className=" pl-11 text-base text-blue-700 underline"
                            href={`/topics/${topic.uuid}/edit`}

                        >Edit</a>
                    </div>

                    <span className="text-gray-900 pl-11 text-sm">{topic.description}</span>

                    <div className="grid grid-cols-3 gap-6">


                        <div className="p-11 rounded bg-white xl:col-span-2 col-span-3 flex flex-col gap-4">
                            <div className="flex justify-between">
                                <h1 className="font-bold text-xl text-black">Trends</h1>
                                <select
                                    id="topic-trends-time-frame"
                                    value={selectedTimeFrame}
                                    onChange={(e) => setSelectedTimeFrame(e.target.value)}
                                    className="bg-violet-50 border w-28 py-1 px-3 text-sm border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                >
                                    <option value="month">Maand</option>
                                    <option value="week">Week</option>
                                </select>
                            </div>
                            <TopicsChart
                                chartId={`seartchterms-all-topics`}
                                data={chartData}
                                timescale={selectedTimeFrame}

                            />
                        </div>

                        <div className="col-span-3 2xl:col-span-1 flex flex-col md:flex-row 2xl:flex-col gap-6">
                            {terms && (
                                <SharedTermsWidget
                                    sharedTermData={terms}
                                    searchTerms={topic.search_terms}
                                    timeFrame={selectedTimeFrame}
                                    termsClickable={true}
                                    topicId={topic.uuid}
                                />
                            )}
                            {activeSources && (
                                <ActiveGroupsWidget
                                    mostActiveSourcesData={activeSources}
                                    timeFrame={selectedTimeFrame}
                                />
                            )}
                        </div>
                    </div>
                    {
                        polarities && <>
                            <button
                                type="button"
                                onClick={() => {
                                    setShowPolaritiesWidget((prev) => !prev)
                                }}
                                className="pl-11 flex items-center pr-6 text-base text-gray-500 hover:text-gray-800 font-semibold transition duration-75 group dark:text-white "
                            >
                                {showPolaritiesWidget ? "Verberg het polariteitsdiagram" : "Toon het polariteitsdiagram"}
                                {showPolaritiesWidget ? <i className='ml-2 fa-solid fa-minus'></i> : <i className='ml-2 fa-solid fa-plus'></i>}
                            </button>
                            {
                                showPolaritiesWidget &&
                                <div className='flex'>
                                    {
                                        polarities &&
                                        <div className="h-[560px] w-full bg-white p-6 rounded ">
                                            <PolarityChart dataset={polarities.data} labels={polarities.labels} />
                                        </div>
                                    }
                                </div>
                            }
                        </>
                    }

                    <div className='flex flex-col xl:w-3/4 gap-6 self-center'>
                        {
                            summary && summary.samenvatting &&
                            <div className='p-6 rounded bg-white flex flex-col'>
                                <div className='flex gap-4 items-center mb-4'>
                                    <i
                                        className="fa-light fa-file rounded bg-teal-50 text-2xl text-teal-500 p-4 w-14 h-14"></i>
                                    <h1 className=' text-black text-lg font-semibold'>Samenvatting</h1>
                                </div>
                                <Markdown className='text-gray-500 mb-4 list-disc'>{summary.samenvatting}</Markdown>
                              


                                {
                                    summary.concepten &&
                                    <div className='flex gap-4 flex-wrap'>

                                        {
                                            summary.concepten.map((concept, index) => {
                                                return <span key={concept + "-" + index} className="inline-flex items-center rounded-full text-sm bg-gray-50 px-2 py-2 font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{concept}</span>
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>

                </div>
            </div >

        )
    }
    return null;


}

export default TopicSummary;