import React, { useEffect, useState } from 'react';
import { polarityColors } from '../utils/colors';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);


const options = {
    responsive: true,

    maintainAspectRatio: false,
    scales: {
        y: {
            // title: {display: true, text: "Percentage berichten"},
            beginAtZero: true,
            ticks: {
                callback: (value, index, values) => {
                    return `${value}`
                }
            }
        }
    },
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    return context.raw.toFixed(2);
                }
            }
        }
    },

};


export default function PolarityChart({ dataset, labels }) {

    const [data, setData] = useState();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (dataset) {
            const data = {
                "labels": labels,
                "datasets": [{
                    "label": "Distributie", // "Polariteitsscore (-1 = zeer negatief, 1 = zeer positief)",
                    "data": dataset,
                    "backgroundColor": polarityColors
                }]
            }
            setData(data);
        }

        const timer = setTimeout(() => setIsVisible(true), 50);
        return () => clearTimeout(timer);
    }, [dataset])



    if (data) {
        return <Bar
            height={"100%"}
            width={"100%"}
            id={`${data[0]}`}
            options={options}
            data={data} 
            className={`transition-opacity duration-500 ${isVisible ? "opacity-100" : "opacity-0"}`}
            />;
    }
    return null;
}
