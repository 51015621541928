import React from 'react';
import useAuth from '../hooks/useAuth';

export const HeaderSearchBar = () => {
    const { currentUser } = useAuth();
    return (
        <div id="searchbar-container" className='pl-11 bg-white flex gap-32 border-b min-h-[64px] border-b-gray-200'>
            <div className='py-5 self-center'>
                {/* <h1 className='text-slate-900 text-xl font-sbold'>Hello, {currentUser.firstName}</h1> */}
            </div>
        </div>
    )
}