import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from '../services/axios';
import formatPostType from '../utils/formatting/formatPost';

const formatOptionLabel = ({ value, label }) => (
    <div>
      <div className='text-slate-600'>{label}</div>
    </div>
  );

const options  = [
    {'label': 'Alles', 'value': "all"}, 
    {'label': 'Afgehandeld', 'value': "handled"}, 
    {'label': 'Onafgehandeld', 'value': "unhandled"}, 

]


const SaveHandledSelect = ({selectedFilter, setSelectedFilter }) => {

    const handleChange = selectedOption => {
        setSelectedFilter(selectedOption);
    };

    return (
    <Select
        styles={{
            container: (base) => ({
                ...base, 
                width: "12rem"
            }),
            option: (baseStyles, state) => ({
                ...baseStyles,
                color: "black"
            }), 
            multiValueRemove: (base) => ({
                ...base,
                color:"gray"
              }),
          }}
          
        options={options}
        onChange={handleChange}
        formatOptionLabel={formatOptionLabel}
        value={selectedFilter}
    />)
}

export default SaveHandledSelect;