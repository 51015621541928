import React from 'react';
const AlertDanger = ({ heading, message }) => {

    return (<div
        className="rounded-md border-l-8 py-4 px-6 flex flex-col gap-6 bg-red-100 border-rose-400" >
        {heading && (<div className="flex gap-6 items-center text-red-700">
            <i className="bg-rose-400 fa-ban fa-solid text-white rounded-md w-6 h-6 p-1 text-center " />
            <h1 className="text-2xl font-semibold text-red-700">
                {heading}
            </h1>
        </div>)}
        <p
            className="text-sm font-medium text-red-700"
        >{message}</p>
    </div >)
}



export default AlertDanger;
