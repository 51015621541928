import React, { useState, useRef, useEffect } from "react";

const StatusPage = () => {
  useEffect(() => {
    document.title = `${process.env.APP_NAME} • Status`;
  }, []);

  return (
    <>
      <h1 className="text-gray-900 text-2xl font-semibold">
        Status van de Scrapers
      </h1>
      <div className="gap-3 flex flex-col w-full">
        <div className="flex gap-4 items-center">
          <img
            src="https://healthchecks.io/b/2/aa43a681-a499-4616-874c-fca0c4c25acb.svg"
            alt="Facebook Posts"
          ></img>
          <img
            src="https://healthchecks.io/b/2/1a02441c-8f43-408b-afdf-9425f86ca140.svg"
            alt="Facebook Comments"
          ></img>
        </div>
        <div className="flex gap-4 items-center">
          <img
            src="https://healthchecks.io/b/2/88139516-ff25-4ce7-b73b-9b01407fec11.svg"
            alt="Tiktok Profiles"
          ></img>
          <img
            src="https://healthchecks.io/b/2/a7081a4f-3056-47ec-8ad6-04e027e4cfd5.svg"
            alt="Tiktok Keywords"
          ></img>
          <img
            src="https://healthchecks.io/b/2/a4d0055e-1a5a-43e6-b0ba-3d3a1a6babf0.svg"
            alt="Tiktok Replies"
          ></img>
        </div>
        <div className="flex gap-4 items-center">
          <img
            src="https://healthchecks.io/b/2/b6c2ba1e-bd1f-4a4e-8b39-3b8c5b6a8b1d.svg"
            alt="Twitter Profiles"
          ></img>
          <img
            src="https://healthchecks.io/b/2/95f08518-57af-4b33-abb6-b133a9e74da4.svg"
            alt="Twitter Keywords"
          ></img>
          <img
            src="https://healthchecks.io/b/2/49076bef-68b1-4c9f-b1cb-170b8dd9834d.svg"
            alt="Twitter replies"
          ></img>
        </div>
        <div className="flex gap-4 items-center">
          <img
            src="https://healthchecks.io/b/2/c45d0f6c-95d2-420a-9a60-3f0d81e02823.svg"
            alt="Wordpress posts"
          ></img>
          <img
            src="https://healthchecks.io/b/2/ec32ab99-edee-4777-9fce-9df354573fb9.svg"
            alt="Wordpress comments"
          ></img>
          <img
            src="https://healthchecks.io/b/2/ed692dbf-4d5f-4f1a-ba70-c3e68ec001ae.svg"
            alt="Dumpert"
          ></img>
          <img
            src="https://healthchecks.io/b/2/aaa820db-c630-4049-b043-203b67b4bf01.svg"
            alt="Geen Stijl"
          ></img>
          <img
            src="https://healthchecks.io/b/2/16a99e83-ed30-4dc1-a3a9-fdb56007a1f5.svg"
            alt="blckbx.tv"
          ></img>
        </div>
        <div className="flex gap-4 items-center">
          <img
            src="https://healthchecks.io/b/2/51cd3517-ddcd-444c-b5e6-14f65a11e494.svg"
            alt="Youtube Channels"
          ></img>
          <img
            src="https://healthchecks.io/b/2/be72f90c-bc23-4881-984c-c63be90c46c0.svg"
            alt="Youtube keywords"
          ></img>
          <img
            src="https://healthchecks.io/b/2/f82f224a-3918-4481-8670-7ed9a1dc6940.svg"
            alt="Youtube replies"
          ></img>
        </div>
      </div>

      <h1 className="text-gray-900 text-2xl font-semibold">Changelog</h1>

      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                Feature
              </th>
              <th scope="col" class="px-6 py-3">
                ETA
              </th>
              <th scope="col" class="px-6 py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Public Facing Page
              </th>

              <td class="px-6 py-4"></td>
              <td class="px-6 py-4"></td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Reddit Added as Source
              </th>

              <td class="px-6 py-4">2024-05-10</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Two-factor authentication on projects
              </th>

              <td class="px-6 py-4">2024-05-04</td>
              <td class="px-6 py-4">✅</td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Infinite scroll on feeds
              </th>

              <td class="px-6 py-4">2024-05-05</td>
              <td class="px-6 py-4">✅</td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Dumpert, Geenstijl, blckbx.tv added
              </th>

              <td class="px-6 py-4">2024-04-30</td>
              <td class="px-6 py-4">✅</td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Date range filters
              </th>

              <td class="px-6 py-4">2024-04-26</td>
              <td class="px-6 py-4">✅</td>
            </tr>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Text search box
              </th>

              <td class="px-6 py-4">2024-04-26</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Link Feed to BENEDMO factcheck database
              </th>

              <td class="px-6 py-4">2024-03-31</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Restrict Sign-ups based on email
              </th>

              <td class="px-6 py-4">2024-03-28</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Re-organized sidebar
              </th>

              <td class="px-6 py-4">2024-03-28</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Personal Feed of saved items
              </th>

              <td class="px-6 py-4">2024-03-24</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Daily trending topics
              </th>

              <td class="px-6 py-4">2024-03-23</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Y-axis of linecharts represents virality
              </th>

              <td class="px-6 py-4">2024-03-22</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Direct Link to Youtube comment
              </th>

              <td class="px-6 py-4">2024-03-21</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Sort feed on date or virality
              </th>

              <td class="px-6 py-4">2024-03-21</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Filters for platforms
              </th>

              <td class="px-6 py-4">2024-03-21</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Updated Doubt classifier integrated
              </th>

              <td class="px-6 py-4">2024-03-03</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Fine-tuned front-end Deployed
              </th>

              <td class="px-6 py-4">2024-02-25</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Expose API
              </th>

              <td class="px-6 py-4">2024-02-25</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                CSV/Excel upload sources
              </th>

              <td class="px-6 py-4">2024-02-24</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Topic summaries & concepts
              </th>

              <td class="px-6 py-4">2024-02-23</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Discussion summaries
              </th>

              <td class="px-6 py-4">2024-02-21</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                dashboard topic table feeds
              </th>

              <td class="px-6 py-4">2024-02-17</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                individual topic table feeds
              </th>

              <td class="px-6 py-4">2024-02-16</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Doubts linked to topics
              </th>

              <td class="px-6 py-4">2024-02-15</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Tiktok Replies repaired
              </th>

              <td class="px-6 py-4">2024-02-12</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Youtube Comments repaired
              </th>

              <td class="px-6 py-4">2024-02-12</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Dutch version
              </th>

              <td class="px-6 py-4">2024-02-11</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Manual on site
              </th>

              <td class="px-6 py-4">2024-02-11</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Update password function for users
              </th>

              <td class="px-6 py-4">2024-02-10</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Faster AI suggestions
              </th>

              <td class="px-6 py-4">2024-02-08</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Edit topic page
              </th>

              <td class="px-6 py-4">2024-02-08</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Cron jobs Deployed (all actions automated)
              </th>

              <td class="px-6 py-4">2024-02-06</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Fixes for Safari / Chrome
              </th>

              <td class="px-6 py-4">2024-02-04</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Welcome mail
              </th>

              <td class="px-6 py-4">2024-02-02</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Front-end Beta Deployed
              </th>

              <td class="px-6 py-4">2024-02-01</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Beta Doubt classifier
              </th>

              <td class="px-6 py-4">2024-01-04</td>
              <td class="px-6 py-4">✅</td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                Scrapers
              </th>

              <td class="px-6 py-4">2023-11-01</td>
              <td class="px-6 py-4">✅</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default StatusPage;
