import axios from "../../../services/axios";

export const loadPlatforms = async (
    setIsLoading,
    setPlatforms,
    setSelectedPlatforms
) => {
    setIsLoading(true);
    try {

        const platformResponse = await axios.get(`platforms`);
        setPlatforms(platformResponse.data.map((platform) => {
            return {
                "value": platform.name,
                "label": platform.name[0].toUpperCase() + platform.name.substring(1)
            }
        })
        )
        setSelectedPlatforms(
            platformResponse.data.map((platform) => {
                return {
                    "value": platform.name,
                    "label": platform.name[0].toUpperCase() + platform.name.substring(1)
                }
            }));

    } catch (error) {
        console.error(error)
    } finally {
        setIsLoading(false);
    }
}

