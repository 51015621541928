import React, { useState } from 'react';
import axios from '../../services/axios';
import AlertDanger from '../../components/ui/alerts/AlertDanger';

const FileInputSourceForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [labelPlaceholder, setLabelPlaceholder] = useState("Geen bestand gekozen");

    const handleChange = (event) => {
        console.log("handling change")
        setLabelPlaceholder(event.target.files[0].name);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError();
        const formData = new FormData(event.currentTarget);

        try {
            const response = await axios.post('/sources/xlsx', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            window.location.replace('/sources');

        } catch (error) {

            if (error.response.status == 422) {
                switch (error.response.data.detail) {
                    case "INVALID_FILETYPE":
                        setError("Dien een Excel-bestand (.xlsx) in.")
                        break;
                    case "INVALID_COLUMN_NUMBER":
                        setError("Dien een Excel-bestand in met alleen de kolommen \"Titel\" en \"URL\.")
                        break;
                    case "INVALID_COLUMN_TITLE":
                        setError("Dien een Excel-bestand in met alleen de kolommen \"Titel\" en \"URL\".")
                        break;
                    case "EMPTY_VALUE":
                        setError("Geef elke bron in het Excel-bestand een titel en URL.")
                        break;
                    default:
                        setError("Er is iets fout gegaan. Zorg ervoor dat uw Excel-bestand de kolomkoppen \"title\" en \"url\" heeft en dat elke bron een geldige titel en URL heeft.")
                }
            }
            else {
                setError("Er is iets fout gegaan. Vernieuw de pagina en probeer het opnieuw.")
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (

        <form className="flex flex-col gap-4"
            onSubmit={handleSubmit}
        >

            {
                error && <AlertDanger message={error} />
            }
            <label
                htmlFor='upload-excel'
                className="w-4/5 border p-2 rounded-md border-neutral-200 px-4 flex items-center cursor-pointer">
                <span
                    className="rounded-md text-gray-500 hover:text-gray-800 bg-gray-200 hover:bg-gray-400 transition-colors px-2 py-1 text-sm my-auto text-center mr-2 cursor-pointer ">
                    Kies bestand
                </span>
                <span className="text-gray-400 text-sm cursor-pointer">{labelPlaceholder}</span>
                <input
                    name="excelfile"
                    id="upload-excel"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={handleChange}
                    className='absolute opacity-0 -z-10'
                />
            </label>

            <div>
                <button
                    disabled={isLoading}
                    className="bg-blue-700 w-full hover:bg-blue-900 transition-colors duration-300 ease-in-out text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                >
                    Upload
                </button>
            </div>
        </form>
    )

}

export default FileInputSourceForm;