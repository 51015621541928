import React from 'react';
import { useLocation, Link } from 'react-router-dom';

export default function BreadCrumbBar({ currentPage }) {
    const location = useLocation();
    const crumbs = location.pathname.split('/')
        .filter(crumb => crumb !== '');
    const breadCrumbArray = [];
    if (crumbs[0] === 'sources') {
        breadCrumbArray.push(<span key={"source" + 1}>Sources</span>);
        breadCrumbArray.push(<i key={"chevron" + 1} className="fa-solid fa-chevron-right"></i>);
        if (crumbs.length > 1) {
            if (crumbs[1] === 'new') {
                breadCrumbArray.push(<Link to='/sources/new'><span key={"add-source" + 2}>Add new source</span></Link>);
            }
        } else {
            breadCrumbArray.push(<Link to='/sources'><span key={"source-list" + 2}>List of sources</span></Link>);
        }
    }

    if (crumbs[0] === 'topics') {
        breadCrumbArray.push(<span key={"source" + 0}>Topics</span>);
        breadCrumbArray.push(<i key={"chevron" + 1} className="fa-solid fa-chevron-right"></i>);
        if (crumbs.length > 1) {
            if (crumbs[1] === 'new') {
                breadCrumbArray.push(<Link to='/sources/new'><span key={"add-source" + 1}>Create a topic</span></Link>);
            }
        } else {
            breadCrumbArray.push(<Link to='/sources'><span key={"source-list" + 1}>List of topics</span></Link>);
        }
    }
    return (
        <nav className='flex gap-4 text-gray-500 font-medium items-center'>
            <Link to='/'>
                <i className="fa-sharp fa-regular fa-house-blank"></i>
            </Link>
            <i className="fa-solid fa-chevron-right"></i>
            {breadCrumbArray}
            {currentPage && (
                <span>{currentPage}</span>
            )}
        </nav>)
}
