import { SideNavBar } from "../SideNavBar";
import { HeaderSearchBar } from "../HeaderSearchBar";
import React from 'react';
import { Outlet } from "react-router-dom";


export const MainLayout = () => {
    return (
        <div className="flex h-full">
            <SideNavBar />
            <div className="w-screen lg:w-5/6 flex flex-col gap-11 pb-20 overflow-auto">
                <main id="main-container" className="p-11 flex flex-col gap-6">
                <Outlet />
                </main>
            </div>
        </div>

    )
}
