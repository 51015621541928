import React, { useRef, useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import AlertDanger from '../../components/ui/alerts/AlertDanger';

import AlertSuccess from '../../components/ui/alerts/AlertSuccess';
import Modal from '../../components/ui/Modal';

const ForgotPasswordModal = ({ show, close }) => {

    const emailRef = useRef();
    const { requestPasswordChange } = useAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    useEffect(() => {
        setErrorMessage('');
        setSuccessMessage('');
    }, [show]);

    if (!show) {
        return null
    };

    async function handleSubmit(e) {
        const email = emailRef.current.value
        e.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");
        try {
            setIsLoading(true);
            console.log("resetting password")
            await requestPasswordChange(email);
            setSuccess(true);
            setSuccessMessage(`We verstuurden een e-mail naar ${email}. Klik op de reset-link in de mail.`)
            // navigate("/");
        }
        catch (error) {
            setErrorMessage("Fout bij het versturen van de reset link.")
        }
        setIsLoading(false);
    }

    return (
        <Modal show={show} close={close} >
            <div className="basis-1/6">
                <div className="text-gray-800 text-3xl font-bold mb-4">Reset je paswoord</div>
                <div className='text-gray-500 text-sm mb-6'>{`Geef het email-adres van je ${process.env.APP_NAME}-account in en we sturen je een reset-link.`}</div>
            </div>
            <div className="basis-5/6 flex flex-col gap-4">
                {errorMessage && <AlertDanger message={errorMessage} />}
                {success && <AlertSuccess heading='Success!' message={successMessage} />}
                <form className="h-full flex flex-col justify-between"
                    onSubmit={handleSubmit}
                >
                    <div className="mb-6">
                        <label className="block text-gray-300 text-sm mb-2" htmlFor="email">
                            E-mailadres
                        </label>
                        <input
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="signup-email" type="email" ref={emailRef} required />
                    </div>
                    <div>
                        <button
                            className="bg-blue-700 w-full hover:bg-blue-900 transition-colors duration-300 ease-in-out text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            disabled={isLoading}
                            type="submit">
                            Reset paswoord
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default ForgotPasswordModal;