import React, { useEffect, useState } from "react";
import formatDate from "../../utils/formatting/formatDate";
import getHighlightedText from "../../utils/formatting/highlightText";
import DOMPurify from "dompurify";

const FeedItemComment = ({ comment, highlightString }) => {
  const [showFullText, setShowFullText] = useState(false);
  const textExceedsCharacterLimit = comment.text.length > 330;


  const toggleShowFullText = (event) => {
    event.preventDefault();
    setShowFullText((prev) => !prev);
  };

  return (
    <div>
      <span className="flex ml-2 cursor-hover hover:bg-neutral-100 p-2 rounded-md">
        <a
          href={comment.url}
          className="underline mr-6 text-neutral-400 w-[156px]"
          target="_blank"
        >
          {comment.datetime ? formatDate(comment.datetime) : ""}{" "}
        </a>
        <p
          className="text-neutral-400 w-5/6 break-words"
          onClick={toggleShowFullText}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                showFullText || !textExceedsCharacterLimit
                  ? highlightString ? getHighlightedText(comment.text, highlightString).concat(" ") : comment.text.concat(" ")
                  : highlightString ? getHighlightedText(comment.text, highlightString).substring(0, 320).concat("... ") : comment.text.substring(0, 320).concat("... "))
            }}
          />
          {textExceedsCharacterLimit && !showFullText && (
            <button className="text-cyan-600 px-1 rounded-lg hover:bg-neutral-200">
              lees meer
            </button>
          )}
        </p>
      </span>
    </div>
  );
};

export default FeedItemComment;
