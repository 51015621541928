import axios from "../../../services/axios";

export const handleSave = async (
    platform, 
    postUid,
    setFeedItems
) => {
    console.log("render")
    setFeedItems(prev => prev.map(item => {
        if ((item.type === platform) && (item.uid === postUid)) {
            return { ...item, isUpdating: true }; // remove save record
        } else {
            // No change
            return item
        }
    }));
    try {
        const response = await axios.post("/saves",
            {
                "platform": platform,
                "post_uid": postUid
            },
            {
                headers: {
                    "Accept": 'application/json',
                    "Content-Type": "application/json"
                }
            }

        );
        setFeedItems(prev => prev.map(item => {
            if ((item.uid === postUid) && (item.type === platform)) {
                return { ...item, save: { id: response.data.id, handled: response.data.handled } };
            } else {
                // No change
                return item
            }
        }));
    } catch (error) {
        console.error( error)
    } finally {
        setFeedItems(prev => prev.map(item => {
            if ((item.type === platform) && (item.uid === postUid)) {
                return { ...item, isUpdating: false }; // remove save record
            } else {
                // No change
                return item
            }
        }));
    }
}
