import axios from "../../../services/axios";

export const handleUnsave = async (
    saveId,
    setFeedItems,
    feedItems
) => {
    setFeedItems(prev => prev.map(item => {
        if ((item.save) && (item.save.id === saveId)) {
            return { ...item, isUpdating: true }; // remove save record
        } else {
            // No change
            return item
        }
    }));
    const item = feedItems.find((item) => {
        if ((item.save) && (item.save.id === saveId)) {
            return item;
        }
    })
    const uid = item.uid;
    const platform = item.platform;
    try {
        await axios.delete(`/saves/${saveId}`,
            {
                headers: {
                    "Accept": 'application/json',
                }
            }
        );
        setFeedItems(prev => prev.map(item => {
            if ((item.save) && (item.save.id === saveId)) {
                return { ...item, save: null }; // remove save record
            } else {
                // No change
                return item
            }
        }));
    } catch (error) {
        console.error( error)
    } finally {
        setFeedItems(prev => prev.map(item => {
            if ((item.platform === platform) && (item.uid === uid)) {
                return { ...item, isUpdating: false }; // set not updating
            } else {
                // No change
                return item
            }
        }));
    }
}
