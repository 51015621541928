import React, { useState, useRef, useEffect } from "react";

const AddStatusPage = () => {
  useEffect(() => {
    document.title = `${process.env.APP_NAME} • Handleiding`;
  }, []);

  return (
    <>
      <style>{`
    table td{
     border:1px solid black;
     padding: 10px;
    }
     h1 {
      margin-top: 20px;
     }
      h2 {
      margin-top: 10px;
  }
      p {
      margin-top: 5px;}
  `}</style>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {" "}
        {/* Example of a container */}
        {/* Your existing JSX here */}
        <h1 className="text-gray-900 text-3xl font-semibold">
          Verwerkersovereenkomst (versie 2024-10-08)
        </h1>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 1: definities
        </h2>
        <ul>
          <li className="text-gray-900">
            <span className="font-semibold">“Overeenkomst”</span>: onderhavige
            verwerkersovereenkomst inclusief haar bijlagen;
          </li>
          <li className="text-gray-900">
            <span className="font-semibold">“Persoonsgegevens”</span>: alle
            informatie over een geïdentificeerde of identificeerbare natuurlijke
            persoon; als identificeerbaar wordt beschouwd een natuurlijke
            persoon die direct of indirect kan worden geïdentificeerd, met name
            aan de hand van een identificator zoals een naam, een
            identificatienummer, locatiegegevens, een online identificator of
            van één of meer elementen die kenmerkend zijn voor de fysieke,
            fysiologische, genetische, psychische, economische, culturele of
            sociale identiteit van die natuurlijke persoon;
          </li>
          <li className="text-gray-900">
            <span className="font-semibold">“Gegevenslek”</span>: een inbreuk op
            de beveiliging die per ongeluk of op onrechtmatige wijze leidt tot
            vernietiging, het verlies, de wijziging of de ongeoorloofde
            verstrekking van of de ongeoorloofde toegang tot doorgezonden,
            opgeslagen of anderszins verwerkte Persoonsgegevens;
          </li>
          <li className="text-gray-900">
            <span className="font-semibold">“GDPR”</span>: Algemene Verordening
            Gegevensbescherming (EU) 2016/679 van 27 april 2016 van het Europees
            Parlement en de Raad betreffende de bescherming van natuurlijke
            personen in verband met de verwerking van persoonsgegevens en
            betreffende het vrije verkeer van die gegevens en tot intrekking van
            Richtlijn 95/46/EG.
          </li>

          <li className="text-gray-900">
            <span className="font-semibold">“Subverwerker”</span>: dit is iedere
            onderaannemer die door de Verwerker wordt aangesteld om een deel van
            het verwerkingsproces voor de Verwerkingsverantwoordelijke op zich
            te nemen.
          </li>
        </ul>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 2: Voorwerp
        </h2>
        <p className="text-gray-900">
          Deze Overeenkomst vloeit voort uit de verplichting opgenomen in
          artikel 28 van de GDPR die bepaalt dat tussen de Verwerker en de
          Verwerkingsverantwoordelijke een geschreven overeenkomst dient
          gesloten te worden.
        </p>
        <p className="text-gray-900">
          Deze Overeenkomst regelt de rechten en verplichtingen van de
          Verwerkingsverantwoordelijke en de Verwerker bij het verwerken van
          Persoonsgegevens.
        </p>
        <p className="text-gray-900">
          De Verwerker verbindt zich ertoe om vanaf de inwerkingtreding van deze
          Overeenkomst, bij het uitvoeren van verwerkingsactiviteiten in
          opdracht van de Verwerkingsverantwoordelijke, deze Overeenkomst na te
          leven. Indien er reeds verwerkingsactiviteiten plaatsvonden vóór de
          inwerkingtreding van de Overeenkomst, verbindt de Verwerker zich ertoe
          om in elk geval vanaf de inwerkingtreding van de Overeenkomst deze
          verwerkingen te verrichten conform de Overeenkomst.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 3: Contactgegevens
        </h2>
        <p className="text-gray-900">
          <b>Voor de Verwerker treedt op als vaste contactpersoon:</b>
          <br></br>
          <span className="font-semibold">Naam:</span> Redouan el Hamouchi
          <br></br>
          <span className="font-semibold">E-mail:</span> redouan@textgain.com
          <br></br>
          <span className="font-semibold">Tel:</span> +31 6 412 13 650<br></br>
        </p>
        <p className="text-gray-900">
          <b>Data-Protection Officer (DPO)</b>
          <br></br>
          <span className="font-semibold">Naam:</span> Mr. Franklin<br></br>
          <span className="font-semibold">E-mail:</span> hallo@misterfranklin.be
          <br></br>
          <span className="font-semibold">Tel:</span> +32 486 27 53 05<br></br>
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 4: rechten en plichten van de Verwerker
        </h2>
        <p className="text-gray-900">
          De Verwerker handelt uitsluitend in opdracht van de
          Verwerkingsverantwoordelijke bij de verwerking van de
          Persoonsgegevens.
        </p>
        <p className="text-gray-900">
          De Verwerker zal enkel Persoonsgegevens verwerken zoals beschreven in
          Bijlage 1 van de Overeenkomst, en te allen tijde in overeenstemming
          met de bepalingen van de Overeenkomst en de schriftelijke instructies
          van de Verwerkingsverantwoordelijke. De Verwerkingsverantwoordelijke
          laat de Verwerker evenwel uitdrukkelijk toe om bijkomend de verwerkte
          Persoonsgegevens te anonimiseren en op deze manier tot geaggregeerde
          statistieken te komen met het oog op de analyse hiervan in functie van
          de optimalisatie door de Verwerker van diens SaaS-dienstverlening.
        </p>
        <p className="text-gray-900">
          De Verwerker zal enkel deze Persoonsgegevens verwerken die strikt
          noodzakelijk zijn voor de uitvoering van de Overeenkomst en
          uitsluitend deze Persoonsgegevens opgenomen in Bijlage 1 van de
          Overeenkomst.
        </p>
        <p className="text-gray-900">
          De Verwerker verbindt zich ertoe om zijn personeelsleden die instaan
          voor de verwerking van de Persoonsgegevens en het uitvoeren van de
          Overeenkomst regelmatig te informeren en bij te scholen aangaande
          bepalingen van de privacyregelgeving in het algemeen en de GDPR in het
          bijzonder. De Verwerker waarborgt dat deze personeelsleden zich ertoe
          hebben verbonden vertrouwelijkheid in acht te nemen, of door een
          passende wettelijke verplichting aan een
          vertrouwelijkheidsverplichting zijn gebonden.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 5: rechten en plichten van de Verwerkingsverantwoordelijke
        </h2>
        <p className="text-gray-900">
          De Verwerkingsverantwoordelijke verbindt zich ertoe om, telkens
          wanneer deze een nieuwe verwerkingsopdracht geeft aan de Verwerker of
          telkens wanneer het doel van de verwerking wijzigt, een addendum aan
          of wijziging van onderhavige Overeenkomst voor te stellen.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 6: verwerking van persoonsgegevens
        </h2>
        <p className="text-gray-900">
          <span className="font-semibold">6.1</span> De Verwerker is verplicht
          tot geheimhouding van de Persoonsgegevens die hij verwerkt in opdracht
          van de Verwerkingsverantwoordelijke.
        </p>
        <p className="text-gray-900">
          <span className="font-semibold">6.2.</span>
          De Persoonsgegevens mogen door de Verwerker enkel worden verwerkt voor
          de doeleinden omschreven in Bijlage 1 van de Overeenkomst. Het is de
          Verwerker in het licht van de Overeenkomst toegelaten om de
          Persoonsgegevens buiten de Europese Economische Ruimte te (laten)
          verwerken, mits de Verwerkingsverantwoordelijke hiervan
          voorafgaandelijk op de hoogte is gebracht en mits naleving van artikel
          44 en verder van de GDPR.
        </p>
        <p className="text-gray-900">
          <span className="font-semibold">6.3</span>
          De Verwerkingsverantwoordelijke geeft aan de Verwerker de toestemming
          om de Persoonsgegevens mee te delen aan alle personen, instellingen en
          instanties die rechtstreeks deelnemen aan de uitvoering van de
          opdracht en wanneer dit strikt noodzakelijk is voor de uitvoering van
          de Overeenkomst en binnen de grenzen van de Overeenkomst en de GDPR.
        </p>
        <p className="text-gray-900">
          <span className="font-semibold">6.4.</span>
          De mededeling aan andere derden dan deze omschreven in het vorig lid,
          is verboden tenzij dit door of krachtens de wet wordt opgelegd of
          verplicht is op basis van een rechterlijk bevel. Elke wettelijke of
          gerechtelijke verplichte mededeling aan derden moet door de Verwerker
          vooraf ter kennis worden gebracht aan de Verwerkingsverantwoordelijke.
        </p>
        <p className="text-gray-900">
          <span className="font-semibold">6.5.</span>
          De Verwerker kan overgaan tot het nemen van een back-up of een kopie
          indien dit strikt noodzakelijk is om de Overeenkomst uit te voeren.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 7: Rechten van de betrokkene
        </h2>
        <p className="text-gray-900">
          In geval de Verwerkingsverantwoordelijke een aanvraag ontvangt van de
          betrokkene van wie Persoonsgegevens worden verwerkt, om zijn rechten
          uit te oefenen overeenkomstig de GDPR zoals bv. recht van verzet of
          recht van wissing van de Persoonsgegevens, geeft de
          Verwerkingsverantwoordelijke deze opdracht onverwijld door aan de
          Verwerker.
        </p>
        <p className="text-gray-900">
          De Verwerker verbindt zich ertoe om onverwijld en uiterlijk binnen 10
          werkdagen na ontvangst van de aanvraag, een passend gevolg te geven
          aan deze opdracht van de Verwerkingsverantwoordelijke en ofwel de
          gevraagde informatie te verschaffen ofwel de gevraagde aanpassingen te
          doen aan de Persoonsgegevens, dan wel bepaalde Persoonsgegevens te
          verwijderen en vernietigen.
        </p>
        <p className="text-gray-900">
          In geval de Verwerker zelf rechtstreeks een aanvraag zou ontvangen van
          de betrokkene van wie Persoonsgegevens worden verwerkt, om zijn
          rechten uit te oefenen overeenkomstig de GDPR, geeft hij dit zonder
          onredelijke vertraging door aan de Verwerkingsverantwoordelijke en
          levert hij de door de Verwerkingsverantwoordelijke verzochte redelijke
          bijstand om naar behoren te kunnen voldoen aan dit verzoek. De
          Verwerker zal zelf niet antwoorden op dit verzoek of hiertoe enige
          andere handelingen stellen, tenzij onder instructie van de
          Verwerkingsverantwoordelijke. De Verwerker mag wel de hiervoor
          beschreven doorverwijzing aan de betrokkene bevestigen samen met de
          motivatie hiervoor.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 8: vertrouwelijkheid
        </h2>
        <p className="text-gray-900">
          Alle Persoonsgegevens en informatie die de Partijen in het kader van
          deze Overeenkomst ontvangen, zullen tijdens de duur van deze
          Overeenkomst en tien jaar daarna als vertrouwelijk worden behandeld en
          zullen niet onthuld worden aan derden en niet worden gebruikt voor
          enig ander doel dan het bevorderen van de doelstellingen van de
          Overeenkomst.
        </p>
        <p className="text-gray-900">
          De in het vorige lid omschreven verplichting is niet van toepassing op
          vertrouwelijke informatie die:
          <ul>
            <li className="text-gray-900">
              Ten tijde van de onthulling door de onthullende Partij reeds
              openbaar beschikbaar was of daarna zonder toedoen van de
              ontvangende Partij openbaar beschikbaar wordt;
            </li>
            <li className="text-gray-900">
              Ten tijde van de onthulling reeds in het wettige bezit was van de
              ontvangende Partij zoals voldoende kan worden aangetoond door de
              ontvangende Partij; of
            </li>
            <li className="text-gray-900">
              Na de onthulling ervan door de onthullende Partij, op een
              niet-vertrouwelijke basis van derden wordt ontvangen door de
              ontvangende Partij.
            </li>
          </ul>
        </p>
        <p className="text-gray-900">
          De Persoonsgegevens worden eveneens beschouwd als vertrouwelijke
          informatie dewelke op geen enkel moment in de toekomst, ook niet na 10
          jaar door de Verwerker kan gebruikt worden dan binnen de grenzen van
          onderhavige Overeenkomst.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 9: aansprakelijkheid en garanties
        </h2>
        <p className="text-gray-900">
          Indien de Verwerker aangesproken wordt door de
          Verwerkingsverantwoordelijke tot schadevergoeding ingevolge de
          niet-naleving van deze Overeenkomst of de GDPR, dan zal de
          aansprakelijkheid van de Verwerker, behoudens ingeval van opzet, per
          geheel van gebeurtenissen die aanleiding geven tot een geschil,
          beperkt zijn tot het relevante bedrag effectief uitgekeerd onder de
          aansprakelijkheidsverzekering van de Verwerker indien de feiten
          hierdoor gedekt worden.
        </p>
        <p className="text-gray-900">
          Indien de verzekeraar om welke reden dan ook niet uitkeert, zal de
          aansprakelijkheid van de Verwerker behoudens opzet per geheel van
          gebeurtenissen die aanleiding geven tot een geschil, in ieder geval
          beperkt blijven tot het totaal van de bedragen die de Verwerker aan de
          Verwerkingsverantwoordelijke heeft gefactureerd en die door de
          Verwerkingsverantwoordelijke zijn betaald gedurende het jaar dat
          voorafgaat aan de feiten die aanleiding geven tot deze
          aansprakelijkheid. Voor de berekening van dit maximumbedrag aan
          aansprakelijkheid in hoofde van de Verwerker komen enkel bedragen in
          aanmerking die werden betaald voor geleverde cloudproducten en
          gerelateerde diensten die voor wat betreft de gegevensverwerking die
          hiermee gepaard gaat, onder het toepassingsgebied van deze
          Overeenkomst vallen.
        </p>
        <p className="text-gray-900">
          Een reeks van samenhangende feiten die aanleiding geven tot
          aansprakelijkheid in hoofde van de Verwerker, wordt voor de toepassing
          van dit artikel beschouwd als een geheel van gebeurtenissen die
          aanleiding geven tot eenzelfde geschil. In dit geval zal de totale
          aansprakelijkheid voor dit geheel van gebeurtenissen in hoofde van de
          Verwerker bovenvermelde bedragen niet kunnen overstijgen.
        </p>
        <p className="text-gray-900">
          De Verwerker is niet aansprakelijk voor enige vorm van indirecte
          schade zoals, maar niet beperkt tot ondernemingsstagnatie, verminderde
          goodwill, gemiste besparingen, gederfde winst, reputatieschade of
          enige andere vorm van indirecte, incidentele of gevolgschade, ongeacht
          de aard van de handeling.
        </p>
        <p className="text-gray-900">
          De toepassing van deze bepaling laat de eventuele aansprakelijkheid
          van de Verwerker ten aanzien van de betrokkene op grond van artikel 82
          van de GDPR onverlet.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 10: duur, opzeg en beëindiging
        </h2>
        <p className="text-gray-900">
          De Overeenkomst is onlosmakelijk verbonden met de tussen Partijen
          gesloten overeenkomst(en) gespecifieerd in Bijlage 1 en is te
          beschouwen als een aanvulling daarop dan wel wijziging daarvan, en
          prevaleert boven alle andere overeenkomsten tussen Partijen. Partijen
          zullen deze Overeenkomst aanpassen aan gewijzigde of aangevulde
          regelgeving, aanvullende instructies van de relevante autoriteiten en
          voortschrijdend inzicht in de toepassing van de privacywetgeving
          (bijvoorbeeld door, maar niet beperkt tot, jurisprudentie of adviezen
          van de Gegevensbeschermingsautoriteit of het Europees Comité voor
          Gegevensbescherming).
        </p>
        <p className="text-gray-900">
          De Overeenkomst gaat in op heden en wordt gesloten voor onbepaalde
          duur. De Overeenkomst kan door elk van de Partijen opgezegd worden bij
          aangetekend schrijven en mits inachtname van een opzegtermijn van 3
          maand. De opzegtermijn begint te lopen de eerste dag van de maand die
          volgt op het verzenden van de aangetekende zending, waarbij de
          poststempel zal gelden als bewijs. Indien de hoofdovereenkomst(en)
          gespecifieerd in Bijlage 1 op het einde van de opzegtermijn nog niet
          beëindigd zijn, zal de opzegtermijn geschorst worden tot ook deze
          overeenkomst(en), die onderhavige verwerking van Persoonsgegevens
          impliceert/impliceren, zijn beëindigd.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 11: Gevolgen van de beëindiging
        </h2>
        <p className="text-gray-900">
          In geval van beëindiging van onderhavige Overeenkomst, ongeacht de
          wijze waarop, zal de Verwerker op eigen initiatief alle
          Persoonsgegevens-bevattende documenten, computer disks, en andere
          informatiedragers, met inbegrip van kopieën daarvan, ongeacht of de
          inhoud van de informatiedragers is geproduceerd of gemaakt door de
          Verwerker, de Verwerkingsverantwoordelijke dan wel derden, retourneren
          dan wel de Subverwerkers de opdracht geven om één en ander te doen,
          binnen een redelijke termijn en onder voorbehoud van hetgeen in de
          derde alinea bepaald is.
        </p>
        <p className="text-gray-900">
          In zoverre als de Persoonsgegevens worden bewaard of opgeslagen op een
          computersysteem van de Verwerker of in enige andere vorm zijn
          vastgelegd die redelijkerwijze niet aan de andere Partij ter hand kan
          worden gesteld, zal de Verwerker dergelijke Persoonsgegevens
          vernietigen en/of aan zijn Subverwerker(s) de opdracht geven om het
          nodige te doen binnen een redelijke termijn, onder voorbehoud van
          hetgeen in de derde alinea bepaald is.
        </p>
        <p className="text-gray-900">
          De Verwerker zal volledig gevolg dienen te geven aan de verplichtingen
          opgenomen in dit artikel binnen 3 maanden na beëindiging van de
          Overeenkomst. Voor zover specifieke termijnen zijn overeengekomen voor
          bepaalde Persoonsgegevens, zullen de betreffende Persoonsgegevens
          reeds eerder verwijderd worden en waar relevant reeds in de loop van
          de Overeenkomst, conform de instructies van de
          Verwerkingsverantwoordelijke.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 12: bewaring van persoonsgegevens
        </h2>
        <p className="text-gray-900">
          De Verwerker zal de Persoonsgegevens niet langer bewaren dan
          noodzakelijk is voor het verrichten van de opdracht waarvoor ze ter
          beschikking worden gesteld. Zijn de persoonsgegevens hierna niet meer
          nodig, dan zal de Verwerker ze adequaat uitwisselen en permanent
          verwijderen, ofwel de gegevensdragers terugbezorgen aan de
          Verwerkingsverantwoordelijke, onder voorbehoud van wat in artikel 11
          bepaald is.
        </p>
        <p className="text-gray-900">
          Deze bepaling geldt eveneens voor de gegevensdragers waarop de kopie
          of back-up conform artikel 6.5 van de Overeenkomst bewaard werd.
        </p>
        <p className="text-gray-900">
          Deze bepaling is ook van toepassing op eventuele Subverwerkers waarop
          de Verwerker een beroep zou doen.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 13: Controle door Verwerkingsverantwoordelijke
        </h2>
        <p className="text-gray-900">
          De Verwerkingsverantwoordelijke heeft het recht om de naleving van de
          Overeenkomst te controleren, desgevallend via tussenkomst van een
          gemachtigd controleur. Daartoe kan hij zich, na afspraak minstens twee
          weken voorafgaand aan het bezoek, ter plaatse begeven in de lokalen of
          plaatsen waar de Verwerker of de Subverwerkers de gegevensverwerking
          uitvoeren en/of de kopieën of back-ups bewaren en daar alle nuttige en
          nodige documenten inkijken, op verzoek, die noodzakelijk zijn teneinde
          zich ervan te verzekeren dat de verwerking door de Verwerker of
          Subverwerker conform is aan de bepalingen van onderhavige Overeenkomst
          en de bepalingen van de GDPR. De Verwerker kan de ondertekening van
          een vertrouwelijkheidsverklaring of -overeenkomst als voorwaarde
          stellen voor het betreden van diens lokalen of andere gebouwen, ten
          aanzien van de personen die met deze opdracht belast worden door de
          Verwerkingsverantwoordelijke.
        </p>
        <p className="text-gray-900">
          De kosten voor deze audit zullen ten laste zijn van de Verwerker
          indien zou blijken dat de Verwerker zich niet schikte naar de
          bepalingen van de Overeenkomst, de instructies van de
          Verwerkingsverantwoordelijke en/of de bepalingen van de GDPR.
        </p>
        <p className="text-gray-900">
          De Verwerker stelt de Verwerkingsverantwoordelijke tevens op verzoek
          de informatie ter beschikking die nodig is om de nakoming van de in
          deze Overeenkomst neergelegde verplichtingen aan te tonen.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 14: Beveiliging
        </h2>
        <p className="text-gray-900">
          De Verwerker verbindt zich ertoe om de gepaste technische en
          organisatorische maatregelen te nemen om de Persoonsgegevens en de
          verwerking ervan te beveiligen.
        </p>
        <p className="text-gray-900">
          De Verwerker verbindt zich ertoe om de nodige maatregelen te treffen
          teneinde de toegang tot de Persoonsgegevens te beperken tot deze
          medewerkers in dienst van de Verwerker die toegang tot deze
          Persoonsgegevens nodig hebben om de Overeenkomst te kunnen uitvoeren.
        </p>
        <p className="text-gray-900">
          Indien de Verwerker een beroep doet op Subverwerkers voor de
          uitvoering van de Overeenkomst, garandeert de Verwerker dat hij met
          deze Subverwerkers een overeenkomst heeft gesloten die minimaal de
          garanties en verplichtingen voortvloeiende uit deze Overeenkomst
          bevat.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 15: Subverwerkers
        </h2>
        <p className="text-gray-900">
          Het is de Verwerker toegestaan om Subverwerkers aan te stellen of te
          vervangen met het oog op het uitvoeren van de verwerkingsactiviteiten
          die het voorwerp uitmaken van onderhavige Overeenkomst. De
          Verwerkingsverantwoordelijke verleent hiertoe bij deze een algemene
          schriftelijke toestemming aan de Verwerker. In dat geval zal de
          Verwerker de Verwerkingsverantwoordelijke in kennis stellen
          voorafgaand aan de aanstelling of vervanging van de Subverwerker, en
          zal de Verwerkingsverantwoordelijke de kans hebben om binnen de
          redelijke termijn zoals door de Verwerker ter kennis gegeven, bezwaar
          te maken tegen de aanstelling.
        </p>
        <p className="text-gray-900">
          In geval de Verwerker beroep wenst te doen op een Subverwerker in de
          zin van dit artikel, verbindt de Verwerker zich ertoe om met deze
          Subverwerker een schriftelijke overeenkomst te sluiten die minimaal de
          garanties en verplichtingen voortvloeiende uit deze Overeenkomst
          omvat.
        </p>
        <p className="text-gray-900">
          De Verwerker houdt een actueel register bij van de door hem
          ingeschakelde Subverwerkers waarin onder meer de identiteit,
          vestigingsplaats en een beschrijving van de werkzaamheden van de
          Subverwerkers zijn opgenomen, alsmede eventuele door de
          Verwerkingsverantwoordelijke gestelde aanvullende voorwaarden. Dit
          register zal als Bijlage 3 aan deze Overeenkomst worden toegevoegd en
          zal door de Verwerker actueel worden gehouden. In deze Bijlage 3
          worden eveneens de Subverwerkers opgenomen die van bij de aanvang van
          de Overeenkomst worden ingeschakeld.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 16: Diverse bepalingen
        </h2>
        <p className="text-gray-900">
          <span className="font-semibold">16.1</span> De Overeenkomst omvat het
          volledige akkoord tussen Partijen omtrent het voorwerp ervan en
          vervangt alle voorgaande, geschreven en mondelinge akkoorden
          hieromtrent.
        </p>
        <p className="text-gray-900">
          <span className="font-semibold">16.2</span> Indien één of meer
          bepalingen van de Overeenkomst nietig zouden worden verklaard of
          onuitvoerbaar zouden worden, zal hierdoor de wettelijkheid, geldigheid
          en het uitvoerbare en afdwingbare karakter van de overige bepalingen
          van de Overeenkomst en van de Overeenkomst in zijn geheel, voor zover
          deze nog enige uitwerking of bestaansgrond hebben, niet worden
          aangetast.
        </p>
        <p className="text-gray-900">
          Partijen verbinden er zich toe om in de mate van wat wettelijk
          mogelijk is, de ongeldige bepalingen te vervangen door een nieuwe
          bepaling die overeenstemt met de doelstellingen en keuzen van huidige
          Overeenkomst.
        </p>
        <p className="text-gray-900">
          <span className="font-semibold">16.3</span> Geen van de Partijen kan
          de rechten uit deze Overeenkomst overdragen aan derden zonder het
          voorafgaand schriftelijk akkoord van de andere Partij. Aanpassingen of
          wijzigingen aan deze Overeenkomst kunnen enkel plaatvinden als deze
          schriftelijk tussen beide Partijen aanvaard en ondertekend worden.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 17: Gegevenslekken
        </h2>
        <p className="text-gray-900">
          In geval van ontdekking van een Gegevenslek zal de Verwerker, de
          Verwerkingsverantwoordelijke zo spoedig mogelijk en uiterlijk binnen
          de 24 uren na de ontdekking hiervan telefonisch informeren met een
          onmiddellijke schriftelijke bevestiging via de contactgegevens (het
          telefoonnummer respectievelijk het e-mailadres) van de DPO dan wel de
          Vaste contactpersoon GDPR van de Verwerkingsverantwoordelijke, zoals
          opgenomen onder artikel 3 van de Overeenkomst. In geen geval zal de
          Verwerker zélf de betrokkenen informeren over dit Gegevenslek,
          onverminderd de verplichting om de gevolgen van dergelijke inbreuken
          en incidenten zo snel mogelijk ongedaan te maken dan wel te beperken.
        </p>
        <p className="text-gray-900">
          Verwerker zal voorts, op het eerste verzoek van de
          Verwerkingsverantwoordelijke, alle inlichtingen verschaffen die de
          Verwerkingsverantwoordelijke noodzakelijk acht om het incident te
          kunnen beoordelen.
        </p>
        <p className="text-gray-900">
          De Verwerker verbindt zich ertoe om na de ontdekking van een
          Gegevenslek, de Verwerkingsverantwoordelijke op de hoogte te houden
          van maatregelen die werden genomen teneinde de omvang van het
          Gegevenslek te beperken dan wel teneinde dit in de toekomst te
          vermijden.
        </p>
        <p className="text-gray-900">
          De Verwerker beschikt over een gedegen plan van aanpak betreffende de
          omgang met en afhandeling van Gegevenslekken en zal de
          Verwerkingsverantwoordelijke, op diens verzoek, inzage verschaffen in
          het plan. Verwerker stelt de Verwerkingsverantwoordelijke op de hoogte
          van materiële wijzigingen in het plan van aanpak.
        </p>
        <p className="text-gray-900">
          De Verwerker zal het doen van meldingen aan de toezichthouder(s)
          overlaten aan de Verwerkingsverantwoordelijke.
        </p>
        <p className="text-gray-900">
          De Verwerker zal alle noodzakelijke medewerking verlenen aan het zo
          nodig, op de kortst mogelijke termijn, verschaffen van aanvullende
          informatie aan de toezichthouder(s) en/of betrokkene(n). Daarbij
          verschaft Verwerker in ieder geval de informatie, zoals beschreven in
          Bijlage 2, aan de Verwerkingsverantwoordelijke.
        </p>
        <p className="text-gray-900">
          De Verwerker houdt een gedetailleerd logboek bij van alle (vermoedens
          van) Gegevenslekken, evenals de maatregelen die in vervolg op
          dergelijke Gegevenslekken zijn genomen waarin minimaal de informatie
          zoals bedoeld in Bijlage 2 is opgenomen, en geeft daar op eerste
          verzoek van de Verwerkingsverantwoordelijke inzage in.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 18: exporteren van persoonsgegevens
        </h2>
        <p className="text-gray-900">
          De Verwerker verbindt zich ertoe om geen Persoonsgegevens aan een
          derde land of een internationale organisatie door te geven dan in
          overeenstemming met artikel 6.2 van de Overeenkomst, tenzij een op de
          Verwerker van toepassing zijnde Unierechtelijke of lidstaatrechtelijke
          bepaling hem tot verwerking verplicht; In dat geval stelt de Verwerker
          de Verwerkingsverantwoordelijke voorafgaand aan de verwerking in
          kennis van dat wettelijk voorschrift, tenzij die wetgeving deze
          kennisgeving om gewichtige redenen van algemeen belang verbiedt.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 19: Bijstand
        </h2>
        <p className="text-gray-900">
          De Verwerker staat de Verwerkingsverantwoordelijke bij in het nakomen
          van diens verplichtingen onder de GDPR. De Verwerker verleent bijstand
          aan de Verwerkingsverantwoordelijke in het kader van het uitvoeren van
          een gegevensbeschermingseffectbeoordeling overeenkomstig artikel 35 en
          36 van de GDPR.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Artikel 20: Toepasselijk recht en geschillen
        </h2>
        <p className="text-gray-900">
          Deze Overeenkomst is in alle opzichten onderworpen aan en wordt in
          alle opzichten geïnterpreteerd en uitgelegd volgens het Belgisch
          recht.
        </p>
        <p className="text-gray-900">
          Geschillen omtrent de uitvoering of uitlegging van de Overeenkomst
          worden uitsluitend voorgelegd aan de rechtbanken territoriaal bevoegd
          voor de maatschappelijke zetel van de Verwerker.
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Bijlage 1: Details van de verwerkingsactiviteiten
        </h2>
        <table>
          <thead>
            <tr>
              <th className="text-gray-900 font-semibold">Persoonsgegevens</th>
              <th className="text-gray-900 font-semibold">
                Categorie persoonsgegevens
              </th>
              <th className="text-gray-900 font-semibold">
                Doel van de verwerking
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Gegevens sociale media account (naam sociale media account,
                publieke specificaties omtrent dit account)
              </td>
              <td>contactgegevens</td>
              <td>
                Gebruikers van berichten identificeren aan de hand van de naam
                van hun social media account
              </td>
            </tr>
            <tr>
              <td>
                Berichten/posts/media publiek geplaatst op sociale media
                accounts + link naar origineel
              </td>
              <td>Berichten / media</td>
              <td>Berichten consulteren, Trends op social media analyseren</td>
            </tr>
          </tbody>
        </table>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Bijlage 2: Overzicht van inlichtingen bij een incident
        </h2>
        <p className="text-gray-900">
          Verwerker verschaft in ieder geval de volgende informatie aan de
          Verwerkingsverantwoordelijke bij een (potentieel) Gegevenslek dat
          Persoonsgegevens van de Verwerkingsverantwoordelijke betreft
          (desgevallend gespreid over meerdere mededelingen):
          <ul>
            <li className="text-gray-900">
              wat de (vermeende) oorzaak is van de inbreuk;
            </li>
            <li className="text-gray-900">
              wat het (vooralsnog bekende en/of te verwachten) gevolg is;
            </li>
            <li className="text-gray-900">
              wat de (voorgestelde) oplossing is;
            </li>
            <li className="text-gray-900">
              relevante contactgegevens voor de opvolging van de melding;
            </li>
            <li className="text-gray-900">
              aantal personen van wie Persoonsgegevens betrokken zijn bij de
              inbreuk (indien geen exact aantal bekend is: het minimale en
              maximale aantal personen van wie Persoonsgegevens betrokken kunnen
              zijn bij de inbreuk);
            </li>
            <li className="text-gray-900">
              een omschrijving van de groep personen van wie Persoonsgegevens
              betrokken zijn bij de inbreuk;
            </li>
            <li className="text-gray-900">
              het soort of de soorten Persoonsgegevens die betrokken zijn bij de
              inbreuk;
            </li>
            <li className="text-gray-900">
              de datum waarop de (potentiële) inbreuk heeft plaatsgevonden (bij
              benadering indien geen exacte datum bekend is);
            </li>
            <li className="text-gray-900">
              de periode waarbinnen de inbreuk heeft plaatsgevonden;
            </li>
            <li className="text-gray-900">
              de datum en het tijdstip waarop de inbreuk bekend is geworden bij
              de Verwerker of bij een door hem ingeschakelde Subverwerker;
            </li>
            <li className="text-gray-900">
              of de gegevens versleuteld, gehasht of op een andere manier
              onbegrijpelijk of ontoegankelijk zijn gemaakt voor onbevoegden;
            </li>
            <li className="text-gray-900">
              wat de reeds ondernomen maatregelen zijn om de inbreuk te
              beëindigen en om de gevolgen van de inbreuk te beperken, en welke
              maatregelen nog zullen genomen worden of die overwogen worden.
            </li>
          </ul>
        </p>
        <h2 className="text-gray-900 text-xl underline font-semibold">
          Bijlage 3: overzicht van de Subverwerkers
        </h2>
        <table>
          <tbody>
            <tr>
              <td>Subverwerker 1</td>
              <td>Google LLC</td>
            </tr>
            <tr>
              <td>Ondernemingsnummer</td>
              <td>602223102</td>
            </tr>
            <tr>
              <td>Beschrijving van de werkzaamheden</td>
              <td>
                Hosting van database, Hosting van Textgain AI-pipelines,
                automatische samenvatting mbv Google Gemini
              </td>
            </tr>
            <tr>
              <td>Locatie van de verwerking</td>
              <td>Google Cloud Region europe-west2b (Belgium)</td>
            </tr>
            <tr>
              <td>Contact</td>
              <td>
                Gordon House, Barrow Street<br></br>
                Dublin 4<br></br>
                Ierland<br></br>
                <a href="https://cloud.google.com/support/" target="_blank">
                  https://cloud.google.com/support/
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <table>
          <tbody>
            <tr>
              <td>Subverwerker 2</td>
              <td>Hetzner Online GmbH</td>
            </tr>
            <tr>
              <td>Ondernemingsnummer</td>
              <td>DE 812871812</td>
            </tr>
            <tr>
              <td>Beschrijving van de werkzaamheden</td>
              <td>Hosting van database</td>
            </tr>
            <tr>
              <td>Locatie van de verwerking</td>
              <td>Data Center Park Falkenstein (Duitsland)</td>
            </tr>
            <tr>
              <td>Contact</td>
              <td>
                Industriestr. 25<br></br>
                91710 Gunzenhausen<br></br>
                Duitsland<br></br>
                <a
                  href="https://www.hetzner.com/de/legal/legal-notice/"
                  target="_blank"
                >
                  https://www.hetzner.com/de/legal/legal-notice/
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AddStatusPage;
