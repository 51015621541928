import React from "react";

const TrendingTopics = ({ trendingTopics }) => {

    if (!trendingTopics) return null
  
    return (
      <div className="flex max-w-fit gap-8 p-11 bg-white items-center rounded-md shadow-sm border">
        <h1 className="text-xl text-black font-bold">Microtrending</h1>
        <div className="flex gap-4 flex-wrap">
          {
            trendingTopics.map((topic) => {
              return (
                <a href={`/topics/${topic.uuid}`} key={`topic-${topic.uuid}`}>
                  <span className="bg-teal-50 hover:bg-teal-100 transition-colors hover:border-teal-200 border-2 border-teal-100 flex justify-center items-center rounded-full text-gray-600 px-5 py-2 text-lg">
                    {topic.title}
                  </span>
                </a>
              );
            })
          }
        </div>
      </div>
    );
};
export default TrendingTopics;
