import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import shapes from '../../assets/images/apps-426559_1280.jpg'


const WelcomeGraphic = ({ height, setHeight }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const imgRef = useRef();

    const handleResize = () => {

        console.log("height", imgRef.current.clientHeight)
        setHeight(imgRef.current.clientHeight);
    }

    useEffect(() => {


        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (imageLoaded) {
            handleResize();
        }
    }, [imageLoaded]);

    return (<div className="h-full relative">
        <img ref={imgRef}
            id="welcome"
            className="aspect-square brightness-50 blur-[2px]"
            src={shapes}
            onLoad={() => setImageLoaded(true)}
        />
        <img className="absolute top-0 right-0 m-6 w-3/4 brightness-150 drop-shadow-[1px_1px_rgba(0,0,0,1)]" src="/logo-large.png"></img>
    </div>)
}

export default WelcomeGraphic;