import React, { useState, useEffect } from 'react';
import BreadCrumbBar from '../../components/BreadCrumbBar';
import TopicsChart from '../../components/topics/chart/TopicsChart';
import axios from '../../services/axios';
import SharedTermsWidget from '../../components/sharedTerms/SharedTermsWidget';
import ActiveGroupsWidget from '../../components/activeSources/ActiveSourcesWidget';
import SourcesTable from '../../components/sources/SourcesTable';
import TextCard from '../../components/ui/TextCard';
import TopicSummary from '../../components/topics/TopicSummary';
import Feed from '../../components/feed/Feed';
import NotFound from '../../components/NotFound';
import PlatformSelect from '../../components/PlatformSelect';

const TopicDetailPage = () => {
    const [topic, setTopic] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTimeFrame, setSelectedTimeFrame] = useState("week");
    const [error, setError] = useState();

    useEffect(async () => {
        setIsLoading(true);
        setError();
        try {
            const matches = /\/topics\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/
                .exec(window.location.pathname);

            if (matches) {
                const topicId = matches[1];
                const topicResponse = await axios.get(`/topics/${topicId}`);
                setTopic(topicResponse.data)
            }

        } catch (error) {
            console.error(error);
            setError(error);

        } finally {
            setIsLoading(false);
        }
    }, []);


    useEffect(() => {
        if (topic) {
            document.title = `${process.env.APP_NAME} • Onderwerp "${topic.title}"`;
        }

    }, [topic])

    if (error) {

        return (<div className='flex flex-col my-auto mx-auto'>
            <NotFound />
        </div>);
    }

    if (!topic && isLoading) {
        return null;
    }

    return (
        <div className='flex flex-col gap-16'>
            <TopicSummary topic={topic} selectedTimeFrame={selectedTimeFrame} setSelectedTimeFrame={setSelectedTimeFrame} />
            {topic && !isLoading &&
                <div className='xl:w-3/4 w-full self-center'>
                    <Feed topicId={topic.uuid} selectedTimeFrame={selectedTimeFrame} />
                </div>
            }


        </div>
    )
}


export default TopicDetailPage;