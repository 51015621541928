import React from 'react';

const FeedItemsContainer = ({children}) => {

    return (
        <div className='flex flex-col bg-white rounded-lg'>
            {children}
            < hr className="h-px mt-1 bg-gray-200 border-0 " />
        </div>

    );
}

export default FeedItemsContainer;