import React, { useState, useRef, useEffect } from 'react';

const VerificationSuccessful = ({successMessage}) => {
    return (
        <div className="h-full  flex flex-col gap-5 ">
            <div className="h-1/4 flex flex-col">
                <div className="text-gray-800 text-2xl font-bold">Verificatie succesvol!</div>
            </div>
            <p className='text-gray-500 text-sm'>{successMessage}</p>
        </div>
    );
}

export default VerificationSuccessful;