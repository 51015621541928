import React, { useEffect} from 'react';
import ContentHeader from '../../components/ContentHeader';
import UserDetails from './UserDetails';
import PasswordReset from './PasswordReset';



const ProfilePage = () => {

    useEffect(() => {
        document.title = `${process.env.APP_NAME} • Profiel`;
      }, []);

    return (
        <>
            <ContentHeader
                heading='Jouw account'
                />
            <div id="profile-info-container" className="flex gap-2.5 items-start">
                <UserDetails />
                <PasswordReset />
            </div>
        </>
    );
}

export default ProfilePage;