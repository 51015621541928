import React from "react";
import { useEffect, useState } from "react";
import axios from "../../services/axios";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import formatSourceType from "../../utils/formatting/formatting";
import ReactDOM from "react-dom";
import languages from "../../utils/languages";

const TopicsTable = ({
  tableId,
  editable,
  checkedTopicIds,
  setCheckedTopicIds,
  topics,
  selectAll
}) => {
  const [table, setTable] = useState(null);

  useEffect(() => {
    makeTable(topics);
  }, [JSON.stringify(topics)]);

  const handleChange = (value, isChecked) => {
    if (isChecked) {
      setCheckedTopicIds((previousValues) => {
        return [...previousValues, value];
      });
    } else {
      setCheckedTopicIds((previousValues) => {
        return previousValues.filter((previousValue) => {
          return previousValue !== value;
        });
      });
    }
  };

  const makeTable = (topics) => {
    if (table) {
      table.destroy();
      setTable(table);
    }

    const columns = [
      {
        data: "title",
        title: "Titel",
        render: function (data, type, row) {
          return `<a href=/topics/${row.uuid} class='underline'>${data}</a>`;
        },
      },
      {
        data: "language",
        title: "Taal",
        render: function (data, type) {
          return data.length > 0 ? `<span class='mr-4 text-sm '>${languages.find((language) => language.code === data).name}</span>` : "None";
        },
      },
      {
        data: "description",
        title: "Beschrijving",
        render: function (data, type) {
          return data.length > 0 ? `<span class=' text-sm '>${data}</a>` : "None";
        },
      },
      {
        data: "sources",
        title: "Bronnen",
        orderable: false,
        render: function (data, type) {
          return data.length > 0 ? `<span class=' text-sm '>${data.join(", ")}</a>` : "";
        },
      },
      {
        data: "search_terms",
        title: "Zoektermen",
        orderable: false,
        render: function (data, type) {
          return data.length > 0 ? `<span class=' text-sm '>${data.slice(0, 5).join(", ")}</a>` : "";
        },
      },
      {
        data: "active",
        title: "Status",
        render: function (data, type) {
          return data ? "Active" : "Inactive";
        },
      },
    ];
    if (editable) {
      columns.unshift({
        data: null,
        orderable: false,
        defaultContent: '<input type="checkbox", value="">',
        render: function (data, type, row) {
          return `<input 
                type="checkbox"
                name='selected-topics'
                value=${row.uuid}>`;
        },
      });
    }

    const tableConfig = {
      data: topics,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.13.7/i18n/nl-NL.json",
      },
      columns: columns,
      order: [[1, "asc"]],
    };
    if (editable) {
      tableConfig["columnDefs"] = [
        {
          targets: 0,
          createdCell: function (td, cellData, rowData, row, col) {
            ReactDOM.render(
              <input
                type="checkbox"
                value={rowData.uuid}
                defaultChecked={checkedTopicIds.includes(rowData.uuid)}
                onChange={(event) => {
                  handleChange(event.target.value, event.target.checked);
                }}
              ></input>,
              td
            );
          },
        },
      ];
    }
    setTable($(`#${tableId}`).DataTable(tableConfig));
  };

  return (
    <div>
      <table
        id={tableId}
        className="hover compact w-full border-collapse border-spacing-y-4 text-black"
        width="100%"
      ></table>
    </div>
  );
};

export default TopicsTable;
