import React, { useState, useRef, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import AlertSuccess from '../../components/ui/alerts/AlertSuccess';
import useAuth from '../../hooks/useAuth';
import AlertDanger from '../../components/ui/alerts/AlertDanger';

const RequestVerificationForm = () => {
    const emailRef = useRef();
    const { requestEmailVerification } = useAuth();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");


    async function handleSubmit(e) {
        e.preventDefault();
        const email = emailRef.current.value;
        try {
            setError("");
            setIsLoading(true);
            await requestEmailVerification(email);
            setSuccess(true);
            setSuccessMessage(`We stuuren een e-mail naar ${email}. Open de mail om je account te activeren.`);
        }
        catch (error) {
            console.error(error)
            setError("E-mailverificatie-request mislukt.");
            setIsLoading(false);
        }
    }

    return (
        <div className="h-full flex flex-col gap-5">
            <div className="h-1/4 flex flex-col">
                <div className="text-gray-800 text-2xl font-bold">Bevestigingsmail opnieuw verzenden</div>
            </div>
            <div className="min-h-[75%] flex flex-col gap-4 justify-between">
                {success && <AlertSuccess heading="E-mail werd verstuurd" message={successMessage} />}
                {error && <AlertDanger message={error} />}
                <form className="h-full flex flex-col gap-4"
                    onSubmit={handleSubmit}
                >
                    <div>
                        <label className="block text-gray-300 text-sm mb-2" htmlFor="email">
                        E-mailadres
                        </label>
                        <input
                            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email" type="email" ref={emailRef} required />
                    </div>
                    <div>
                        <button
                            className="bg-blue-700 w-full hover:bg-blue-900 transition-colors duration-300 ease-in-out text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            disabled={isLoading}
                            type="submit">
                            Versturen
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RequestVerificationForm;