import React, { useEffect, useState } from 'react'
import Select from 'react-select'



const options = [
    { value: 'date:desc', label: 'Nieuwste eerst' },
    { value: 'viral', label: 'Meest viraal' }
  ]

const SortFeedSelect = ({ selectedSort, setSelectedSort }) => {

    const handleChange = selected => {
        setSelectedSort(selected);
    };

    return (
    <Select
        styles={{
            container: (baseStyles) => ({
                ...baseStyles,
                width: '20%'
            }),
            control: (baseStyles) => ({
                ...baseStyles,
                width: '100%',
                cursor: "pointer"
            }),
            option: (baseStyles, {isSelected, isFocused}) => ({
                ...baseStyles,
                color: "black",
                backgroundColor: isSelected
                ? "#ebeced"
                : isFocused
                ? "#f3f4f6"
                : undefined,                
                cursor: "pointer"
            }),
            control: (baseStyles) => ({
                ...baseStyles,
                cursor: "pointer"
            }),
          }}
        options={options}
        onChange={handleChange}
        value={selectedSort}

    />)
}

export default SortFeedSelect;