import React, { useEffect, useState } from 'react';
import { MainLayout } from '../../components/layouts/MainLayout';
import BreadCrumbBar from '../../components/BreadCrumbBar';
import ContentHeader from '../../components/ContentHeader';
import SourcesTable from '../../components/sources/SourcesTable';
import axios from '../../services/axios';
import Spinner from '../../components/ui/Spinner';
import AlertDanger from '../../components/ui/alerts/AlertDanger';

const SourcesPage = () => {

    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [sources, setSources] = useState();
    const [checkedSourceIds, setCheckedSourceIds] = useState([]);


    useEffect(async () => {
        document.title = `${process.env.APP_NAME} • Bronnen`;
        await fetchSources();
    }, []);


    const fetchSources = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`/sources`);
            setSources(response.data);
        } catch (error) {
            console.log(error)
            setError(error);
        }
        finally {
            setIsLoading(false);
        }
    };


    return (
        <>
            <ContentHeader
                heading='Bronnenlijst'
            // subtitle="Delete a source if you don't want its results to be included in your topics."
            />
            <div id="source-list-container" className="gap-6 flex flex-col">

                {
                    isLoading &&
                    <div className='lg:pt-32'>
                        <Spinner />
                    </div>
                }


                {
                    sources &&

                    <SourcesTable  
                    tableId={"source-table"} 
                    checkedSourceIds={checkedSourceIds} 
                    setCheckedSourceIds={setCheckedSourceIds} 
                    sources={sources}
                    noneMesssage={"Geen bronnen beschikbaar."}
                     />
                }

            </div >

        </>)
}

export default SourcesPage;