import React from 'react';
import { getIconColor } from '../getIconColor';

const formatPostType = (postType) => {
    return {
        "icon": <i className={`fa-brands fa-${postType}`} 
        style={{
            color: getIconColor(postType),
            opacity: 0.8
        }}></i>,
        "type": postType
    }
}

export default formatPostType;