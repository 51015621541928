const chartColors = ['#3B2465', "#8A3170", "#C94D6A", "#F37D5D", '#FFB957', '#F9F871'];
const polarityColors = [
    // "#f45348",
    "#f25a41",
    // "#f0603a",
    "#ed6733",
    // "#e96d2b",
    "#e57324",
    // "#e1791c", 
    "#dd7f12",
    // "#d88506",
    "#d28a00",
    // "#cd9000",
    "#c79500",
    // "#c19a00",
    "#ba9f00", 
    // "#b3a300", 
    "#aca800", 
    // "#a5ac00",
    "#9db002",
    // "#95b412",
    "#8db81e"

]
export { chartColors, polarityColors}