import React from "react";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "../../services/axios";
import $ from "jquery";

import "datatables.net-responsive-dt";
import formatSourceType from "../../utils/formatting/formatSource";

const SourcesTable = ({
  tableId,
  selectable,
  checkedSourceIds,
  setCheckedSourceIds,
  topicId,
  sources,
  noneMesssage
}) => {
  const [table, setTable] = useState(null);


  useEffect(() => {
    makeTable(sources);
  }, [sources]);

  const handleChange = (value, isChecked) => {
    if (isChecked) {
      setCheckedSourceIds((previousValues) => {
        return [...previousValues, value];
      });
    } else {
      setCheckedSourceIds((previousValues) => {
        return previousValues.filter((previousValue) => {
          return previousValue !== value;
        });
      });
    }
  };

  const makeTable = (sources) => {
    if (table) {
      table.destroy();
      setTable(table);
    }
    const columns = [
      {
        data: "title",
        title: "Titel",
        render: function (data, type, row) {
          return `<a class='hover:text-black underline hover:no-underline' target="_blank" href=${row.url}>${data}</a>`;
        },
      },
      {
        data: "created",
        title: "Aangemaakt",
      },
      {
        data: "type",
        title: "Type",
        render: function (data, type) {
          return formatSourceType(data);
        },
      },

      {
        data: "topics",
        title: "Gelinkt aan onderwerpen",
        orderable: false,
        render: function (data, type) {
          return data.length > 0 ? data.join(", ") : "None";
        },
      },
      {
        data: "active",
        title: "Status",
        render: function (data, type) {
          return data ? "Active" : "Inactive";
        },
      },
    ];

    if (selectable) {
      columns.unshift({
        data: null,
        orderable: false,
      });
    }

    const tableConfig = {
      columns: columns,
      data: sources,
      language: {
        url: "//cdn.datatables.net/plug-ins/1.13.7/i18n/nl-NL.json",
      },
      responsive: {
        language: {
          url: "//cdn.datatables.net/plug-ins/1.13.7/i18n/nl-NL.json",
        },
        details: {
          renderer: $.fn.dataTable.Responsive.renderer.listHiddenNodes(),
        },
      },
      order: [[0, "asc"]],
      pageLength: 10,
    };
    if (selectable) {
      tableConfig["columnDefs"] = [
        {
          targets: 0,
          createdCell: function (td, cellData, rowData, row, col) {
            ReactDOM.render(
              <input
                type="checkbox"
                value={rowData.uuid}
                defaultChecked={checkedSourceIds.includes(rowData.uuid)}
                onChange={(event) => {
                  handleChange(event.target.value, event.target.checked);
                }}
              ></input>,
              td
            );
          },
        },
      ];
    }
    setTable($(`#${tableId}`).DataTable(tableConfig));
  };

  if (typeof sources === "undefined" || !sources.length) {
    return (
      <p className="pl-11 text-gray-500 font-semibold">
       {noneMesssage}
      </p>
    );
  }

  return (
    <div>
      <table
        id={tableId}
        className="hover compact w-full border-collapse border-spacing-y-4 text-black"
        width="100%"
      ></table>
    </div>
  );
};

export default SourcesTable;
