import React, { useState, useEffect } from 'react';
import axios from '../../services/axios';

import AllTopicsSummary from '../../components/topics/AllTopicsSummary';
import Feed from '../../components/feed/Feed';
import ContentHeader from '../../components/ContentHeader';
import TrendingTopics from '../../components/topics/TrendingTopics';

const HomePage = () => {
    const [subscribedTopics, setSubscribedTopics] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTimeFrame, setSelectedTimeFrame] = useState("week");
    const [trendingTopics, setTrendingTopics] = useState();

    useEffect(() => {
        const fetchData = async () => {
            document.title = `${process.env.APP_NAME} • Dashboard`;
            setIsLoading(true);
            try {
                const topicsResponse = await axios.get("/topics-summary");
                setSubscribedTopics(topicsResponse.data);
                const trendingResponse = await axios.get("/topics-summary/trending");
                setTrendingTopics(trendingResponse.data);
            } catch (error) {
                console.error("Failed to get subscribed topics", error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, []);

    
    return (
        <>
            <ContentHeader heading={"Dashboard"} />
            {trendingTopics && trendingTopics.length > 0 &&
                <TrendingTopics trendingTopics={trendingTopics} />
            }

            <div className='flex flex-col gap-16'>
                {subscribedTopics &&
                    <AllTopicsSummary 
                    allTopics={subscribedTopics} 
                    selectedTimeFrame={selectedTimeFrame} 
                    setSelectedTimeFrame={setSelectedTimeFrame} 
                    />
                }
                {subscribedTopics && !isLoading &&
                    <div className='xl:w-3/4 justify-self-center self-center'>
                        <Feed />
                    </div>
                }
            </div>
        </>
    )

}


export default HomePage;