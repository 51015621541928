import React, { useState, useEffect, useRef } from 'react';
import FeedItem from './FeedItem';
import PlatformSelect from '../PlatformSelect';
import SortFeedSelect from '../SortFeedSelect';

import FeedItemSkeleton from './FeedItemSkeleton';
import FeedItemsContainer from './FeedItemsContainer';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchPosts } from './utils/fetchPosts';
import { loadPlatforms } from './utils/loadPlatforms';
import { fetchMorePosts } from './utils/fetchMorePosts';
import { FeedSinceToInputs } from './FeedSinceToInputs';

const Feed = ({ topicId }) => {

    const searchRef = useRef();
    
    const [platforms, setPlatforms] = useState();
    const [feedItems, setFeedItems] = useState([]);
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [since, setSince] = useState(dayjs().subtract(7, "day"))
    const [until, setUntil] = useState(dayjs())
    const [sort, setSort] = useState({ "label": "Nieuwste eerst", "value": "date:desc" });
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => { 
        loadPlatforms(setIsLoading, setPlatforms, setSelectedPlatforms) 
    }, [])

    useEffect(() => {
        if (selectedPlatforms) {
            fetchPosts(
                setIsLoading,
                setFeedItems,
                setHasMore,
                since,
                until,
                selectedPlatforms,
                sort,
                searchRef,
                topicId
            );
        }
    }, [selectedPlatforms, sort, since, until]);


    const handleSinceChange = (event) => {

        if (event.target.value) {
            if (dayjs(event.target.value) > until) {
                setSince(until.subtract(1, "day"));
            }
            else {
                setSince(dayjs(event.target.value))
            }
        } else {
            setSince(null);
        }
    }

    const handleUntilChange = (event) => {

        if (event.target.value) {
            if (dayjs(event.target.value) < since) {
                setUntil(since.add(1, "day"));
            }
            else {
                setUntil(dayjs(event.target.value))
            }
        } else {
            setUntil(null);
        }

    }


    return (
        <div className='flex flex-col gap-6'>
            <h1 className="text-gray-900 text-2xl font-semibold self-center">Feed</h1>
            <div className='flex flex-col gap-4'>
                <div className='flex xl:flex-row flex-col xl:justify-between xl:gap-2 gap-4'>
                    {selectedPlatforms &&
                        <>
                            <form>
                                <div className="relative">
                                <input
                                    ref={searchRef}
                                    id="default-search"
                                    className="block w-full p-2 ps-3 text-sm 
                                    text-gray-900 border border-[#CCCCCC] 
                                    focus:outline-none focus:ring-1 focus:ring-blue-500 
                                    rounded-md bg-white focus:border-blue-500 
                                    dark:bg-gray-700 dark:border-gray-600 
                                    dark:placeholder-gray-400 dark:text-white 
                                    dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                    placeholder="Zoek" />
                                    <button className="absolute inset-y-0 end-2.5 flex items-center ps-3"
                                        onClick={
                                            (event) => {
                                                event.preventDefault();
                                                setFeedItems([]);
                                                fetchPosts(
                                                    setIsLoading,
                                                    setFeedItems,
                                                    setHasMore,
                                                    since,
                                                    until,
                                                    selectedPlatforms,
                                                    sort,
                                                    searchRef,
                                                    topicId
                                                );
                                            }
                                        }
                                    >
                                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" strokeWidth="round" strokeLinejoin="round" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                    </button>
                                </div>
                            </form>
                            <PlatformSelect platforms={platforms} selectedPlatforms={selectedPlatforms} setSelectedPlatforms={setSelectedPlatforms} />
                            <SortFeedSelect selectedSort={sort} setSelectedSort={setSort} />
                        </>
                    }


                </div>
                <FeedSinceToInputs since={since} until={until} 
                handleSinceChange={handleSinceChange} handleUntilChange={handleUntilChange} />
            </div>

            {!isLoading && feedItems && !feedItems.length &&
                <div className='grid place-items-center font-bold mt-20'>
                    <h1 className='text-gray-700 text-xl'>
                        Er zijn geen berichten gevonden.
                        Wijzig uw zoekfilters en probeer het opnieuw.
                    </h1>
                </div>
            }
            {feedItems && feedItems.length &&
                <InfiniteScroll
                    dataLength={feedItems.length}
                    next={() => {
                        fetchMorePosts(
                            feedItems.length, 
                            setFeedItems, 
                            setHasMore, 
                            selectedPlatforms,
                            since,
                            until,
                            sort,
                            searchRef,
                            topicId
                        )
                    }}
                    hasMore={hasMore}
                    loader={
                        <div className='flex flex-col bg-white rounded-lg p-4 gap-6'>
                            <FeedItemSkeleton />
                            <FeedItemSkeleton />
                            <FeedItemSkeleton />
                            <FeedItemSkeleton />
                            <FeedItemSkeleton />
                        </div>
                    }
                    endMessage={
                        <h1 className='text-slate-600 text-lg mt-8 ml-11'>
                            Je hebt het einde van deze feed bereikt.
                        </h1>
                     
                    }
                >
                    <FeedItemsContainer>
                        {feedItems.map((item, index) => {
                                return (
                                    <div key={`div-${index}`}>
                                        {(index > 0) ?
                                            <hr key={`hr-${index}`} className="h-px my-1 bg-gray-200 border-0 " /> : ''
                                        }
                                        <FeedItem
                                            key={`${item.url}-${index}`}
                                            data={item}
                                            setFeedItems={setFeedItems}
                                            feedItems={feedItems}
                                            textToHighlight={searchRef.current ? searchRef.current.value : null}
                                        />
                                    </div>
                                )
                            })
                        }
                    </FeedItemsContainer>
                </InfiniteScroll>
            }

            {isLoading && 
                <div className='flex flex-col bg-white rounded-lg p-4 gap-6'>
                    <FeedItemSkeleton />
                    <FeedItemSkeleton />
                    <FeedItemSkeleton />
                    <FeedItemSkeleton />
                    <FeedItemSkeleton />
                </div>
            }
        </div>
    )
}

export default Feed;