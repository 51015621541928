import React, { useState, useEffect } from 'react';
import LoginForm from './LoginForm';
import WelcomeGraphic from './WelcomeGraphic';
import TopicsChart from '../../components/topics/chart/TopicsChart';
import axios from '../../services/axios';
import Spinner from '../../components/ui/Spinner';
import PolarityChart from '../../components/PolarityChart';
import TrendingTopics from '../../components/topics/TrendingTopics';
import Markdown from 'markdown-to-jsx';
import SummaryMarkdown from '../../components/SummaryMarkdown';

const LoginPage = () => {
    const [loginFormHeight, setLoginFormHeight] = useState();
    const [chartData, setChartData] = useState();

    // Type: {samenvatting: string, concepten: string[]}
    const [summaryData, setSummaryData] = useState(null);
    
    const [distributionData, setDistributionData] = useState(null);
    const [trendingData, setTrendingData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [contentOpacity, setContentOpacity] = useState(0);

    const fetchData = async () => {
        if (process.env.PUBLICCHARTS.toLowerCase() !== 'true') {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        try {
            const [chartResponse, summaryResponse, distributionResponse, trendingResponse] = await Promise.all([
                axios.get('/public/chart'),
                axios.get('/public/summary'),
                axios.get('/public/distribution'),
                axios.get('/public/trending')
            ]);

            if (trendingResponse.status === 200) setTrendingData(trendingResponse.data);
            if (chartResponse.status === 200) setChartData(chartResponse.data);
            if (summaryResponse.status === 200) setSummaryData(summaryResponse.data);
            if (distributionResponse.status === 200) setDistributionData(distributionResponse.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        document.title = `${process.env.APP_NAME} • Login`;
        fetchData();
    }, []);

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => setContentOpacity(1), 100);
        }
    }, [isLoading]);


    const renderPublicContent = () => {
        if (process.env.PUBLICCHARTS.toLowerCase() !== 'true') return null;

        return (
            <div 
                className='w-full transition-opacity duration-500 ease-in-out flex flex-col gap-16'
                style={{ opacity: contentOpacity }}
            >
                <div className='flex flex-col gap-10 w-full text-center 
                text-black bg-white rounded-xl p-10 shadow-sm border'>
                    <span className='font-bold text-2xl'>Trends</span>
                    <TopicsChart
                        chartId={`seartchterms-all-topics`}
                        data={chartData}
                        timescale={"week"}
                    />
                </div>
                
                <div className='flex flex-col gap-10 sm:flex-row sm:space-x-5'>
                    <div className={trendingData && trendingData.length === 0 
                        ? "grid place-items-center w-full" : ""}>
                        <SummaryMarkdown summary={summaryData} />
                    </div>
                    <div className='flex items-center justify-start w-full'>
                        {trendingData && (trendingData.length > 0) &&
                            <TrendingTopics trendingTopics={trendingData} />
                        }
                    </div>
                </div>
                <div className='flex flex-col gap-10 w-full text-center 
                text-black bg-white rounded-xl p-10 shadow-sm border'>
                    <span className='font-bold text-2xl'>Topic distribution</span>
                    <div className="h-[560px] w-full bg-white p-6 rounded ">
                        <PolarityChart dataset={distributionData.data}
                        labels={distributionData.labels} />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='flex flex-col gap-20 py-32 w-[80%] mx-auto'>
            <div className="flex items-center justify-center w-full">
                <div className='w-full 2xl:w-1/2'
                    style={loginFormHeight > 500 ?
                        { "height": loginFormHeight } : { "minHeight": "500px" }}>
                    <LoginForm />
                </div>
                <div id='graphic-container' className='2xl:flex hidden'>
                    <WelcomeGraphic height={loginFormHeight} setHeight={setLoginFormHeight} />
                </div>
            </div>
            {isLoading ? (
                <Spinner />
                ) 
                : 
                renderPublicContent()
            }
        </div>
    )
}

export default LoginPage;