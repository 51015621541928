import React, { useEffect, useState } from 'react';
import ContentHeader from '../../components/ContentHeader';


import InfoAlert from '../../components/ui/alerts/AlertInfo';
import TopicsTable from '../../components/topics/TopicsTable';
import axios from '../../services/axios';
import BreadCrumbBar from '../../components/BreadCrumbBar';
import Spinner from '../../components/ui/Spinner';


const TopicsPage = () => {


    const [authoredTopics, setAuthoredTopics] = useState();
    const [subscribedTopics, setSubscribedTopics] = useState();
    const [communityTopics, setCommunityTopics] = useState();
    const [checkedUserTopicIds, setCheckedUserTopicsIds] = useState([]);
    const [checkedCommunityTopicIds, setCheckedCommunityTopicIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [subscribeError, setSubscribeError] = useState();
    const [unsubscribeError, setUnsubscribeError] = useState();
    const [userTopicsAreLoading, setUserTopicsAreLoading] = useState(false);
    const [communityTopicsAreLoading, setCommunityTopicsAreLoading] = useState(false)
    const [authoredTopicsAreLoading, setAuthoredTopicsAreLoading] = useState(false);
    const [error, setError] = useState();


    const fetchAuthoredTopics = async () => {
        setAuthoredTopicsAreLoading(true);
        try {
            const response = await axios.get(`/users/me/topics?authored=True`);
            const data = response.data;
            const topicData = data.map((topic) => {
                return {
                    'title': topic.title,
                    'description': topic.description,
                    'active': topic.active,
                    'search_terms': topic.search_terms,
                    'sources': topic.sources,
                    'uuid': topic.uuid,
                    'language': topic.language,
                };
            })
            setAuthoredTopics(topicData);

        } catch (error) {

            setError(error);
        }
        finally {
            setAuthoredTopicsAreLoading(false);
        }
    }

    const fetchUserTopics = async () => {
        setUserTopicsAreLoading(true);
        try {
            const response = await axios.get(`/users/me/topics?subscribed=True`);
            const data = response.data;
            const topicData = data.map((topic) => {
                return {
                    'title': topic.title,
                    'description': topic.description,
                    'active': topic.active,
                    'search_terms': topic.search_terms,
                    'sources': topic.sources,
                    'uuid': topic.uuid,
                    'language': topic.language,
                };
            })
            setSubscribedTopics(topicData);

        } catch (error) {

            setError(error);
        }
        finally {
            setUserTopicsAreLoading(false);
        }
    }


    const fetchCommunityTopics = async () => {
        setCommunityTopicsAreLoading(true);

        try {
            const response = await axios.get(`/users/me/topics?subscribed=False`);
            const data = response.data;
            const topicData = data.map((topic) => {
                return {
                    'title': topic.title,
                    'description': topic.description,
                    'active': topic.active,
                    'search_terms': topic.search_terms,
                    'sources': topic.sources,
                    'uuid': topic.uuid,  
                    'language': topic.language,
                };
            })
            setCommunityTopics(topicData);

        } catch (error) {

            setError(error);
        }
        finally {
            setCommunityTopicsAreLoading(false);
        }
    };

    useEffect(() => {
        document.title = `${process.env.APP_NAME} • Onderwerpen`;
        fetchAuthoredTopics();
        fetchUserTopics();
        fetchCommunityTopics();

    }, []);


    const handleUnsubscribe = async () => {
        setIsLoading(true);
        setUnsubscribeError(null);
        for (let topicId of checkedUserTopicIds) {
            try {
                await axios.delete(`/users/me/topics/${topicId}`);

                const index = subscribedTopics.findIndex(obj => obj.uuid === topicId);
                let unsubscribedTopic;
                if (index !== -1) {
                    // Use splice to remove the object at the found index
                    unsubscribedTopic = subscribedTopics.splice(index, 1)[0];

                    communityTopics.push(unsubscribedTopic);
                }
                setCheckedUserTopicsIds([]);
                setCheckedCommunityTopicIds([]);
            } catch (error) {
                console.error(error);
                setUnsubscribeError("Fout bij afmelden voor het/de onderwerp(en). Vernieuw de pagina en probeer het opnieuw.")
            }
        };
        setIsLoading(false);

    }

    const handleSubscribe = async () => {
        setIsLoading(true);
        setSubscribeError(null);

        for (let topicId of checkedCommunityTopicIds) {

            try {
                await axios.post("/users/me/topics", {
                    "topic_uuid": topicId
                },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                );
                const index = communityTopics.findIndex(obj => obj.uuid === topicId);
                let subscribedTopic;
                if (index !== -1) {
                    // Use splice to remove the object at the found index
                    subscribedTopic = communityTopics.splice(index, 1)[0];

                    subscribedTopics.push(subscribedTopic);
                }
                setCheckedUserTopicsIds([]);
                setCheckedCommunityTopicIds([]);

            } catch (error) {
                console.error(error);
                setSubscribeError("Fout bij abonneren op het/de onderwerp(en). Vernieuw de pagina en probeer het opnieuw.")
            }
            setIsLoading(false);
        }
    }

    return (
        <>

            <ContentHeader
                heading='Onderwerpenlijst'
                subtitle=""
            />
            <div id="topic-list-container" className="gap-11 flex flex-col">
                <div className='flex flex-col gap-4'>
                    <h1 className="text-gray-900 text-1xl font-semibold">Eigen onderwerpen</h1>
                    {
                        authoredTopicsAreLoading &&
                        <div className='lg:pt-32'>
                            <Spinner />
                        </div>
                    }
                    {
                        authoredTopics &&
                        <>

                            <TopicsTable editable={false} tableId={'created-topics-table'} topics={authoredTopics} />
                        </>
                    }
                </div>
                <div className='flex flex-col gap-4'>
                    <h1 className="text-gray-900 text-1xl font-semibold">Geabonneerde onderwerpen</h1>
                    {
                        userTopicsAreLoading &&
                        <div className='lg:pt-32'>
                            <Spinner />
                        </div>
                    }
                    {
                        subscribedTopics &&
                        <>
                            <button className='btn btn--warning w-32'
                                disabled={isLoading || checkedUserTopicIds.length < 1}
                                onClick={handleUnsubscribe}

                            >Uitschrijven</button>
                            <TopicsTable editable={true} tableId={'user-topics-table'} topics={subscribedTopics} setCheckedTopicIds={setCheckedUserTopicsIds} checkedTopicIds={checkedUserTopicIds} />
                        </>
                    }
                </div>
                <div className='flex flex-col gap-4'>
                    <h1 className="text-gray-900 text-1xl font-semibold">Andere onderwerpen</h1>
                    {
                        communityTopicsAreLoading &&
                        <div className='lg:pt-32'>
                            <Spinner />
                        </div>
                    }
                    {
                        communityTopics &&
                        <>
                            <button className='btn w-32'
                                disabled={isLoading || checkedCommunityTopicIds.length < 1}
                                onClick={handleSubscribe}

                            >Abonneren</button>
                            <TopicsTable editable={true} tableId={'community-topics-table'} topics={communityTopics} setCheckedTopicIds={setCheckedCommunityTopicIds} checkedTopicIds={checkedCommunityTopicIds} />
                        </>
                    }
                </div>
            </div >

        </>)
}

export default TopicsPage;