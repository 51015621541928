import React, { useState, useRef, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import AlertSuccess from "../../components/ui/alerts/AlertSuccess";
import useAuth from "../../hooks/useAuth";
import AlertDanger from "../../components/ui/alerts/AlertDanger";

import RequestVerificationForm from "./RequestVerificationForm";
import VerificationSuccessful from "./VerificationSuccessful";

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const [userVerified, setUserVerified] = useState(false);
  const { verifyEmail } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(async () => {
    document.title = `${process.env.APP_NAME} • Verify email`;
    setErrorMessage("");
    setSuccessMessage("");
    try {
      await verifyEmail(searchParams.get("t"));
      setUserVerified(true);
      setSuccessMessage(
        "De registratie van je account is succesvol. Je zal binnen een 5tal seconden automatisch naar de login-pagina worden gebracht..."
      );
      setIsLoading(false);
      setTimeout(function () {
        navigate("/login");
      }, 5000);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (error === "VERIFY_USER_ALREADY_VERIFIED") {
        setUserVerified(true);
        setSuccessMessage("Dit e-mailadres is al geverifieerd.");
        return;
      }
      if (error === "VERIFY_USER_BAD_TOKEN") {
        setErrorMessage("Fout validatietoken.");
        return;
      }
      setUserVerified(false);
      setErrorMessage("E-mailverificatie mislukt.");
    }
  }, []);

  return (
    <div className="flex items-center justify-center w-3/4 xl:w-1/4">
      {!isLoading && !userVerified && (
        <div className="h-full bg-white flex flex-col justify-between leading-normal gap-6 p-20 w-full">
          <AlertDanger message={errorMessage} />
          <RequestVerificationForm />
        </div>
      )}
      {!isLoading && userVerified && (
        <div className="h-full bg-white flex flex-col justify-between leading-normal gap-6 p-20 w-full">
          <VerificationSuccessful successMessage={successMessage} />
        </div>
      )}
    </div>
  );
};

export default VerifyEmailPage;
