import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import AlertSuccess from '../../components/ui/alerts/AlertSuccess';
import useAuth from '../../hooks/useAuth';
import AlertDanger from '../../components/ui/alerts/AlertDanger';



const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const { resetPassword } = useAuth();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [successMessage, setSuccessMessage] = useState("");


    useEffect(() => {
        document.title= `${process.env.APP_NAME} • Wachtwoord opnieuw instellen`;
      }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        if (passwordRef.current.value.length < 6) {
            setError("Het wachtwoord moet op zijn minst 6 tekens lang zijn.");
            return
        }
        if (passwordRef.current.value !== confirmPasswordRef.current.value) {
            setError("Wachtwoorden komen niet overeen.");
            return
        }
        try {
            setError("");
            setIsLoading(true);
            await resetPassword(passwordRef.current.value, searchParams.get("t"));
            setSuccess(true);
            setSuccessMessage("U wordt nu automatisch doorgestuurd naar de inlogpagina. Wacht maximaal 5 seconden...")
            setTimeout(function() {
                navigate('/login');
              }, 5000);
        }
        catch (error) {
            console.error(error)
            setError("Wachtwoord opnieuw instellen mislukt.");
            setIsLoading(false);
        }
    }

    return (
        <div className="flex items-center justify-center lg:w-3/5">
            <div className="h-full lg:w-1/2 bg-white flex flex-col justify-between leading-normal">
                <div className="h-full p-20 flex flex-col grow-0 gap-4">
                    <div className="flex flex-col gap-4">
                        <div className="text-gray-800 text-4xl font-bold">Stel je wachtwoord opnieuw in</div>
                        <div className='text-gray-500 text-sm'>Voer een nieuw wachtwoord in voor uw {process.env.APP_NAME}-account.</div>
                    </div>
                    <div className="flex flex-col gap-4 grow-1">
                        {error && <AlertDanger message={error} />}
                        {success && <AlertSuccess heading="Password successfully changed" message={successMessage} />}
                        <form className="h-full flex flex-col gap-4 justify-evenly"
                            onSubmit={handleSubmit}
                        >
                            <div>
                                <label className="block text-gray-300 text-sm mb-2" htmlFor="password">
                                Wachtwoord
                                </label>
                                <input
                                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="password" type="password" ref={passwordRef} required />
                            </div>
                            <div>
                                <label className="block text-gray-300 text-sm mb-2" htmlFor="confirm-password">
                                Bevestig wachtwoord
                                </label>
                                <input
                                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                    id="confirm-password" type="password" ref={confirmPasswordRef} required />
                            </div>
                            <div>
                                <button
                                    className="bg-blue-700 w-full hover:bg-blue-900 transition-colors duration-300 ease-in-out text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    disabled={isLoading}
                                    type="submit">
                                    Bevestigen
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>)
}

export default ResetPasswordPage;