import React, { useEffect, useState } from 'react';
import { polarityColors } from '../../utils/colors';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
);





export default function FeedItemPolarity({ datasets, labels }) {

    const [chart, setChart] = useState();
    const counts = [];
    const [data, setData] = useState();
    const [options, setOptions] = useState();



    useEffect(() => {
        if (chart) {
            chart.destroy();
        }
        const data = [];

        
        if (datasets) {
            for (let i = 0; i < datasets.length; i++) {
                const item = {};
                item['data'] = datasets[i];
            }
            for (let i = 0; i < datasets.length; i++) {
                const item = {};
                item['data'] = datasets[i];
                item['label'] = labels[i];
                item['backgroundColor'] = polarityColors[i];
                data.push(item);
            }
        }
        const dataConfig = {
            labels: labels,
            datasets: data
        }
        const max = datasets.flat().reduce((partialSum, a) => partialSum + a, 0)

        setData(dataConfig);
        setOptions({
            responsive: true,
            indexAxis: 'y',
            maintainAspectRatio: false,
            elements: {
                bar: {
                    borderWidth: 1,
                }
            },
            scales: {

                x: {
                    display: false,
                    stacked: true,
                    max: max > 0? max :1,
                    ticks: {
                        display: true,

                    },
                    grid: {
                        display: false,

                    },
                },
                y: {

                    stacked: true,
                    display: false,
                    ticks: {
                        display: true,
                    }
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                },
                tooltip: {
                    enabled: false
                }
            }
        })

    }, [datasets])



    if (data) {
        return <div className='w-56 h-4 relative' >
            <Bar
                height={"100%"}
                width={"100%"}
                options={options}
                id={Math.random()}
                data={data}
            /></div>;
    }
    return null;
}
