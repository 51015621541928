import axios from "../../../services/axios";

export const fetchMorePosts = async (
    skip,
    setFeedItems,
    setHasMore,
    selectedPlatforms,
    since,
    until,
    sort,
    searchRef,
    topicId
) => {

    try {
        let feedResponse;
        const searchParams = new URLSearchParams();
        selectedPlatforms.forEach((platform) => { searchParams.append("platforms", platform.value) });
        if (since) {
            searchParams.append("since", since.toISOString())
        }
        if (until) {
            searchParams.append("until", until.toISOString())
        }
        searchParams.append("sort", sort.value);
        searchParams.append("skip", skip)
        if (searchRef.current.value) {
            searchParams.append("query", searchRef.current.value)
        }

        if (topicId) {
            feedResponse = await axios.get(`/topics/${topicId}/feed?`
                + searchParams);
        }
        else {
            feedResponse = await axios.get(`/topics-summary/feed?`
                + searchParams);
        }            
        setHasMore(feedResponse.data.length > 9);
        setFeedItems(prev => { return [...prev, ...feedResponse.data.map((item) => { return { ...item, isUpdating: false } })] });
    } catch (error) {
        console.error(error)
    }

}