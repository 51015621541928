import React from 'react';

export const AllTopicsSummaryButtons = ({ showPolaritiesWidget, setShowPolaritiesWidget }) => {
    const handleClick = () => {
        setShowPolaritiesWidget((prev) => !prev);
    };

    return (
        <button
            type="button"
            onClick={handleClick}
            className="pl-11 flex items-center pr-6 text-base 
            text-gray-500 hover:text-gray-800
            font-semibold transition duration-75 group dark:text-white"
        >
            {showPolaritiesWidget ? "Verberg het polariteitsdiagram" : "Toon het polariteitsdiagram"}
            <i className={`ml-2 fa-solid transition-all duration-500 ease-in-out
                ${showPolaritiesWidget ? 'fa-minus rotate-180 scale-110 text-red-600' : 'fa-plus rotate-0'}`}
            ></i>
        </button>
    );
};

export default AllTopicsSummaryButtons;