import React, { useState } from 'react';


export const CollapseNavMenu = ({ name, children, collapsed, isUserMenu }) => {
    const [isCollapsed, setCollapsed] = useState(collapsed);

    function toggleCollapsed() {
        setCollapsed(prevCollapsed => !prevCollapsed);
    }

    return (
        <li>
            <button
                type="button"
                onClick={toggleCollapsed}
                className="flex items-center w-full pl-11 py-2 pr-6 text-base text-gray-500 transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
            >
                {
                    isUserMenu &&
                    <img
                        id="pfp"
                        className="w-12 h-12 mr-4"
                        src="/logo-small.png"
                    />
                }
                <span className="flex-1 text-left whitespace-nowrap">{name}</span>{isCollapsed ? (<i className="fa-solid fa-chevron-down" />) : (<i className="fa-solid fa-chevron-up" />)}
            </button>
            {
                !isCollapsed &&
                (<ul name='nav-items' className="py-2 space-y-2">
                    {children}
                </ul>)
            }
        </li>
    )
}