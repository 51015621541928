import React from 'react'

export const FeedSinceToInputs = ({ since, until, handleSinceChange, handleUntilChange }) => {
    const classes = `block w-full p-2 ps-3 text-sm border text-black focus:ring-blue-500 
                    border-gray-300 rounded-lg focus:border-blue-500 focus:ring-1 
                    focus:outline-none cursor-pointer
                    dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                    dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`
    return (
        <form className='flex  xl:gap-8 gap-4 items-center'>
            <div className='flex items-center gap-1 xl:w-1/4 w-1/2'>
                <label htmlFor='since' className='text-gray-400 w-9'>Van</label>
                <input
                    type='date'
                    id='since'
                    value={since ? since.format("YYYY-DD-MM") : null}
                    onChange={handleSinceChange}
                    className={classes} />
            </div>
            <div className='flex items-center gap-1 xl:w-1/4 w-1/2'>
                <label htmlFor="until" className='text-gray-400 w-9'>Tot</label>
                <input
                    type='date'
                    value={until ? until.format("YYYY-DD-MM") : null}
                    onChange={handleUntilChange}
                    className={classes} />
            </div>
        </form>
    )    
}
