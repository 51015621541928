import dayjs from 'dayjs';

const formatDate = (dateStr) => {
    const dateObj = dayjs(dateStr);
    return dayjs().isSame(dateObj, 'year') ?

        (
            (dayjs().diff(dateObj, 'hours') > 24)
                ?
                dateObj.format("MMM. D")
                :
                `${dayjs().diff(dateObj, 'hours')} uur geleden`)
        :
        dateObj.format("MMMM D, YYYY")
}

export default formatDate;