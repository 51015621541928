import axios from "../../../services/axios";

export const fetchPosts = async (
    setIsLoading,
    setFeedItems,
    setHasMore,
    since,
    until,
    selectedPlatforms,
    sort,
    searchRef,
    topicId
) => {

    setIsLoading(true);
    setFeedItems([]);
    try {
        let feedResponse;
        const searchParams = new URLSearchParams();
        selectedPlatforms.forEach((platform) => { searchParams.append("platforms", platform.value) });
        if (since) {
            searchParams.append("since", since.toISOString())
        }
        if (until) {
            searchParams.append("until", until.toISOString())
        }
        searchParams.append("sort", sort.value);
        if (searchRef.current.value) {
            searchParams.append("query", searchRef.current.value)
        }

        if (topicId) {
            feedResponse = await axios.get(`/topics/${topicId}/feed?`
                + searchParams);
        }
        else {
            feedResponse = await axios.get(`/topics-summary/feed?` + searchParams);
        }        
        setFeedItems([...feedResponse.data.map((item) => { return { ...item, isUpdating: false } })]);
        setHasMore(feedResponse.data.length > 9);
    } catch (error) {
        console.error("Error in fetching feed", error)
    } finally {
        setIsLoading(false);
    }
};
