import React, { useState, useEffect, useRef } from 'react';
import { MainLayout } from '../../components/layouts/MainLayout';
import BreadCrumbBar from '../../components/BreadCrumbBar';
import ContentHeader from '../../components/ContentHeader';
import axios from '../../services/axios';
import AlertDanger from '../../components/ui/alerts/AlertDanger';
import TextInputSourceForm from './TextInputSourceForm';
import FileInputSourceForm from './FileInputSourceForm';
import templateFile from '../../assets/template.xlsx';
const AddSourcePage = () => {



    useEffect(() => {
        document.title = `${process.env.APP_NAME} • Bron toevoegen`;
    }, [])



    return (
        <>
            <ContentHeader
                heading='Bron toevoegen'
                subtitle={`Voeg een bron toe die ${process.env.APP_NAME} moet scrapen. Je kan (publieke) Facebookpagina's en -groepen, Twitter profielen, Youtube kanalen, Wordpress sites, Subreddits en TikTok profielen toevoegen.`}
            />
            <div className="flex flex-col gap-4 rounded-md p-6 bg-white lg:w-5/12">
                <div className="flex justify-between">
                    <h1 className="text-gray-900 text-xl font-semibold">Bron handmatig toevoegen</h1>
                </div>
                <div className="flex flex-col gap-4 justify-between ">
                    <TextInputSourceForm />
                </div>
            </div>
            <div className="flex flex-col gap-4 rounded-md p-6 bg-white lg:w-5/12">
                <div className="flex justify-between">
                    <h1 className="text-gray-900 text-xl font-semibold">Voeg bronnen toe via bestandsupload</h1>
                </div>
                <a 
                href={templateFile}
                className="font-medium text-gray-500 cursor-pointer"
                download="ehbt-bronnen-template"
                >
                    <i className="fa-regular fa-download mr-2 rounded-full hover:bg-gray-200 p-2 transition-colors"></i>
                    Download het Excel-sjabloonbestand en vul het met bronnen en de bijbehorende URL's die u wilt toevoegen.
                </a>
                <p className="font-medium text-gray-500 ">
                    Upload vervolgens het ingevulde bestand hieronder.
                </p>
                <FileInputSourceForm />

            </div>
        </>)
}

export default AddSourcePage;