import React from 'react';
import App from './App';
import ReactDOM from 'react-dom';
import 'datatables.net-dt/css/jquery.dataTables.css';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// const domNode = document.getElementById('root');
// const root = createRoot(domNode);
// root.render(<App />);