import React from 'react';
const NotFound = () => {
    return (
        <div className='absolute top-1/3 left-1/3'>
            <h1 className='text-3xl text-gray-500 mb-12'>
            Pagina niet gevonden
            </h1>
            <p className='text-gray-500 mb-6 text-xl'>We konden de pagina die u zocht niet vinden. Weet u zeker dat de website-URL correct is?</p>
            <a className='btn btn-primary' href='/'>Ga naar het dashboard</a>
        </div>)
}

export default NotFound;