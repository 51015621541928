import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'


const FeedItemSkeleton = () => {

    return (
        <div className="flex lg:flex-row flex-col p-11 mx-1 rounded-md">
            <div className="lg:w-1/6">
                <Skeleton width={"50%"} />
            </div>
            <div className="flex flex-col lg:w-2/3 gap-2">
                <div className="pb-1 pl-2 flex gap-2 items-center" >
                    <Skeleton width={"2em"} height={"2em"} circle />

                    <Skeleton width={"8em"} />

                </div>
                <Skeleton count={5} />
                <div
                    className=" p-2"
                >
                    <Skeleton count={3} />
                </div>

                <div className="relative">
                    <div
                        className="absolute top-0 left-0 bottom-0 w-lg xs:w-xl flex justify-center items-center z-0 group mb-sm"
                        aria-hidden="true"
                    >
                        <div
                            data-testid="main-thread-line"
                            className="w-[1px] h-full bg-neutral-300"
                        ></div>
                    </div>
                    <div className="flex ml-2 p-2">
                        <div className="mr-6 w-[156px]" >
                            <Skeleton />
                        </div>
                        <div className=" w-5/6 ">
                            <Skeleton />
                        </div>
                    </div>
                    <div className="flex ml-2 p-2">
                        <div className="mr-6 w-[156px]" >
                            <Skeleton />
                        </div>
                        <div className=" w-5/6 ">
                            <Skeleton />
                        </div>
                    </div>
                    <div className="flex ml-2 p-2">
                        <div className="mr-6 w-[156px]" >
                            <Skeleton />
                        </div>
                        <div className=" w-5/6 ">
                            <Skeleton />
                        </div>
                    </div>

                </div></div>
                <div className="xl:w-1/6 xl:ml-6">
                    <Skeleton height={"100%"} />
                </div>
            
        </div>
    )
}


export default FeedItemSkeleton;