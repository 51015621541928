import React, { useState, useEffect } from 'react';
import {getIconColor} from '../../utils/getIconColor';

export default function ActiveGroupsWidget({ mostActiveSourcesData, timeFrame }) {

    const [isVisible, setIsVisible] = useState(false);
    const platformWithMostPosts = mostActiveSourcesData[0]?.title || '';

    // Add 200ms delay to the animation here, to ensure that component will have opacity 100
    // after SharedTermsWidget has finished it's animation
    useEffect(() => {
        const timer = setTimeout(() => setIsVisible(true), 200);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`p-6 rounded bg-white flex flex-col shadow-sm border 
            transition-opacity duration-500 ease-in-out 
            ${isVisible ? 'opacity-100' : 'opacity-0'}`
            }>
            <div className='flex gap-4 items-center border-b'>
                <i className={`fa-brands fa-${platformWithMostPosts} rounded
                    text-2xl p-4 w-14 h-14`} 
                    style={{ color: getIconColor(platformWithMostPosts) }}></i>
                <h1 className=' text-black text-lg font-semibold'>{`Meest actieve bronnen deze ${timeFrame === "month" ? "maand" : "week"}`}</h1>
            </div>
            
            {!mostActiveSourcesData.length &&
                <h1 className='text-gray-500  text-sm font-semibold self-center justify-center'>
                    Dit onderwerp heeft geen gelinkte bronnen
                </h1>
            
            }
            
            {mostActiveSourcesData?.length > 0 &&
                <table className='text-sm border-separate border-spacing-y-4 w-full '>
                    <thead>
                        <tr>
                            <th className='text-black text-left text-base'>Bron</th>
                            <th className='text-black text-left text-base'>Posts</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {mostActiveSourcesData.map((group, index) => (                        
                            <tr key={index} >
                                <td >{group.title}</td>
                                <td>{group.shared}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </div>
    )
}